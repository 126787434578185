import { AccountService } from '../../services/accountService';
import { Form, Input, Button, Row, Col, Typography, Radio, Divider, Result } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { HttpStatus } from '../../constant/responseStatus';
import { Link, useNavigate } from 'react-router-dom';
import { CloseOutlined, EyeInvisibleOutlined, EyeTwoTone, GoogleOutlined } from '@ant-design/icons';
import IRegisterModel from '@/src/models/request/registerRequestModel';
import { useEffect, useState } from 'react';
import { RouteNames } from 'src/routes/routeName';
import { useSessionContext } from 'src/context/session-context';
import MessageModal from 'src/components/modal/messageModal';
import { useGoogleLogin } from '@react-oauth/google';
import { ProfileObjModel } from 'src/models/accountModel';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { PageDocument, StringConstants } from 'src/constant/stringConstants';
import { RegexPattern } from 'src/constant/constants';
import FullScreenMessageModal from 'src/components/modal/fullScreenModal';
import saleforceService from 'src/services/saleforceService';
import PrivacyPolicy from '../privacy-policy';
import TermOfService from '../term-of-service';
import TranslationKey from 'src/i18n/translation';

const { Title, Text } = Typography;

const year = new Date().getFullYear();

export default function Register() {
  const [isAccept, setIsAccept] = useState(false);
  const { signInGoogleAsync, signInFacebookAsync } = useSessionContext();
  const navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenTerm, setIsOpenTerm] = useState(false);
  const [isOpenPolicy, setIsOpenPolicy] = useState(false);

  const [childModal, setChildModal] = useState<JSX.Element>(<></>);
  const [loading, setLoading] = useState<boolean>(false);
  const logo = require('../../assets/images/logo/white@3x.png');
  const bannerImg = require('../../assets/images/banner/Image.png');
  const facebookLogo = require('../../assets/images/icon/facebook.png');

  const accountService = new AccountService();
  const appId = process.env.REACT_APP_FACEBOOK_APP_ID ?? StringConstants.EMPTY;

  const handleCloseModal = () => setIsOpenModal(false);

  useEffect(() => {
    document.title = `${TranslationKey('register.title')} - ${PageDocument.Title}`;
  }, []);

  const onFinish = async (value: IRegisterModel) => {

    setLoading(true);
    const response = await accountService.registerAsync(value);

    if (response.status !== HttpStatus.CREATED) {
      if (response.status === HttpStatus.CONFLICT) {
        errorPopup(
          TranslationKey('register.popup.emailExistTitle'),
          <Link to={RouteNames.login}
            className='btn-orange-round'
            style={{ textDecoration: 'none' }}>
            { TranslationKey('register.popup.emailExistButton') }
          </Link>
        );
        TranslationKey('register.popup.emailExistButton')
        return;
      }

      errorPopup(
        TranslationKey('register.popup.emailInvalidTitle'),
        <Button size='large'
          type='primary'
          shape='round'
          className='btn-primary'
          onClick={handleCloseModal}>
          {TranslationKey('register.popup.emailInvalidButton')}
        </Button>
      );

      return;
    }

    await saleforceService.postCreateUserSaleforceAsync(value.email);

    successPopup(
      TranslationKey('register.popup.registerSuccessTitle'),
      <Link to={RouteNames.login}
        className='btn-orange-round'
        style={{ textDecoration: 'none' }}>
        {TranslationKey('register.popup.registerSuccessButton')}
      </Link>
    );
  };


  const loginGoogle = useGoogleLogin({
    onSuccess: async codeResponse => {
      var user = codeResponse;

      const response = await new AccountService().oauthGoogleAsyncUserData(user.access_token);

      if (response.status !== HttpStatus.OK) {
        errorPopup(
          TranslationKey('register.popup.googleAuthFailTitle'),
          <Button size='large'
            type='primary'
            shape='round'
            className='btn-primary'
            onClick={handleCloseModal}>
            {TranslationKey('register.popup.googleAuthFailButton')}
          </Button>
        );

        return;
      }

      const userData = response.data;

      const result = {
        email: userData.email,
        familyName: userData.family_name ?? userData.given_name,
        givenName: userData.given_name,
        googleId: userData.sub ?? '',
        imageUrl: userData.picture,
        name: userData.name,
      } as ProfileObjModel;

      await onSignInGoogleAsync(user.access_token, result);

      await saleforceService.postCreateUserSaleforceAsync(userData.email);
    },
    onError: () => {
      console.log('Login Failed');
    },
  });

  const onSignInGoogleAsync = async (tokenId: string, profileObj: ProfileObjModel) => {
    const responseGoogle = await signInGoogleAsync({ tokenId: tokenId, profileObj: profileObj });

    if (responseGoogle.status !== HttpStatus.OK) {
      return;
    }

    await saleforceService.postCreateUserSaleforceAsync(profileObj.email);
    navigate(RouteNames.default);
  };

  const signInFacebook = async (data: any) => {
    const responseFacebook = await signInFacebookAsync({
      email: data.email,
      accessToken: data.accessToken,
      userId: data.userID,
      name: data.name,
      imageUrl: data.picture.data.url,
    });

    if (responseFacebook.status !== HttpStatus.OK) {
      errorPopup(
        TranslationKey('register.popup.facebookAuthFailTitle'),
        <Button size='large'
          type='primary'
          shape='round'
          className='btn-primary'
          onClick={handleCloseModal}>
          {TranslationKey('register.popup.facebookAuthFailButton')}
        </Button>
      );

      return;
    }

    await saleforceService.postCreateUserSaleforceAsync(data.email);
    navigate(RouteNames.default);
  };

  const errorPopup = (msg: string, extra: JSX.Element) => {
    setChildModal(
      <Result status='error'
        title={msg}
        className='result-message'
        extra={extra} />);
    setIsOpenModal(true);
    setLoading(false);  
  };

  const successPopup = (msg: string, extra: JSX.Element) => {
    setChildModal(
      <Result status='success'
        title={msg}
        className='result-message'
        extra={extra} />);
    setIsOpenModal(true);
    setLoading(false);
  };

  const textTOS = () => {
    return (
      <>
        <Row className='d-flex justify-content-end'>
          <Col>
            <Button
              shape='round'
              type='text'
              className='text-primary'
              size='large'
              onClick={() => setIsOpenTerm(false)}
            >
              <CloseOutlined style={{ fontSize: '2em' }} />
            </Button>
          </Col>
        </Row>
        <TermOfService />
        <Row className='d-flex justify-content-center'>
          <Col span={16}>
            <Button
              shape='round'
              type='primary'
              className='btn btn-primary w-100'
              size='large'
              onClick={() => setIsOpenTerm(false)}
            >
              {TranslationKey('termOfService.accept')}
            </Button>
          </Col>
        </Row>
      </>
    )
  }

  const textPolicy = () => {
    return (
      <>
        <Row className='d-flex justify-content-end'>
          <Col>
            <Button
              shape='round'
              type='text'
              className='text-primary'
              size='large'
              onClick={() => setIsOpenPolicy(false)}
            >
              <CloseOutlined style={{ fontSize: '2em' }} />
            </Button>
          </Col>
        </Row>
        <PrivacyPolicy />
        <Row className='d-flex justify-content-center'>
          <Col span={16}>
            <Button
              shape='round'
              type='primary'
              className='btn btn-primary w-100'
              size='large'
              onClick={() => setIsOpenPolicy(false)}
            >
              {TranslationKey('privacyPolicy.accept')}
            </Button>
          </Col>
        </Row>
      </>
    )
  }

  return (
    <>
      <Content className='login'>
        <Row>
          <Col xs={0} lg={12}>
            <Link to={RouteNames.default}>
              <img src={logo} alt='aliveunited' className='img-logo' />
            </Link>
            <p className='copy-right'>All contents &copy; copyright {year} Alive United</p>
            <img src={bannerImg} alt='' className='img' />
          </Col>
          <Col xs={24} lg={12} className='flex-center-items'>
            <Form
              name='form_item_path'
              layout='vertical'
              onFinish={onFinish}
              className='form-input'
              requiredMark={false}
            >
              <Title level={2} className='text-light mb-0'>
                {TranslationKey('register.title')}
              </Title>
              <Form.Item
                name='email'
                label={TranslationKey('email')}
                className='mt-4'
                rules={[
                  {
                    required: true,
                    message: TranslationKey('register.emailInvalidRequired')
                  },
                  {
                    type: 'email',
                    message: TranslationKey('register.emailInvalidRule'),
                  },
                ]}
              >
                <Input
                  className='input-radius-dark w-100'
                  placeholder={TranslationKey('email')}
                  size='large' />
              </Form.Item>
              <Form.Item
                name='password'
                label={TranslationKey('password')}
                rules={[
                  {
                    required: true,
                    message: TranslationKey('register.passwordInvalidRequired')
                  },
                  {
                    pattern: RegexPattern.password,
                    message: TranslationKey('register.passwordInvalidRule')
                  },
                ]}
              >
                <Input.Password
                  name='password'
                  placeholder={TranslationKey('password')}
                  className='input-radius-dark w-100'
                  size='large'
                  iconRender={(visible) =>
                    visible ? (
                      <EyeTwoTone style={{ color: '#FFF' }} />
                    ) : (
                      <EyeInvisibleOutlined style={{ color: '#FFF' }} />
                    )
                  }
                />
              </Form.Item>
              <div>
                <Text className='text-gray'>
                  {TranslationKey('register.requirement')}
                </Text>
              </div>
              <Radio.Group className='custom-color-radio' size='large'>
                <Radio value={2} className='my-3' onChange={() => setIsAccept(true)}>
                  <MessageModal
                    isModalOpen={isOpenModal}
                    onClose={handleCloseModal}
                    children={childModal} />
                  <Text className='text-light'>
                    {TranslationKey('register.iAccept')}{' '}
                    <Text onClick={() => setIsOpenTerm(true)} className='btn-link px-1'>
                      {TranslationKey('hTermOfService')}
                    </Text>{' '}
                    {TranslationKey('register.and')}{' '}
                    <Text onClick={() => setIsOpenPolicy(true)} className='btn-link px-1'>
                      {TranslationKey('hPrivacyPolicy')}
                    </Text>
                  </Text>
                </Radio>
              </Radio.Group>
              <Row justify='center'>
                <Button
                  shape='round'
                  type='primary'
                  className='btn btn-primary w-100'
                  htmlType='submit'
                  size='large'
                  disabled={!isAccept}
                  loading={loading}
                >
                  {TranslationKey('button.submit')}
                </Button>
              </Row>
              <Divider className='text-gray'>{TranslationKey('or')}</Divider>
              <Row gutter={5}>
                <Col span={12} xs={{ span: 24 }} className='mt-2 btn-google'>
                  <Button
                    id='google-login'
                    type='primary'
                    shape='round'
                    className='btn-outline-border icon-fix'
                    size='large'
                    onClick={() => loginGoogle()}
                    block
                    icon={<GoogleOutlined />}
                  >
                    {TranslationKey('google')}
                  </Button>
                </Col>
                <Col xs={{ span: 24 }} className='mt-2'>
                  <FacebookLogin
                    appId={appId}
                    fields='name,email,picture'
                    onClick={signInFacebook}
                    callback={signInFacebook}
                    render={(renderProps) => (
                      <Button
                        type='primary'
                        shape='round'
                        className='btn-outline-border icon-fix'
                        size='large'
                        onClick={renderProps.onClick}
                        block
                        icon={
                          <img
                            src={String(facebookLogo)}
                            alt='facebook logo'
                            style={{
                              height: '1em',
                              marginRight: 8,
                              marginBottom: 5,
                              opacity: 0.7,
                            }}
                          />
                        }
                      >
                        {TranslationKey('facebook')}
                      </Button>
                    )}
                  />
                </Col>
                <Col xs={{ span: 24 }}>
                </Col>
              </Row>
              <div className='text-center mt-4'>
                <Text className='text-light'>
                  {TranslationKey('register.alreadyHaveAnAccount')}
                </Text>
                <Link to={RouteNames.login} className='btn-link px-1'>
                  {TranslationKey('button.login')}
                </Link>
              </div>
            </Form>
          </Col>
        </Row>
      </Content>
      <FullScreenMessageModal
        isModalOpen={isOpenTerm}
        onClose={() => setIsOpenTerm(false)}
        children={textTOS()} />
      <FullScreenMessageModal
        isModalOpen={isOpenPolicy}
        onClose={() => setIsOpenPolicy(true)}
        children={textPolicy()} />
    </>
  );
}