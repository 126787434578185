import TranslationKey from "src/i18n/translation";
import { Col, Pagination, Row } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { AssetRejectedModel, AssetRejectedResponse, PagingRequest, PagingResponse } from "src/models/assetModel";

interface Props {
    rejectedList: AssetRejectedResponse;
    pagingRequest: PagingRequest;
    initPagingRequest: PagingRequest;
    pagingResponse: PagingResponse;
    onChangePage: (page: number, size: number) => void;
}

var columns: ColumnsType<AssetRejectedModel> = [
    {
        title: TranslationKey("myUpload.assetList"),
        dataIndex: 'thumbnailUrl',
        key: 'thumbnailUrl',
        align: 'center',
        colSpan: 2,
        width: 100,
        render: (text) => <img src={text} width={50} height={50} style={{ objectFit: "cover", pointerEvents: "none" }} />,
    },
    {
        title: '',
        dataIndex: 'assetOriginalFileName',
        key: 'assetOriginalFileName',
        align: 'center',
        colSpan: 0,
        width: 100,
        render: (text) => <p className='text-left'>{text}</p>,
    },
    {
        title: TranslationKey("myUpload.rejectedDate"),
        dataIndex: 'rejectDate',
        key: 'rejectDate',
        align: 'center',
        width: 200,
        render: (text) => <p className='text-center'>{new Date(text).toLocaleString("th-TH")}</p>,
    },
    {
        title: TranslationKey("myUpload.rejectedReason"),
        dataIndex: 'rejectReason',
        key: 'rejectReason',
        align: 'center',
        render: (text) => <p className='text-left'>{text['message']}</p>,
    },
];

export default function AssetRejectedList({ rejectedList, pagingRequest, initPagingRequest, pagingResponse, onChangePage }: Props) {
    return (
        <>
            <Row>
                <Col span={24}>
                    <Table rowKey='key'
                        columns={columns}
                        locale={{ emptyText: TranslationKey("table.noData") }}
                        dataSource={rejectedList.list}
                        pagination={false} />
                </Col>
                <Col span={24} className="text-right pt-3">
                    <Pagination
                        defaultCurrent={initPagingRequest.page}
                        current={pagingRequest.page}
                        total={pagingResponse.totalRows}
                        pageSize={initPagingRequest.size}
                        onChange={onChangePage} />
                </Col>
            </Row>
        </>
    );
}