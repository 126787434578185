import { EarningReportModel } from 'src/models/earningReportModel';
import { Button, Card, Col, DatePicker, Form, Input, Row, Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { DateFormat } from 'src/constant/DateFormatConstants';
import GalleryItem from 'src/components/galleryItem/GalleryItem';
import { RouteNames } from 'src/routes/routeName';
import { useEffect, useState } from 'react';
import { ArtistService } from 'src/services/artistService';
import { HttpStatus } from 'src/constant/responseStatus';
import { Link } from 'react-router-dom';
import { RangePickerProps } from 'antd/es/date-picker';
import TranslationKey from 'src/i18n/translation';

export default function EarningReport() {
    const [form] = Form.useForm();
    const { RangePicker } = DatePicker;
    const [earningReports, setEarningReports] = useState<EarningReportModel[]>([]);
    const [assetCode, setAssetCode] = useState('');
    const [startDate, setStartDate] = useState<Date | undefined>();
    const [endDate, setEndDate] = useState<Date | undefined>();

    useEffect(() => {
        onGetEarningReport();
    }, []);

    const onReset = () => {
        form.resetFields();
        setAssetCode('');
        setStartDate(undefined);
        setEndDate(undefined);
        onGetEarningReport()
    };

    const onGetEarningReport = async (assetCode?: string, startDate?: Date, endDate?: Date) => {
        const {
            data,
            status
        } =
            await new ArtistService()
                .getEarningReportAsync(
                    1, 10,
                    assetCode,
                    startDate,
                    endDate);

        if (status !== HttpStatus.OK) {
            return;
        }

        setEarningReports(data);
    };

    const onExportCsv = async () => {
        const
            {
                data,
                status
            } =
                await new ArtistService()
                    .getExportEarningReportAsync(
                        assetCode,
                        startDate,
                        endDate);

        if (status !== HttpStatus.OK) {
            return;
        }

        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(data);
        downloadLink.download = "Earning_Report.csv";

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const onSearchAsync = async () => {
        onGetEarningReport(
            assetCode,
            startDate,
            endDate);
    };

    const onClickItem = (assetId: string) => {
        window.location.href =
            RouteNames.assetDetail
                .replace(':id', assetId);
    };

    const onChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
        if (dates) {
            setStartDate(new Date(dateStrings[0]));
            setEndDate(new Date(dateStrings[1]));

            return;
        }

        setStartDate(undefined);
        setEndDate(undefined);
    };

    const columns: ColumnsType<EarningReportModel> = [
        {
            title: TranslationKey('earningReport.date'),
            dataIndex: 'trasactionDate',
            key: 'trasactionDate',
            align: 'center',
            width: 200,
            render: (_, record) => <>
                {
                    (
                        record.transaction &&
                        record.transaction.createdInfo
                    ) &&
                    (
                        moment(record.transaction.createdInfo.at).format(DateFormat.DateTimeDash)
                    )
                }
            </>,
        },
        {
            title: TranslationKey('earningReport.asset'),
            dataIndex: 'asset',
            key: 'asset',
            render: (_, record, index) => (
                <Row gutter={[16, 0]} wrap={false} align='middle'>
                    <Col flex='none'>
                        <GalleryItem
                            index={index}
                            asset={record.asset}
                            width='50px'
                            height='50px'
                            onClickItem={onClickItem}
                        />
                    </Col>
                    <Col flex='auto'>
                        <Link to={RouteNames.assetDetail.replace(':id', record.asset.id)}>
                            {record.asset.code}{record.asset.name && ('- ' + record.asset.name)}
                        </Link>
                    </Col>
                </Row>
            ),
        },
        {
            title: TranslationKey('earningReport.standardLicense'),
            dataIndex: 'stdLicense',
            key: 'stdLicense',
            align: 'center',
            width: 200,
            render: (_, record) => (
                <>
                    {record.standardLicenseName}
                </>
            ),
        },
        {
            title: TranslationKey('earningReport.extendedLicense'),
            dataIndex: 'extLicense',
            key: 'extLicense',
            align: 'center',
            width: 200,
            render: (_, record) => (
                <>
                    {record.extendedLicenseName}
                </>
            ),
        },
        {
            title: TranslationKey('earningReport.amount'),
            dataIndex: 'commission',
            key: 'commission',
            align: 'center',
            width: 200,
            render: (_, record) => (
                <>
                    {
                        record.transaction.amount.toLocaleString(
                            undefined, {
                            minimumFractionDigits: 2,
                        })
                    }
                </>
            ),
        },
    ];

    return (
        <>
            <Card title={TranslationKey('earningReport.title')} bordered={false}>
                <Form
                    form={form}
                    autoComplete='off'>
                    <Card className='card-form'>
                        <Row gutter={[16, 16]} justify='center'>
                            <Col span={24} md={12} lg={6}>
                                <Form.Item
                                    colon={false}
                                    name='assetcode'
                                    className='text-light w-100'
                                    label={TranslationKey('assetCode')}
                                >
                                    <Input
                                        className='w-100'
                                        size='large'
                                        onChange={(e) => setAssetCode(e.target.value)} />
                                </Form.Item>
                            </Col>
                            <Col span={24} md={12} lg={6}>
                                <Form.Item
                                    colon={false}
                                    name='earningDate'
                                    className='text-light w-100'
                                    label={TranslationKey('earningReport.earningDate')}>
                                    <RangePicker size='large' onChange={onChange} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify='end'>
                            <Col span={24} className='text-right'>
                                <Space wrap>
                                    <Button
                                        size='large'
                                        type='primary'
                                        className='px-5'
                                        onClick={onSearchAsync}>
                                        {TranslationKey('button.search')}
                                    </Button>
                                    <Button
                                        size='large'
                                        type='default'
                                        className='px-5'
                                        onClick={onReset}>
                                        {TranslationKey('button.clear')}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Card>
                </Form>
                <Row className='my-2'>
                    <Col span={24} className='d-flex justify-content-end'>
                        <Button
                            size='large'
                            type='default'
                            className='px-5'
                            onClick={onExportCsv}>
                            {TranslationKey('button.exportCSV')}
                        </Button>
                    </Col>
                </Row>
                <Table
                    locale={{ emptyText: TranslationKey("table.noData") }}
                    columns={columns}
                    dataSource={earningReports}
                    className='table-responsive' />
            </Card>
        </>
    );
}