export const enum GenderType {
    NOTSPECIFIED = "NotSpecified",
    MALE = "Male",
    FEMALE = "Female",
}

export const enum GenderTypeLabel {
    NOTSPECIFIED = "Not Specified",
    MALE = "Male",
    FEMALE = "Female",
}