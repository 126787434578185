import { FavoriteService } from '../../../src/services/favoriteService';
import { Button, Col, Form, Input, Row, Tabs, Card, message, Dropdown, Menu, Divider, Space, Badge } from 'antd';
import { Modal as AntModal } from 'antd';
import { IFavoriteCreateRequest } from '../../models/request/favoriteRequestModel';
import { HttpStatus } from '../../../src/constant/responseStatus';
import { useEffect, useState } from 'react';
import { FavoriteResponseModel } from 'src/models/responses/favoriteModel';
import GalleryItem from 'src/components/galleryItem/GalleryItem';
import { DeleteOutlined, EditOutlined, EllipsisOutlined, ExclamationCircleFilled, ShareAltOutlined } from '@ant-design/icons';
import { Modal } from 'react-bootstrap';
import ShareModal from 'src/components/modal/shareModal';
import TranslationKey from 'src/i18n/translation';

export default function MyFavorite() {
  const favoriteService = new FavoriteService();
  const [messageApi, contextHolder] = message.useMessage();
  const [isShowDelete, setIsShowDelete] = useState(false);
  const [isOpenShareModal, setIsOpenShareModal] = useState(false);
  const [isShowEditNameModal, setIsShowEditNameModal] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [editFavoriteNameForm] = Form.useForm();
  const [favoriteDetail, setFavoriteDetail] = useState<FavoriteResponseModel>();
  const [favorites, setFavorites] = useState<FavoriteResponseModel[]>([]);
  const [favoriteShareUrl, setFavoriteShareUrl] = useState<string>('');

  const addFavoriteAsync = async (data: IFavoriteCreateRequest) => {
    const response = await favoriteService.createFavoriteAsync(data);

    if (response.status !== HttpStatus.CREATED) {
      messageApi.open({
        type: 'error',
        content: TranslationKey('myFavorite.addNewFavoriteFail'),
      });

      return;
    }

    messageApi.open({
      type: 'success',
      content: TranslationKey('myFavorite.addNewFavoriteSuccess'),
    });
    await getAllFavoritesAsync();
  };

  const onSubmitEditFavoriteName = async () => {
    const newFavoriteName = editFavoriteNameForm.getFieldValue('newFavoriteName');
    const favoriteId = favoriteDetail?.id ?? '';

    if (!favoriteId) {
      setIsShowEditNameModal(false)
      return;
    }

    const response = await favoriteService.updateFavoriteNameAsync(favoriteId, newFavoriteName);

    if (response.status !== HttpStatus.OK) {
      messageApi.open({
        type: 'error',
        content: TranslationKey('myFavorite.editFavoriteNameFailed'),
      });

      setIsShowEditNameModal(false)
      return;
    }

    messageApi.open({
      type: 'success',
      content: TranslationKey('myFavorite.editFavoriteNameSuccess'),
    });

    setIsShowEditNameModal(false)
    await getAllFavoritesAsync();
  }

  const getAllFavoritesAsync = async () => {
    const response = await favoriteService.getFavoriteFolderAllAsync();

    if (response.status !== HttpStatus.OK) {
      return;
    }

    setFavorites(response.data);
  };

  const deleteFavoriteByIdAsync = async (favoriteId: string) => {
    const response = await favoriteService.deleteFavoriteAsync(favoriteId);

    if (response.status !== HttpStatus.NO_CONTENT) {
      setIsShowDelete(false);
      messageApi.open({
        type: 'error',
        content: 'Delete favorite failed.',
      });
      return;
    }

    setIsShowDelete(false);
    messageApi.open({
      type: 'success',
      content: 'Delete favorite success.',
    });
    await getAllFavoritesAsync();
  };

  useEffect(() => {
    getAllFavoritesAsync();
  }, []);

  const onClickItem = (assetId: string) => {
    window.location.href = '/asset/' + assetId;
  };

  const onDelete = (assetId: string) => {
    favorites.forEach((fav) => {
      fav.favoriteItems.forEach((item) => {
        if (item.assetId === assetId) {
          onDeleteFavoriteItemAsync(fav.id, item.id);
          return;
        }
      });
    });
  };

  const onDeleteFavoriteItemAsync = async (favId: string, itemId: string) => {
    const { status: responseStatus } =
      await new FavoriteService().deleteFavoriteItemAsync(
        favId,
        itemId
      );

    if (responseStatus !== HttpStatus.NO_CONTENT) {
      return;
    }

    getAllFavoritesAsync();
  };

  const onDeleteFav = async (favId: string) => {
    setDeleteId(favId);
    setIsShowDelete(true);
  };

  const onEditFavoriteNameOpen = (favorite: FavoriteResponseModel) => {
    setFavoriteDetail(favorite)

    setIsShowEditNameModal(true);
    editFavoriteNameForm.resetFields();
  }

  const onOpenShareFavorite = (favoriteId: string) => {
    setFavoriteShareUrl(`${process.env.REACT_APP_REDIRECT_PATH}/favorite-collection/${favoriteId}`);
    setIsOpenShareModal(true);
  }

  const onCloseshareModal = () => {
    setIsOpenShareModal(false)
  };

  const [addNewFavoriteform] = Form.useForm();

  const clearAddNewFolderForm = () => {
    addNewFavoriteform.setFields([
      {
        name: 'name',
        errors: [],
      },
    ]);
  }

  return (
    <>
      {contextHolder}
      <Card title={TranslationKey('myFavorite.title')} bordered={false}>
        <Form
          form={addNewFavoriteform}
          name='addNewFavoriteForm'
          layout='vertical'
          onFinish={addFavoriteAsync}
          className='form-input'>
          <Form.Item
            name='name'
            className=' text-light'
            label={TranslationKey('myFavorite.newFavorite')}
            rules={[{
              required: true,
              message: TranslationKey('myFavorite.requireNewFavoriteName')
            }]}
          >
            <Row gutter={16}>
              <Col span={24} lg={5} md={10} xs={16} className='mt-2'>
                <Input className='w-100' placeholder='Name' size='large' />
              </Col>
              <Col span={24} lg={2} md={4} xs={8} className='mt-2'>
                <Button
                  shape='default'
                  type='primary'
                  size='large'
                  htmlType='submit'>
                  {TranslationKey('button.add')}
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
        <div className='mt-4'>
          <Tabs
            onClick={() => { clearAddNewFolderForm() }}
            defaultActiveKey='1'
            tabPosition={'left'}
            style={{ minHeight: '70vh' }}
            className='tabs-favorite'
            items={favorites.map((fav, i) => {
              const id = String(i);

              return {
                label: <>
                  <div onClick={() => { clearAddNewFolderForm() }}
                    className='favorite-folder-tab-item'>
                    <span className='folder-name-box'>
                      {fav.name}
                    </span>
                    <span className='folder-name-suffix'>
                      <span>
                        {
                          fav.favoriteItems.length > 0 &&
                          <Badge
                            count={fav.favoriteItems.length}
                            overflowCount={999}
                            color="grey" />
                        }
                      </span>
                      <Dropdown
                        overlay=
                        {
                          <Menu>
                            <Menu.Item
                              key="editFavName"
                              onClick={() => onEditFavoriteNameOpen(fav)}>
                              <EditOutlined />
                              <span
                                className='favorite-action-label'>
                                {TranslationKey('myFavorite.editName')}
                              </span>
                            </Menu.Item>
                            <Menu.Item
                              key="sahreFav"
                              onClick={() => onOpenShareFavorite(fav.id)}>
                              <ShareAltOutlined />
                              <span className='favorite-action-label'>
                                {TranslationKey('myFavorite.share')}
                              </span>
                            </Menu.Item>
                            <Menu.Item
                              key="deleteFav"
                              onClick={() => onDeleteFav(fav.id)}>
                              <DeleteOutlined />
                              <span className='favorite-action-label'>
                                {TranslationKey('myFavorite.delete')}
                              </span>
                            </Menu.Item>
                          </Menu>
                        }
                        trigger={['click']}>
                        <Button icon={<EllipsisOutlined />} type='text' />
                      </Dropdown>
                    </span>
                  </div>
                </>,
                key: id,
                children: (
                  <>
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <h6>
                          <span className='ml-2 fw-bold'>
                            {fav.name}
                          </span>
                        </h6>
                        <Divider></Divider>
                        <Row gutter={[16, 16]}>
                          {
                            fav.favoriteItems
                              .map((d, index) => (
                                <Col xs={24} lg={4} className='mt-2' key={index}>
                                  <GalleryItem
                                    index={index}
                                    asset={d.asset}
                                    width={'100%'}
                                    height={'100%'}
                                    onClickItem={onClickItem}
                                    onDelete={onDelete}
                                  />
                                </Col>
                              ))
                          }
                        </Row>
                      </Col>
                    </Row>
                  </>
                ),
              };
            })}
          />
        </div>
      </Card>

      <AntModal
        title={
          <div>
            <ExclamationCircleFilled className='text-danger' />
            <span style={{ marginLeft: '8px' }}>
              {TranslationKey('myFavorite.doYouWantToDelete')}
            </span>
          </div>
        }
        open={isShowDelete}
        okType='danger'
        okButtonProps={{ type: 'primary' }}
        onOk={() => deleteFavoriteByIdAsync(deleteId)}
        okText={TranslationKey('button.confirm')}
        onCancel={() => setIsShowDelete(false)}
        cancelText={TranslationKey('button.cancel')}
      >
      </AntModal>

      <ShareModal
        isModalOpen={isOpenShareModal}
        sharedUrl={favoriteShareUrl}
        onClose={() => onCloseshareModal()}
      />
      <Modal
        size='lg'
        show={isShowEditNameModal}>
        <Modal.Header>
          <Modal.Title style={{ color: 'black' }}>
            {TranslationKey('myFavorite.editFavoriteName')}: {favoriteDetail?.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            form={editFavoriteNameForm}
            name='editFavoriteNameForm'
            layout='vertical'
            onFinish={onSubmitEditFavoriteName}
            className='form-input'>
            <Row>
              <Col span={24}>
                <Form.Item
                  name='newFavoriteName'
                  label={TranslationKey('myFavorite.newFavoriteName')}
                  rules={
                    [
                      {
                        validator: (_, value) => {
                          if (!value || value.trim() === '') {
                            return Promise.reject(
                              TranslationKey('myFavorite.requireNewFavoriteName')
                            );
                          }

                          return Promise.resolve();
                        },
                      },
                    ]}>
                  <Input
                    className="w-100"
                    placeholder={TranslationKey('myFavorite.newFavoriteName')}
                    size="large" />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ float: 'right' }}>
              <Col span={24}>
                <Space>
                  <Button
                    type='default'
                    shape='round'
                    size='large'
                    onClick={() => { setIsShowEditNameModal(false) }}>
                    {TranslationKey('button.cancel')}
                  </Button>
                  <Button
                    type='primary'
                    shape='round'
                    size='large'
                    htmlType='submit'>
                    {TranslationKey('button.confirm')}
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
