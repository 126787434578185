import { AssetStatus, AssetStatusName, AssetUsage } from 'src/constant/assetConstants';
import { Card, Col, Row, Tabs, TabsProps } from 'antd';
import AssetThumnail from './asset-thumnail';
import AssetRejectedList from './asset-list';
import EditingAssetModel from '../../../models/request/editingAssetRequestModel';
import { useEffect, useState } from 'react';
import { AssetService } from 'src/services/assetService';
import { HttpStatus } from 'src/constant/responseStatus';
import { AssetRejectedResponse, PagingRequest } from '@/src/models/assetModel';
import TranslationKey from 'src/i18n/translation';
import { Trans } from 'react-i18next';

interface Props {
  reloadData: boolean;
  tabValue: string;
  onSelectedItem: (item: EditingAssetModel) => void;
  onSelectedItemAll: (item: EditingAssetModel[]) => void;
  onLoadDataDone: () => void;
  onTabChanged: (assetStatus: string) => void;
}

const initAssetRejectedResponse: AssetRejectedResponse = {
  paging: {
    totalRows: 0,
  },
  list: [],
};

const initPagingRequest: PagingRequest = {
  page: 1,
  size: 10,
};

export default function AssetTabItem(
  {
    reloadData,
    tabValue,
    onSelectedItem,
    onSelectedItemAll,
    onLoadDataDone,
    onTabChanged
  }: Props) {
  const [myUpload, setMyUpload] = useState<EditingAssetModel[]>([]);
  const [assetStatus, setAssetStatus] = useState<string | undefined>();
  const [myUploadRejected, setMyUploadRejected] = useState<AssetRejectedResponse>(initAssetRejectedResponse);
  const [pagingRequest, setPagingRequest] = useState<PagingRequest>(initPagingRequest);
  const [, setIsResetCheckAll] = useState(false);

  useEffect(() => {
    onAssetTabChange(tabValue);
    setIsResetCheckAll(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabValue]);

  const onGetMyUploadAsync = async (assetStatus: string | undefined) => {
    if (assetStatus) {
      assetStatus === AssetStatus.REJECTED
        ? getRejectedAssetAsync()
        : getUncompletedAssetAsync(assetStatus);
    }
  };

  const getRejectedAssetAsync = async () => {
    const { data, status } = await new AssetService().getMyUploadRejected(pagingRequest);

    onLoadDataDone();

    if (status !== HttpStatus.OK) {
      return;
    }

    setMyUploadRejected(data);
  };

  const getUncompletedAssetAsync = async (assetStatus: string) => {
    const { data, status } = await new AssetService().getMyUpload(assetStatus);

    onLoadDataDone();

    if (status !== HttpStatus.OK) {
      return;
    }

    const myUploadData: EditingAssetModel[] = data.map((rd) => ({
      id: rd.id,
      name: rd.name,
      fileName: rd.fileName,
      thumbnailImageInfo: rd.thumbnailImageInfo,
      assetType: rd.assetType,
      description: rd.description,
      keywords: rd.keywords,
      cameraInfo: rd.cameraInfo,
      rejectedDate: rd.rejectedDate,
      rejectedReason: rd.rejectedReason,
      releaseItems: rd.releaseItems,
      isMatureContent: rd.isMatureContent,
      usage: rd.usage ?? AssetUsage.Commercial,
      photoInfo: rd.photoInfo,
      isSelected: false,
    }));

    setMyUpload(myUploadData);
  };

  useEffect(() => {
    if (reloadData) {
      onGetMyUploadAsync(assetStatus);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadData]);

  const onAssetTabChange = (assetStatus: string) => {
    setAssetStatus(assetStatus);

    onTabChanged(assetStatus);

    onGetMyUploadAsync(assetStatus);
  };

  useEffect(() => {
    getRejectedAssetAsync();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagingRequest]);

  const onChangePage = (page: number, size: number) => {
    const pagingChanged: PagingRequest = {
      page: page,
      size: size,
    };

    setPagingRequest(pagingChanged);
  };

  const assetTabItems: TabsProps['items'] = [
    {
      key: AssetStatus.DRAFT.toString(),
      label: TranslationKey('assetStatus.DRAFT'),
      children: (
        <AssetThumnail
          watermaskContent={AssetStatusName.DRAFT}
          myUpload={myUpload}
          onSelectedItem={onSelectedItem}
          onSelectedItemAll={onSelectedItemAll} />
      ),
    },
    {
      key: AssetStatus.PENDING.toString(),
      label: TranslationKey('assetStatus.PENDING'),
      children: (
        <AssetThumnail
          watermaskContent={AssetStatusName.PENDING}
          myUpload={myUpload}
          onSelectedItem={onSelectedItem}
          onSelectedItemAll={onSelectedItemAll} />
      ),
    },
    {
      key: AssetStatus.REJECTED.toString(),
      label: TranslationKey('assetStatus.REJECTED'),
      children: (
        <AssetRejectedList
          rejectedList={myUploadRejected}
          pagingRequest={pagingRequest}
          initPagingRequest={initPagingRequest}
          pagingResponse={myUploadRejected.paging}
          onChangePage={onChangePage}></AssetRejectedList>
      ),
    },
  ];

  return (
    <>
      <Col
        xs={{ span: 24 }}
        xl={{
          span: assetStatus == AssetStatus.REJECTED
            ? 24
            : 12
        }}>
        <Card
          title={TranslationKey('myUpload.title')}
          bordered={false}
          className='my-upload-card-body'>
          <Row>
            <Col
              className='mt-1'
              span={24}>
              <Tabs
                items={assetTabItems}
                defaultActiveKey={tabValue}
                activeKey={tabValue}
                onChange={onAssetTabChange}
                className='fix-ma'
              />
            </Col>
          </Row>
        </Card>
      </Col>
    </>
  );
}
