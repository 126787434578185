import { HttpStatus } from 'src/constant/responseStatus';
import { Button, Card, Modal, message } from 'antd';
import { ReleasesService } from 'src/services/releasesService';
import { useEffect, useState } from 'react';
import Table, { ColumnsType } from 'antd/es/table';
import { IReleaseResponseModel } from 'src/models/request/releaseModel';
import { useNavigate } from 'react-router-dom';
import { RouteNames } from 'src/routes/routeName';
import { ExclamationCircleFilled } from '@ant-design/icons';
import TranslationKey from 'src/i18n/translation';

export default function MyReleases() {
  const [releases, setReleases] = useState<IReleaseResponseModel[]>([]);
  const navigate = useNavigate();
  const { confirm } = Modal;

  useEffect(() => {
    getReleasesAsync();
  }, []);

  const getReleasesAsync = async () => {
    const response = await new ReleasesService().getAllByArtist();

    if (response.status !== HttpStatus.OK) {
      return;
    }

    setReleases(response.data);
  };

  const onDetail = (id?: string) => {
    if (id) {
      navigate(`${RouteNames.releaseDetail}?id=${id}`);
    } else {
      navigate(RouteNames.releaseDetail);
    }
  };

  const onDelete = (id: string) => {
    confirm({
      title: TranslationKey('release.doYouWantToDeleteThisRelease'),
      icon: <ExclamationCircleFilled className='txt-primary' />,
      okText: TranslationKey('button.confirm'),
      cancelText: TranslationKey('button.cancel'),
      okType: 'danger',
      async onOk() {
        const response = await new ReleasesService().deleteReleaseById(id);

        if (response.status !== HttpStatus.OK) {
          message.error(TranslationKey('release.deleteReleaseFail'),);

          return;
        }

        message.success(TranslationKey('release.deleteReleaseSuccess'),);

        getReleasesAsync();
      },
      onCancel() { },
    });
  };

  const columns: ColumnsType<IReleaseResponseModel> = [
    {
      title: TranslationKey('release.releaseName'),
      dataIndex: 'name',
      key: 'name',
      width: '60%',
    },
    {
      title: TranslationKey('release.releaseType'),
      dataIndex: 'releaseModel',
      key: 'releaseModel',
      align: 'center',
      render: (_, record: IReleaseResponseModel) => (
        record.releaseModel
          ? <>{TranslationKey('release.releaseTypes.model')}</>
          : <>{TranslationKey('release.releaseTypes.property')}</>
      ),
    },
    {
      title: TranslationKey('release.detail'),
      key: 'detail',
      align: 'center',
      render: (_, record: IReleaseResponseModel) => (
        <>
          <Button type='link' size='large' onClick={() => onDetail(record.id)}>
            {TranslationKey('button.detail')}
          </Button>
          <Button danger type='link' size='large' onClick={() => onDelete(record.id)}>
            {TranslationKey('button.delete')}
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <Card
        title={TranslationKey('release.myRelease')}
        bordered={false}
        extra={
          <>
            <Button
              type='primary'
              size='large'
              onClick={() => onDetail(undefined)}>
              {TranslationKey('release.addNewRelease')}
            </Button>
          </>
        }
      >
        <Table
          locale={{ emptyText: TranslationKey("table.noData") }}
          columns={columns}
          dataSource={releases}
          rowKey='id' />
      </Card>

    </>
  );
}
