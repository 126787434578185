import { Row, Col, Form, Input, Button, Card, message, Modal } from 'antd';
import { HttpStatus } from '../../constant/responseStatus';
import { AccountService } from '../../../src/services/accountService';
import IChangePasswordRequest from '../../../src/models/request/changePasswordRequestModel';
import TranslationKey from 'src/i18n/translation'
import { RegexPattern } from 'src/constant/constants';
import { useSessionContext } from 'src/context/session-context';
import { ExclamationCircleFilled } from '@ant-design/icons';

export default function ChangePassword() {
  const accountService = new AccountService();
  const [messageApi, contextHolder] = message.useMessage();
  const { signOut } = useSessionContext();
  const { confirm } = Modal;

  const changePassword = async (data: IChangePasswordRequest) => {
    if (data.newPassword !== data.confirmNewPassword) {
      messageApi.open({
        type: 'error',
        content: TranslationKey('changePassword.passwordDoNotMatch'),
      });

      return;
    }

    const response = await accountService.changePasswordAsync(data);

    if (response.data === 'BadRequest') {
      messageApi.open({
        type: 'error',
        content: TranslationKey('changePassword.changePasswordFailedOldPasswordIsIncorrect'),
      });

      return;
    }

    if (response.status !== HttpStatus.OK) {
      messageApi.open({
        type: 'error',
        content: TranslationKey('changePassword.changePasswordFailed'),
      });

      return;
    }

    messageApi.open({
      type: 'success',
      content: TranslationKey('changePassword.changePasswordSuccess'),
    })
      .then(() => signOut());
  };

  const showConfirmChangePasswordModal = async (data: IChangePasswordRequest) => {
    confirm({
      title: TranslationKey('changePassword.confirmChangePassword'),
      icon: <ExclamationCircleFilled />,
      onOk() {
        changePassword(data);
      },
      okText: TranslationKey('button.ok'),
      cancelText: TranslationKey('button.cancel')
    });
  };

  return (
    <>
      {contextHolder}
      <Form
        name='formChangePassword'
        layout='vertical'
        onFinish={showConfirmChangePasswordModal}
        className='form-input'>
        <Card
          title={TranslationKey('changePassword.title')}
          bordered={false}
          extra={
            <Button type='primary' htmlType='submit' size='large'>
              {TranslationKey('button.submit')}
            </Button>
          }
        >
          <Row>
            <Col span={24} lg={8}>
              <Form.Item
                name='currentPassword'
                className='mb-4 text-light'
                label={TranslationKey('changePassword.currentPassword')}
                rules={[
                  {
                    required: true,
                    message: TranslationKey('changePassword.requireCurrentPassword')
                  },
                  {
                    pattern: RegexPattern.password,
                    message: TranslationKey('changePassword.passwordInvalidRule')
                  }
                ]}
              >
                <Input.Password
                  placeholder={TranslationKey('changePassword.currentPassword')}
                  className='w-100'
                  size='large' />
              </Form.Item>
              <Form.Item
                name='newPassword'
                className='mb-4 text-light'
                label={TranslationKey('changePassword.newPassword')}
                rules={[
                  {
                    required: true,
                    message: TranslationKey('changePassword.requireNewPassword')
                  },
                  {
                    pattern: RegexPattern.password,
                    message: TranslationKey('changePassword.passwordInvalidRule')
                  }
                ]}
              >
                <Input.Password
                  placeholder={TranslationKey('changePassword.newPassword')}
                  className='w-100'
                  size='large' />
              </Form.Item>
              <Form.Item
                name='confirmNewPassword'
                className='mb-4 text-light'
                label={TranslationKey('changePassword.confirmNewPassword')}
                rules={[
                  {
                    required: true,
                    message: TranslationKey('changePassword.requireConfirmNewPassword')
                  },
                  {
                    pattern: RegexPattern.password,
                    message: TranslationKey('changePassword.passwordInvalidRule')
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('newPassword') === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error(TranslationKey('changePassword.passwordDoNotMatch'))
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  placeholder={TranslationKey('changePassword.confirmNewPassword')}
                  className='w-100'
                  size='large' />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </>
  );
}