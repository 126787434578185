import { HttpStatus } from 'src/constant/responseStatus';
import { ArtistService } from 'src/services/artistService';
import { Badge, Button, Card, Col, Divider, Form, Input, Modal, Row, Space, Typography, Upload, UploadProps, message } from 'antd';
import { useEffect, useState } from 'react';
import { PayoutInfoStatus } from 'src/constant/statusConstants';
import { PayoutInfoCreatingModel, PayoutInfoModel } from 'src/models/PayoutInfoModel';
import { useSessionContext } from 'src/context/session-context';
import SelectBank from 'src/components/Selector/SelectBank';
import { UploadOutlined } from '@ant-design/icons';
import { S3Constants } from 'src/constant/s3Constants';
import AWS from 'aws-sdk';
import { UploadFileConstants, UploadListType, UploadValidationMessages } from 'src/constant/uploadFileConstants';
import { StringConstants } from 'src/constant/stringConstants';
import { AccountService } from 'src/services/accountService';
import TranslationKey from 'src/i18n/translation';

export default function PayoutInfo() {
  const [payoutInfo, setPayoutInfo] = useState<PayoutInfoModel>({} as PayoutInfoModel);
  const { userProfile, getUserProfileAsync, artistProfile } = useSessionContext();
  const [bankCode, setBankCode] = useState('');
  const [form] = Form.useForm();
  const { Text } = Typography;
  const [messageApi, contextHolder] = message.useMessage();
  const [, setIdCardFileList] = useState<any[]>([]);
  const [, setBookBankFileList] = useState<any[]>([]);
  const [, setUploadFilePath] = useState<string>(StringConstants.EMPTY);
  const [uploadFileName, setUploadFileName] = useState<string>(StringConstants.EMPTY);
  const [idCardUrl, setIdCardUrl] = useState<string|undefined>(undefined);
  const [bookBankUrl, setBookBankUrl] = useState<string|undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  AWS.config.update({
    accessKeyId: S3Constants.ACCESS_KEY_ID,
    secretAccessKey: S3Constants.SECRET_ACCESS_KEY,
  });

  const S3_BUCKET = S3Constants.BUCKET;
  const REGION = S3Constants.REGION;
  const s3Bucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  useEffect(() => {
    if (userProfile && userProfile.userId) {
      onGetPayoutInfo(userProfile.userId);
    } else {
      getUserProfileAsync();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile]);

  useEffect(() => {
    if (artistProfile) {
      onGetArtist(artistProfile.id);
    }
  }, [artistProfile]);

  const onFinish = async (values: any) => {
    Modal.success({
      title: 'Save',
      content: 'Confirm your payout-info?',
      cancelText: 'Cancel',
      onOk: () => {
        onSavePayoutInfo();
      },
  });
  };

  const onSavePayoutInfo = async () => {
    setIsLoading(true);
    const model: PayoutInfoCreatingModel = {
      bankBrand: bankCode,
      accountNumber: form.getFieldValue('accountNumber'),
      accountName: form.getFieldValue('accountName'),
    };

    if(!idCardUrl || !bookBankUrl){
      setIsLoading(false);
      messageApi.open({
        type: 'warning',
        content: 'Pleases Attachment ID Card and Book Bank.',
      });
      return;
    }

    if(payoutInfo.id){
      const { status } = await new ArtistService().UpdatePayoutInfoAsync(userProfile.userId, model);

      if (status !== HttpStatus.OK) {
        setIsLoading(false);
        messageApi.open({
          type: 'error',
          content: 'Save payout-into failed.',
        });
        return;
      }
    }
    else{
      const {status } = await new ArtistService().CreatePayoutInfoAsync(userProfile.userId, model);

      if (status !== HttpStatus.CREATED) {
        setIsLoading(false);
        messageApi.open({
          type: 'error',
          content: 'Save payout-into failed.',
        });
        return;
      }
    }

    setIsLoading(false);
    messageApi.open({
      type: 'success',
      content: 'Save payout-into success.',
    });
  }

  const onGetPayoutInfo = async (id: string) => {
    const { data, status } = await new ArtistService().getPayoutInfoAsync(id);

    if (status !== HttpStatus.OK) {
      return;
    }

    setPayoutInfo(data);
    setBankCode(data.bankBrand);
    form.setFieldValue('accountNumber', data.bankAccountNumber);
    form.setFieldValue('accountName', data.bankAccountName);
  };

  const onGetArtist = async (id: string) => {
    const { data, status } = await new AccountService().getArtistProfile(id);

    if (status !== HttpStatus.OK) {
      return;
    }

    if(data && data.artist){
      setIdCardUrl(data.artist.idCardUrl!);
      setBookBankUrl(data.artist.bookBankUrl!);
    }
  };

  function renderStatus(status: string) {
    if (status === PayoutInfoStatus.Verified) {
      return (
        <Badge
          status='success'
          text={status}
        />
      );
    } else {
      return (
        <Badge
          color='#bfbfbf'
          text={status}
        />
      );
    }
  }

  const getFileName = (originalFileName: string) => {
    const now = new Date();

    return (
      UploadFileConstants.FILE_NAME_PREFIX +
      userProfile.userId +
      StringConstants.UNDERSCORE +
      'release' +
      StringConstants.UNDERSCORE +
      now.getUTCFullYear() +
      (now.getUTCMonth() + 1) +
      now.getUTCDate() +
      now.getUTCHours() +
      now.getUTCMinutes() +
      now.getUTCSeconds() +
      now.getUTCMilliseconds() +
      StringConstants.UNDERSCORE +
      originalFileName
    );
  };

  const getS3FileUrl = (fileName: string) => {
    return `${S3Constants.FILE_NAME_FORMAT}${fileName}`;
  };

  const deleteFileFromS3 = async (fileName: string) => {
    const params = {
      Bucket: S3Constants.BUCKET,
      Key: fileName,
    };

    await s3Bucket.deleteObject(params, (err) => {
      if (err) {
        console.log(err, err.stack);
        message.error(`${fileName} file remove failed.`);
      }
    });
  };

  const addFileToS3 = async (originalFile: any, attachType: string, onError: any, onSuccess: any, onProgress: any) => {
    const fileName = getFileName(originalFile.name);
    const filePath = getS3FileUrl(fileName);
    setUploadFilePath(filePath);
    setUploadFileName(fileName);

    const params = {
      ACL: S3Constants.ACL_TERM,
      Body: originalFile,
      Bucket: S3_BUCKET,
      Key: fileName,
      ContentType: originalFile.type,
    };

    s3Bucket
      .putObject(params)
      .on(S3Constants.EVENT_ON_UPLOAD, (evt) => {
        const progressPercent = Math.round((evt.loaded / evt.total) * 100);
        onProgress!({ percent: progressPercent });
      })
      .promise()
      .then(
        async () => {
          await addArtistAttach(
                    originalFile,
                    fileName,
                    attachType,
                    onError,
                    onSuccess,
                    onProgress);
        },
        (err) => {
          onError!(err);
          throw err;
        }
      );
  };

  const validateUploadFile = async (file: File, onError: any) => {
    const unknowFileType = !file.type || file.type === StringConstants.EMPTY;
    const fileTypeNotSupport = !UploadFileConstants.MIME_RELEASES_ACCEPT.includes(file.type);

    if (unknowFileType || fileTypeNotSupport) {
      const errorMessage = file.name + StringConstants.SPACE + UploadValidationMessages.INVALID_FILE_TYPE;

      onError(errorMessage);

      return errorMessage;
    }

    return StringConstants.EMPTY.toString();
  };

  const addArtistAttach = async (
    originalFile: any,
    fileName: string,
    attachType: string,
    onError: any,
    onSuccess: any,
    onProgress: any
  ) => {
    const filePath = getS3FileUrl(fileName);
    if(attachType === 'idcard'){
      const response = await new ArtistService().UpdateArtistIdCard(artistProfile.id, filePath);

      onProgress!({ percent: 100 });
      if (response.status !== HttpStatus.OK) {
        const errorMessage = `File ${originalFile.name} : ${response.data.failedReason}`;
        onError!(errorMessage);

        return;
      }

      onSuccess!(response.data);
    } else {
      const response = await new ArtistService().UpdateArtistBookBank(artistProfile.id, filePath);

      onProgress!({ percent: 100 });
      if (response.status !== HttpStatus.OK) {
        const errorMessage = `File ${originalFile.name} : ${response.data.failedReason}`;
        onError!(errorMessage);

        return;
      }

      onSuccess!(response.data);
    }
  };

  const idCardProps: UploadProps = {
    multiple: false,
    listType: UploadListType.TEXT,
    accept: UploadFileConstants.MIME_RELEASES_ACCEPT.toString(),
    maxCount: 1,
    showUploadList: true,
    progress: {
      strokeColor: {
        '0%': '#DBDBFF',
        '100%': '#4D4DFF',
      },
      strokeWidth: 5,
      showInfo: false,
      status: 'active',
    },
    async onChange(info) {
      const { fileList, file } = info;
      if (fileList.length > 0 && fileList.every(file => (file.status === 'done' || file.status === 'error')) && file.status !== 'removed') {
        onGetPayoutInfo(userProfile.userId);
        onGetArtist(artistProfile.id);
      }
    },
    beforeUpload: (file: any) => {
      setIdCardFileList([file]);
    },
    async onRemove() {
      deleteFileFromS3(uploadFileName);
      setIdCardFileList([]);
    },
    async customRequest({ file, onError, onSuccess, onProgress }) {
      const originalFile = file as File;

      let errorMessage = await validateUploadFile(originalFile, onError);

      if (errorMessage !== StringConstants.EMPTY) {
        message.error(errorMessage, 10);

        return;
      }

      await addFileToS3(originalFile, 'idcard', onError, onSuccess, onProgress);
    },
  };

  const bookBankProps: UploadProps = {
    multiple: false,
    listType: UploadListType.TEXT,
    accept: UploadFileConstants.MIME_RELEASES_ACCEPT.toString(),
    maxCount: 1,
    showUploadList: true,
    progress: {
      strokeColor: {
        '0%': '#DBDBFF',
        '100%': '#4D4DFF',
      },
      strokeWidth: 5,
      showInfo: false,
      status: 'active',
    },
    async onChange(info) {
      const { fileList, file } = info;
      if (fileList.length > 0 && fileList.every(file => (file.status === 'done' || file.status === 'error')) && file.status !== 'removed') {
        onGetPayoutInfo(userProfile.userId);
        onGetArtist(artistProfile.id);
      }
    },
    beforeUpload: (file: any) => {
      setBookBankFileList([file]);
    },
    async onRemove() {
      deleteFileFromS3(uploadFileName);
      setBookBankFileList([]);
    },
    async customRequest({ file, onError, onSuccess, onProgress }) {
      const originalFile = file as File;

      let errorMessage = await validateUploadFile(originalFile, onError);

      if (errorMessage !== StringConstants.EMPTY) {
        message.error(errorMessage, 10);

        return;
      }

      await addFileToS3(originalFile, 'bookbank', onError, onSuccess, onProgress);
    },
  };

  return (
    <>
      {contextHolder}
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}>
        <Card
          title={TranslationKey('payoutInfo.title')}
          bordered={false}
          extra={
            <Space>
              <Text type='secondary'>Status : </Text>
              {renderStatus(payoutInfo.status)}
              <Button
                type='primary'
                size='large'
                shape='round'
                htmlType='submit'
                loading={isLoading}>
                  {TranslationKey('button.save')}
              </Button>
            </Space>
          }>
          <Row gutter={16}>
            <Col span={24}>
              <div className='text-danger fw-bold'>{TranslationKey('payoutInfo.note')}</div>
              <ul className='text-danger'>
                <li>{TranslationKey('payoutInfo.whenYouSubmit')}</li>
                <li>{TranslationKey('payoutInfo.whenThePayoutInfoStatus')}</li>
              </ul>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col
              span={24}
              md={12}
              lg={6}>
              <Form.Item
                name='bankBrand'
                className='mt-4 text-light'
                label={TranslationKey('payoutInfo.bank')}>
                <SelectBank
                  className='non-radius'
                  bankCode={bankCode}
                  sendBankCode={(code) => setBankCode(code)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col
              span={24}
              md={12}
              lg={6}>
              <Form.Item
                name='accountNumber'
                className='mt-4 text-light'
                label={TranslationKey('payoutInfo.bankAccountNumber')}
                rules={[{ required: true, message: 'Please input your Payout Method!' }]}>
                <Input
                  className='w-100'
                  size='large'
                />
              </Form.Item>
            </Col>
            <Col
              span={24}
              md={12}
              lg={6}>
              <Form.Item
                name='accountName'
                className='mt-4 text-light'
                label={TranslationKey('payoutInfo.bankAccountName')}
                rules={[{ required: true, message: 'Please input your Payout Method!' }]}>
                <Input
                  className='w-100'
                  size='large'
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col xs={24} md={12}>
              <Form.Item
                name='idCard'
                className='mt-4 text-light'
                label={TranslationKey('payoutInfo.idCard')}
                required>
                {
                  idCardUrl ? (
                    <div className='d-flex gap-2'>
                      <a href={idCardUrl} target='_blank' rel="noreferrer" >สำเนาบัตรประชาชน</a>
                      <Button type='default' size='small'
                        onClick={() => setIdCardUrl(undefined)} danger>
                          {TranslationKey('button.newUpload')}
                      </Button>
                    </div>
                  ) : (
                    <Upload {...idCardProps}>
                      <Button icon={<UploadOutlined />}>Click to Upload ID Card</Button>
                    </Upload>
                  )
                }
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name='bookBank'
                className='mt-4 text-light'
                label='Book Bank'
                required>
                {
                  bookBankUrl ? (
                    <div className='d-flex gap-2'>
                      <a href={bookBankUrl} target='_blank' rel="noreferrer">สำเนาบัญชีธนาคาร</a>
                      <Button type='default' size='small' onClick={() => setBookBankUrl(undefined)} danger>{TranslationKey('button.newUpload')}</Button>
                    </div>
                  ) : (
                    <Upload {...bookBankProps}>
                      <Button icon={<UploadOutlined />}>Click to Upload Book Bank</Button>
                    </Upload>
                  )
                }
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </>
  );
}
