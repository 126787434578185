import { RecommendAssetItemModel } from '../../models/BannerModel';
import { useEffect, useState } from 'react';
import { BannerService } from '../../services/bannerService';
import { HttpStatus } from '../../constant/responseStatus';
import { AnimationSliderClassName } from '../../constant/assetConstants';
import { Skeleton, message } from 'antd';
import { shuffle } from 'lodash';
import { useSessionContext } from 'src/context/session-context';
import RecommendItem from './recommendItem';

export default function Recommended() {
    const [recommendAsset1, setRecommendAsset1] = useState<RecommendAssetItemModel[]>([]);
    const [recommendAsset2, setRecommendAsset2] = useState<RecommendAssetItemModel[]>([]);
    const [recommendAsset1Active, setRecommendAsset1Active] = useState(false);
    const [recommendAsset2Active, setRecommendAsset2Active] = useState(false);
    const { getOrderAssets, getCountOrder } = useSessionContext();

    useEffect(() => {
        onGetRecommendAssetAsync();
        getOrderAssets();
        getCountOrder();
    }, []);

    const onGetRecommendAssetAsync = async () => {
        setRecommendAsset1Active(false);
        setRecommendAsset2Active(false);

        const {
            data: responseData,
            status: responseStatus
        } = await new BannerService().GetRecommendAsset();

        if (responseStatus !== HttpStatus.OK) {
            return;
        }

        if (responseData == null || responseData.length == 0) {
            return;
        }

        const recommendAssetItems = responseData.flatMap(item => item.recommendAssetItems);

        if (recommendAssetItems.length == 1) {
            let extendedAssetCollection1 = [...recommendAssetItems];
            while (extendedAssetCollection1.length < 100) {
                extendedAssetCollection1 = extendedAssetCollection1.concat(recommendAssetItems);
            }

            setRecommendAsset1(shuffle(extendedAssetCollection1));
            setRecommendAsset1Active(true);

            return;
        }

        const halfIndex = recommendAssetItems.length / 2;
        const assetCollection1 = shuffle(recommendAssetItems.slice(0, halfIndex));
        const assetCollection2 = shuffle(recommendAssetItems.slice(halfIndex, recommendAssetItems.length))

        let extendedAssetCollection1 = [...assetCollection1];
        while (extendedAssetCollection1.length < 100) {
            extendedAssetCollection1 = extendedAssetCollection1.concat(assetCollection1);
        }

        let extendedAssetCollection2 = [...assetCollection2];
        while (extendedAssetCollection2.length < 100) {
            extendedAssetCollection2 = extendedAssetCollection2.concat(assetCollection2);
        }

        setRecommendAsset1(extendedAssetCollection1);
        setRecommendAsset2(extendedAssetCollection2);

        setRecommendAsset1Active(true);
        setRecommendAsset2Active(true);
    };

    return (
        <>
            <div className='sliders'>
                <div className={AnimationSliderClassName.Fast} id='slider-recomment-0'>
                    {
                        recommendAsset1Active
                            ?
                            recommendAsset1.map((recommentItem, recommentItemIndex) => (
                                <RecommendItem
                                    key={`slider-recomment-key-0-${recommentItemIndex}`}
                                    sliderId={`slider-recomment-id-0-${recommentItemIndex}`}
                                    recommentAssetItem={recommentItem}
                                    recommentAssetItemIndex={recommentItemIndex} />
                            ))
                            :
                            Array.from({ length: 12 }).map((_, index) => (
                                <div className='slider' key={`slider-recomment-blank-key-0-${index}`}>
                                    <Skeleton.Image active />
                                </div>
                            ))
                    }
                </div>
                <div className='box-gradient' />
                <div className='box-gradient-end' />
            </div>
            <div className='sliders'>
                <div className={AnimationSliderClassName.Slow} id='slider-recommend-1'>
                    {
                        recommendAsset2Active
                            ?
                            recommendAsset2.map((recommentItem, recommentItemIndex) => (
                                <RecommendItem
                                    key={`slider-recomment-key-1-${recommentItemIndex}`}
                                    sliderId={`slider-recomment-id-1-${recommentItemIndex}`}
                                    recommentAssetItem={recommentItem}
                                    recommentAssetItemIndex={recommentItemIndex} />
                            ))
                            :
                            Array.from({ length: 12 }).map((_, index) => (
                                <div className='slider' key={`slider-recomment-blank-key-1-${index}`}>
                                    <Skeleton.Image active />
                                </div>
                            ))
                    }
                </div>
                <div className='box-gradient' />
                <div className='box-gradient-end' />
            </div>
        </>
    );
}
