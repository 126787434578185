import { HttpStatus } from 'src/constant/responseStatus';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Upload,
  UploadProps,
} from 'antd';
import { ReleasesService } from 'src/services/releasesService';
import { useEffect, useState } from 'react';
import ReleaseModel from 'src/models/request/releaseModel';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { UploadFileConstants, UploadListType, UploadValidationMessages } from 'src/constant/uploadFileConstants';
import { StringConstants } from 'src/constant/stringConstants';
import { S3Constants } from 'src/constant/s3Constants';
import AWS from 'aws-sdk';
import { useSessionContext } from 'src/context/session-context';
import { MasterDataService } from 'src/services/masterDataService';
import { MasterDataType } from 'src/constant/masterDataConstants';
import MasterDataModel from 'src/models/request//masterDataModel';
import OptionModel from 'src/models/OptionModel';
import { RouteNames } from 'src/routes/routeName';
import { ReleaseType } from 'src/constant/ReleaseType';
import TranslationKey from 'src/i18n/translation';
import { GenderType } from 'src/constant/GenderType';

export default function MyReleasesDetail() {
  const [searchParams] = useSearchParams();
  const [releases, setReleases] = useState<ReleaseModel>({} as ReleaseModel);
  const [type, setType] = useState(ReleaseType.MODEL);
  const [isDisabled, setIsDisabled] = useState(false);
  const [fileList, setFileList] = useState<any[]>([]);
  const [uploadFilePath, setUploadFilePath] = useState<string>(StringConstants.EMPTY);
  const [uploadFileName, setUploadFileName] = useState<string>(StringConstants.EMPTY);
  const [ethnicOptions, setEthnicOptions] = useState<OptionModel[]>([]);
  const { userProfile } = useSessionContext();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const releaseFormModel = require('src/assets/form/model_release_form.pdf');
  const releaseFormProperty = require('src/assets/form/property_release_form.pdf');

  AWS.config.update({
    accessKeyId: S3Constants.ACCESS_KEY_ID,
    secretAccessKey: S3Constants.SECRET_ACCESS_KEY,
  });

  const S3_BUCKET = S3Constants.BUCKET;
  const REGION = S3Constants.REGION;
  const s3Bucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const releaseTypeOptions = [
    {
      value: ReleaseType.MODEL,
      label: TranslationKey('release.releaseTypes.model')
    },
    {
      value: ReleaseType.PROPERTY,
      label: TranslationKey('release.releaseTypes.property')
    },
  ];

  const genderOptions = [
    {
      value: GenderType.NOTSPECIFIED,
      label: TranslationKey('release.genders.notSpecified')
    },
    {
      value: GenderType.MALE,
      label: TranslationKey('release.genders.male')
    },
    {
      value: GenderType.FEMALE,
      label: TranslationKey('release.genders.female')
    },
  ];

  const uploadProps: UploadProps = {
    multiple: false,
    listType: UploadListType.TEXT,
    accept: UploadFileConstants.MIME_RELEASES_ACCEPT.toString(),
    maxCount: 1,
    fileList: fileList,
    showUploadList: {
      showRemoveIcon: true,
      showPreviewIcon: true,
    },
    beforeUpload: (file: any) => {
      setFileList([file]);
    },
    async onRemove() {
      deleteFileFromS3(uploadFileName);
      setFileList([]);
    },
    async customRequest({ file, onError, onSuccess, onProgress }) {
      const originalFile = file as File;

      let errorMessage = await validateUploadFile(originalFile, onError);

      if (errorMessage !== StringConstants.EMPTY) {
        message.error(errorMessage, 10);

        return;
      }

      await addFileToS3(originalFile, onError, onSuccess, onProgress);
    },
  };

  useEffect(() => {
    const releaseId = searchParams.get('id');

    if (releaseId) {
      getReleasesByIdAsync(releaseId);
      setIsDisabled(true);
      onGetEthnicAsync();
    }
  }, [searchParams]);

  useEffect(() => {
    if (ethnicOptions.length > 0) {
      return;
    }

    onGetEthnicAsync();
  }, [ethnicOptions]);

  const getReleasesByIdAsync = async (id: string) => {
    const { data, status } = await new ReleasesService().getReleaseById(id);

    if (status !== HttpStatus.OK) {
      return;
    }

    setReleases(data);

    form.setFieldValue('name', data.name);

    if (data.model) {
      setType(ReleaseType.MODEL);
      form.setFieldValue('type', ReleaseType.MODEL);
      form.setFieldValue('modelName', data.model?.name);
      form.setFieldValue('gender', data.model?.gender);
      form.setFieldValue('ethnic', data.model?.ethnic);
      form.setFieldValue('dateofbirth', moment(data.model?.dateOfBirth));
    } else {
      setType(ReleaseType.PROPERTY);
      form.setFieldValue('type', ReleaseType.PROPERTY);
    }
  };

  const onChange = ({ target: { value } }: RadioChangeEvent) => {
    setType(value);
  };

  const onGetEthnicAsync = async () => {
    const {
      data: responseData,
      status: responseStatus
    } = await new MasterDataService().get(MasterDataType.ETHNIC);

    if (responseStatus !== HttpStatus.OK) {
      return;
    }

    const ethnics = MapMasterDataToOptionModel(responseData);
    setEthnicOptions(ethnics);
  };

  const MapMasterDataToOptionModel = (masterData: MasterDataModel[]) => {
    return masterData.map(function (md: MasterDataModel) {
      return {
        value: md.name,
        label: md.name,
      };
    });
  };

  const onFinish = async (values: any) => {
    let releaseModel: ReleaseModel = {
      type: values.type,
      name: values.name,
      documentUrl: uploadFilePath,
      model: {
        name: values.modelName,
        gender: values.gender,
        ethnic: values.ethnic,
        dateOfBirth: values.dateofbirth,
      },
    };

    if (values.type !== ReleaseType.MODEL) {
      delete releaseModel.model;
    }

    const {
      status: responseStatus
    } = await new ReleasesService().createAsync(releaseModel);

    if (responseStatus !== HttpStatus.CREATED) {
      messageApi.open({
        type: 'error',
        content: 'Save release failed.',
      });

      return;
    }

    messageApi.open({
      type: 'success',
      content: 'Save release success.',
    });

    navigate(RouteNames.releases);
  };

  const addFileToS3 = async (originalFile: any, onError: any, onSuccess: any, onProgress: any) => {
    const fileName = getFileName(originalFile.name);
    const filePath = getS3FileUrl(fileName);
    setUploadFilePath(filePath);
    setUploadFileName(fileName);

    const params = {
      ACL: S3Constants.ACL_TERM,
      Body: originalFile,
      Bucket: S3_BUCKET,
      Key: fileName,
      ContentType: originalFile.type,
    };

    s3Bucket
      .putObject(params)
      .on(S3Constants.EVENT_ON_UPLOAD, (evt) => {
        const progressPercent = Math.round((evt.loaded / evt.total) * 100);
        onProgress!({ percent: progressPercent });
      })
      .promise()
      .then(
        async () => {
          onSuccess!(fileName);
        },
        (err) => {
          onError!(err);
          throw err;
        }
      );
  };

  const validateUploadFile = async (file: File, onError: any) => {
    const unknowFileType = !file.type || file.type === StringConstants.EMPTY;
    const fileTypeNotSupport = !UploadFileConstants.MIME_RELEASES_ACCEPT.includes(file.type);

    if (unknowFileType || fileTypeNotSupport) {
      const errorMessage = file.name + StringConstants.SPACE + UploadValidationMessages.INVALID_FILE_TYPE;

      onError(errorMessage);

      return errorMessage;
    }

    return StringConstants.EMPTY.toString();
  };

  const getFileName = (originalFileName: string) => {
    const now = new Date();

    return (
      UploadFileConstants.FILE_NAME_PREFIX +
      userProfile.userId +
      StringConstants.UNDERSCORE +
      'release' +
      StringConstants.UNDERSCORE +
      now.getUTCFullYear() +
      (now.getUTCMonth() + 1) +
      now.getUTCDate() +
      now.getUTCHours() +
      now.getUTCMinutes() +
      now.getUTCSeconds() +
      now.getUTCMilliseconds() +
      StringConstants.UNDERSCORE +
      originalFileName
    );
  };

  const getS3FileUrl = (fileName: string) => {
    return `${S3Constants.FILE_NAME_FORMAT}${fileName}`;
  };

  const deleteFileFromS3 = async (fileName: string) => {
    const params = {
      Bucket: S3Constants.BUCKET,
      Key: fileName,
    };

    await s3Bucket.deleteObject(params, (err) => {
      if (err) {
        console.log(err, err.stack);
        message.error(`${fileName} file remove failed.`);
      }
    });
  };

  return (
    <>
      {contextHolder}
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
        initialValues={
          {
            type: ReleaseType.MODEL,
            gender: 0,
          }
        }>
        <Card
          title={TranslationKey('releaseDetail.title')}
          bordered={false}
          extra={
            !isDisabled && (
              <Button type='primary' htmlType='submit' size='large'>
                {TranslationKey('button.save')}
              </Button>
            )
          }
        >
          <Row gutter={16}>
            <Col span={24} md={12}>
              <Form.Item
                name='name'
                label={TranslationKey('releaseDetail.releaseName')}
                rules={[{
                  required: true,
                  message: TranslationKey('releaseDetail.requireReleaseName')
                }]}
              >
                <Input size='large' />
              </Form.Item>
            </Col>
            <Col span={12} md={6}>
              <Form.Item
                name='documenturl'
                label={TranslationKey('releaseDetail.attachment')}
                rules={[{
                  required: true,
                  message: TranslationKey('releaseDetail.requireAttachment')
                }]}
              >
                {isDisabled ? (
                  <Button
                    type='link'
                    size='large'
                    icon={<DownloadOutlined />}
                    onClick={() => window.open(releases.documentUrl, '_blank', 'noreferrer')}
                  >
                    {TranslationKey('button.download')}
                  </Button>
                ) : (
                  <Upload {...uploadProps}>
                    <Button size='large' icon={<UploadOutlined />}>
                      {TranslationKey('clickToUpload')}
                    </Button>
                  </Upload>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12} md={6}>
              <Form.Item
                name='type'
                label={TranslationKey('release.releaseTypes.title')}
              >
                <Radio.Group
                  options={releaseTypeOptions}
                  onChange={onChange}
                  defaultValue={type}
                  value={type}
                  optionType='button'
                  buttonStyle='solid'
                  size='large'
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name='type'
                label={TranslationKey('release.releaseForm.title')}
              >


                <div className='d-flex gap-4'>
                  <Button size='large' icon={<DownloadOutlined />}>
                    {
                      form.getFieldValue('type') == ReleaseType.MODEL
                        ?
                        <a className="download-releases"
                          href={releaseFormModel}
                          style={{ textDecoration: `none` }}
                          download="model_release_form.pdf">
                          {TranslationKey('release.releaseForm.modelForm')}
                        </a>
                        :
                        <a className="download-releases"
                          href={releaseFormProperty}
                          style={{ textDecoration: `none` }}
                          download="property_release_form.pdf">
                          {TranslationKey('release.releaseForm.propertyForm')}
                        </a>
                    }
                  </Button>
                </div>
              </Form.Item>
            </Col>
          </Row>
          {type === ReleaseType.MODEL && (
            <>
              <Row>
                <Col span={24} md={16}>
                  <Form.Item
                    name='modelName'
                    label={TranslationKey('releaseDetail.modelName')}
                    rules={[{
                      required: true,
                      message: TranslationKey('releaseDetail.requireModelName')
                    }]}
                  >
                    <Input size='large' />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24} md={12} xl={6}>
                  <Form.Item
                    name='gender'
                    label={TranslationKey('releaseDetail.gender')}
                    rules={[{
                      required: true,
                      message: TranslationKey('releaseDetail.requiredGenter')
                    }]}
                  >
                    <Radio.Group
                      options={genderOptions}
                      optionType='button'
                      buttonStyle='solid'
                      size='large' />
                  </Form.Item>
                </Col>
                <Col span={24} md={10}>
                  <Form.Item
                    name='ethnic'
                    label={TranslationKey('releaseDetail.ethic')}
                    rules={[{
                      required: true,
                      message: TranslationKey('releaseDetail.requireEthic')
                    }]}
                  >
                    <Select placeholder='Ethnic' size='large'>
                      {ethnicOptions.map((en) => (
                        <Select.Option
                          key={en.value}
                          value={en.value}>
                          {en.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24} md={12} xl={6}>
                  <Form.Item
                    name='dateofbirth'
                    label={TranslationKey('releaseDetail.dateOfBirth')}
                    rules={[{
                      required: true,
                      message: TranslationKey('releaseDetail.requireDateOfBirth')
                    }]}
                  >
                    <DatePicker format={'DD/MM/YYYY'} size='large' />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Card>
      </Form >
    </>
  );
}
