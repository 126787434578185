import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Drawer,
  Empty,
  Form,
  Input,
  message,
  Modal,
  notification,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Space,
  Upload,
  UploadProps,
} from 'antd';
import { useEffect, useState } from 'react';
import { ExclamationCircleFilled, UploadOutlined } from '@ant-design/icons';
import { StringConstants } from 'src/constant/stringConstants';
import { HttpStatus } from 'src/constant/responseStatus';
import { NofificationPlacement, NotificationType } from 'src/constant/notificationConstant';
import { MasterDataService } from 'src/services/masterDataService';
import { ReleasesService } from 'src/services/releasesService';
import { AssetService } from 'src/services/assetService';
import ReleaseModel from 'src/models/request/releaseModel';
import MasterDataModel from 'src/models/request//masterDataModel';
import EditingAssetModel from '../../../models/request/editingAssetRequestModel';
import OptionModel from 'src/models/OptionModel';
import { MasterDataType } from 'src/constant/masterDataConstants';
import { ReleaseType } from 'src/constant/ReleaseType';
import { AssetStatus, AssetUsage } from 'src/constant/assetConstants';
import { UploadFileConstants, UploadListType, UploadValidationMessages } from 'src/constant/uploadFileConstants';
import { S3Constants } from 'src/constant/s3Constants';
import { useSessionContext } from 'src/context/session-context';
import AWS from 'aws-sdk';
import TranslationKey from 'src/i18n/translation';
import { GenderType, GenderTypeLabel } from 'src/constant/GenderType';

const { confirm } = Modal;

interface Props {
  selectedItem: EditingAssetModel[];
  assetStatus: string;
  onReloadData: () => void;
}

const enum SubmitMode {
  SAVE = 0,
  SUBMIT = 1,
}

const enum AssetInfoFields {
  ASSET_NAME = 'assetName',
  DESCRIPTION = 'description',
  USAGE = 'usage',
  KEYWORDS = 'keywords',
  CAMERA_TYPE = 'cameraType',
  CAMERA_BRAND = 'cameraBrand',
  RELEASE_MODEL = 'releaseModels',
  RELEASE_PROPERTY = 'releaseProperties',
}

const enum ReleaseFields {
  NAME = 'name',
  DOCUMENT_URL = 'documentUrl',
  MODEL_NAME = 'modelName',
  MODEL_GENDER = 'modelGender',
  MODEL_ETHNIC = 'modelEthnic',
  MODEL_DATE_OF_BIRTH = 'modelDateOfBirth',
}

const usageOptions = [
  { label: AssetUsage.Commercial, value: AssetUsage.Commercial },
  { label: AssetUsage.Editorial, value: AssetUsage.Editorial },
  { label: 'AI Generated', value: AssetUsage.AiGenerated },
];

const releaseFormModel = require('src/assets/form/model_release_form.pdf');
const releaseFormProperty = require('src/assets/form/property_release_form.pdf');

export default function AssetInfo({ selectedItem, assetStatus, onReloadData }: Props) {
  const { userProfile } = useSessionContext();

  // #region : mapping option model
  const MapMasterDataToOptionModel = (masterData: MasterDataModel[]) => {
    return masterData.map(function (md: MasterDataModel) {
      return {
        value: md.name,
        label: md.name,
      };
    });
  };
  // #endregion

  // #region : alert notification
  const alertSuccess = (message: string = TranslationKey('dataSave.success')) => {
    notification.open({
      message: message,
      type: NotificationType.SUCCESS,
      placement: NofificationPlacement.TOP,
    });
  };

  const alertError = (message: string = TranslationKey('dataSave.fail')) => {
    notification.open({
      message: message,
      type: NotificationType.ERROR,
      placement: NofificationPlacement.TOP,
    });
  };
  // #endregion

  // #region : release model form
  const [fileList, setFileList] = useState<any[]>([]);
  const [uploadFilePath, setUploadFilePath] = useState<string>(StringConstants.EMPTY);
  const [uploadFileName, setUploadFileName] = useState<string>(StringConstants.EMPTY);

  AWS.config.update({
    accessKeyId: S3Constants.ACCESS_KEY_ID,
    secretAccessKey: S3Constants.SECRET_ACCESS_KEY,
  });

  const S3_BUCKET = S3Constants.BUCKET;
  const REGION = S3Constants.REGION;
  const s3Bucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const [releaseModelForm] = Form.useForm();
  const [releasePropertyForm] = Form.useForm();

  const onSubmitReleaseFormAsync = async (releaseType: ReleaseType) => {
    if (releaseType === ReleaseType.MODEL) {
      releaseModelForm.submit();

      releaseModelForm
        .validateFields()
        .then(async (formResult) => {
          const releaseModel: ReleaseModel = {
            type: ReleaseType.MODEL,
            name: formResult[ReleaseFields.NAME],
            documentUrl: uploadFilePath,
            model: {
              name: formResult[ReleaseFields.MODEL_NAME],
              gender: formResult[ReleaseFields.MODEL_GENDER],
              ethnic: formResult[ReleaseFields.MODEL_ETHNIC],
              dateOfBirth: formResult[ReleaseFields.MODEL_DATE_OF_BIRTH],
            },
          };

          const { status: responseStatus } = await new ReleasesService().createAsync(releaseModel);

          if (responseStatus !== HttpStatus.CREATED) {
            alertError();

            return;
          }

          onGetReleaseAsync();

          alertSuccess();
          releaseModelForm.resetFields();
          setFileList([]);
          onCloseModelDrawer(false);
        })
        .catch((e) => { });
    }

    if (releaseType === ReleaseType.PROPERTY) {
      releasePropertyForm.submit();

      releasePropertyForm
        .validateFields()
        .then(async (formResult) => {
          const releaseModel: ReleaseModel = {
            type: ReleaseType.PROPERTY,
            name: formResult[ReleaseFields.NAME],
            documentUrl: uploadFilePath,
          };

          const { status: responseStatus } = await new ReleasesService().createAsync(releaseModel);

          if (responseStatus !== HttpStatus.CREATED) {
            alertError();

            return;
          }

          onGetReleaseAsync();

          alertSuccess();
          releasePropertyForm.resetFields();
          setFileList([]);
          onClosePropertyDrawer(false);
        })
        .catch((e) => { });
    }
  };
  // #endregion

  // #region : upload config
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const getFileName = (originalFileName: string) => {
    const now = new Date();

    return (
      UploadFileConstants.FILE_NAME_PREFIX +
      userProfile.userId +
      StringConstants.UNDERSCORE +
      'release' +
      StringConstants.UNDERSCORE +
      now.getUTCFullYear() +
      (now.getUTCMonth() + 1) +
      now.getUTCDate() +
      now.getUTCHours() +
      now.getUTCMinutes() +
      now.getUTCSeconds() +
      now.getUTCMilliseconds() +
      StringConstants.UNDERSCORE +
      originalFileName
    );
  };

  const getS3FileUrl = (fileName: string) => {
    return `${S3Constants.FILE_NAME_FORMAT}${fileName}`;
  };

  const deleteFileFromS3 = async (fileName: string) => {
    const params = {
      Bucket: S3Constants.BUCKET,
      Key: fileName,
    };

    await s3Bucket.deleteObject(params, (err) => {
      if (err) {
        console.log(err, err.stack);
        message.error(`${fileName} file remove failed.`);
      }
    });
  };

  const addFileToS3 = async (originalFile: any, onError: any, onSuccess: any, onProgress: any) => {
    const fileName = getFileName(originalFile.name);
    const filePath = getS3FileUrl(fileName);
    setUploadFilePath(filePath);
    setUploadFileName(fileName);

    const params = {
      ACL: S3Constants.ACL_TERM,
      Body: originalFile,
      Bucket: S3_BUCKET,
      Key: fileName,
      ContentType: originalFile.type,
    };

    s3Bucket
      .putObject(params)
      .on(S3Constants.EVENT_ON_UPLOAD, (evt) => {
        const progressPercent = Math.round((evt.loaded / evt.total) * 100);
        onProgress!({ percent: progressPercent });
      })
      .promise()
      .then(
        async () => {
          onSuccess!(fileName);
        },
        (err) => {
          onError!(err);
          throw err;
        }
      );
  };

  const validateUploadFile = async (file: File, onError: any) => {
    const unknowFileType = !file.type || file.type === StringConstants.EMPTY;
    const fileTypeNotSupport = !UploadFileConstants.MIME_RELEASES_ACCEPT.includes(file.type);

    if (unknowFileType || fileTypeNotSupport) {
      const errorMessage = file.name + StringConstants.SPACE + UploadValidationMessages.INVALID_FILE_TYPE;

      onError(errorMessage);

      return errorMessage;
    }

    return StringConstants.EMPTY.toString();
  };

  const uploadProps: UploadProps = {
    multiple: false,
    listType: UploadListType.TEXT,
    accept: UploadFileConstants.MIME_RELEASES_ACCEPT.toString(),
    maxCount: 1,
    fileList: fileList,
    showUploadList: {
      showRemoveIcon: true,
      showPreviewIcon: true,
    },
    beforeUpload: (file: any) => {
      setFileList([file]);
    },
    async onRemove() {
      deleteFileFromS3(uploadFileName);
      setFileList([]);
    },
    async customRequest({ file, onError, onSuccess, onProgress }) {
      const originalFile = file as File;

      let errorMessage = await validateUploadFile(originalFile, onError);

      if (errorMessage !== StringConstants.EMPTY) {
        message.error(errorMessage, 10);

        return;
      }

      await addFileToS3(originalFile, onError, onSuccess, onProgress);
    },
  };
  // #endregion

  // #region : asset info form
  const [assetInfoForm] = Form.useForm();
  const [assetInfoFormSubmitMode, setAssetInfoFormSubmitMode] = useState<number>(0);

  const setSubmitAssetInfoFormSave = () => {
    setAssetInfoFormSubmitMode(SubmitMode.SAVE);
  };

  const setSubmitAssetInfoFormSubmit = () => {
    setAssetInfoFormSubmitMode(SubmitMode.SUBMIT);
  };

  const onSubmitAssetInfoForm = async (formValue: any) => {
    const releaseModels: string[] = formValue[AssetInfoFields.RELEASE_MODEL] ?? [];
    const releaseProperties: string[] = formValue[AssetInfoFields.RELEASE_PROPERTY] ?? [];
    const releaseValues = releaseModels.concat(releaseProperties);
    const releaseDataItem = releasesData?.filter((rd) => releaseValues.indexOf(rd.id + StringConstants.EMPTY) >= 0);

    const editingAssetModels: EditingAssetModel[] = selectedItem.map((si) => ({
      id: si.id,
      fileName: si.fileName,
      thumbnailImageInfo: {
        imageUrl: si.thumbnailImageInfo.imageUrl,
        vdoCoverUrl: si.thumbnailImageInfo.vdoCoverUrl,
        vdoUrl: si.thumbnailImageInfo.vdoUrl,
      },
      assetType: si.assetType,
      name: formValue[AssetInfoFields.ASSET_NAME],
      description: formValue[AssetInfoFields.DESCRIPTION],
      keywords: formValue[AssetInfoFields.KEYWORDS],
      cameraInfo: {
        type: formValue[AssetInfoFields.CAMERA_TYPE],
        brand: formValue[AssetInfoFields.CAMERA_BRAND],
      },
      releaseItems: releaseDataItem ?? [],
      isMatureContent: false,
      isSelected: true,
      usage: formValue[AssetInfoFields.USAGE],
    }));

    if (assetInfoFormSubmitMode === SubmitMode.SAVE) {
      const { status: responseStatus } = await new AssetService().updateDetailMultipleAsync(editingAssetModels);

      if (responseStatus !== HttpStatus.OK) {
        alertError();

        return;
      }
    }

    if (assetInfoFormSubmitMode === SubmitMode.SUBMIT) {
      const { status: responseStatus } = await new AssetService().submitMultipleAsync(editingAssetModels);

      if (responseStatus !== HttpStatus.OK) {
        alertError();

        return;
      }
    }

    alertSuccess();
    onReloadData();
  };
  // #endregion

  // #region : action delete
  const onConfirmDelete = () => {
    confirm({
      title: TranslationKey('myUpload.doYouWantToDeleteThisAsset'),
      icon: <ExclamationCircleFilled className='txt-primary' />,
      okText: TranslationKey('button.confirm'),
      cancelText: TranslationKey('button.cancel'),
      okType: 'danger',
      async onOk() {
        deleteMultipleAsset();
      },
      onCancel() { },
    });
  }

  const deleteMultipleAsset = async () => {
    const deleteItemIds = selectedItem.map((item) => item.id);

    if (deleteItemIds.length > 0) {
      const { status: responseStatus } =
        await new AssetService().deleteMultipleAsync(deleteItemIds);

      if (responseStatus !== HttpStatus.NO_CONTENT) {
        alertError();

        return;
      }

      alertSuccess();
      onReloadData();
    }
  };
  // #endregion

  // #region : asset info action
  const setAssetInfoForm = (item: EditingAssetModel) => {
    assetInfoForm.setFieldValue(AssetInfoFields.ASSET_NAME, item.name);
    assetInfoForm.setFieldValue(AssetInfoFields.DESCRIPTION, item.description);
    assetInfoForm.setFieldValue(AssetInfoFields.KEYWORDS, item.keywords);
    assetInfoForm.setFieldValue(AssetInfoFields.CAMERA_TYPE, item.cameraInfo ? item.cameraInfo.type : []);
    assetInfoForm.setFieldValue(AssetInfoFields.CAMERA_BRAND, item.cameraInfo ? item.cameraInfo.brand : []);
    assetInfoForm.setFieldValue(AssetInfoFields.USAGE, item.usage);

    if (item.releaseItems && releaseModelOptions && releaseModelOptions.length > 0) {
      const releasesModel = item.releaseItems
        .filter((r) => releaseModelOptions?.find((ro) => ro.value === r.id))
        .map((r) => r.id);

      assetInfoForm.setFieldValue(AssetInfoFields.RELEASE_MODEL, releasesModel);
    }

    if (item.releaseItems && releasePropertyOptions && releasePropertyOptions.length > 0) {
      const releasesProperties = item.releaseItems
        .filter((r) => releasePropertyOptions?.find((ro) => ro.value === r.id))
        .map((r) => r.id);

      assetInfoForm.setFieldValue(AssetInfoFields.RELEASE_PROPERTY, releasesProperties);
    }
  };

  const compareReleaseIsSame = (
    firstItem: EditingAssetModel,
    releaseOption: OptionModel[],
    items: EditingAssetModel[]
  ) => {
    const firstItemValue = JSON.stringify(
      firstItem.releaseItems
        .filter((ri) => releaseOption.find((ro) => ro.value === ri.id))
        .map((ri) => ri.id)
        .sort()
    );

    return items.some((si) => {
      const seleteItemValue = JSON.stringify(
        si.releaseItems
          .filter((ri) => releaseOption.find((ro) => ro.value === ri.id))
          .map((x) => x.id)
          .sort()
      );

      return !(firstItemValue === seleteItemValue);
    });
  };

  const handleSelectMultipleAsset = () => {
    const firstItem = selectedItem[0];
    const mismatchFields = [];

    const mismatchName = selectedItem.some((item) => item.name !== firstItem.name);

    if (mismatchName) {
      mismatchFields.push(AssetInfoFields.ASSET_NAME);
    }

    const mismatchDescription = selectedItem.some((item) => item.description !== firstItem.description);

    if (mismatchDescription) {
      mismatchFields.push(AssetInfoFields.DESCRIPTION);
    }

    const mismatchKeyword = selectedItem.some(
      (item) => JSON.stringify(item.keywords) !== JSON.stringify(firstItem.keywords)
    );

    if (mismatchKeyword) {
      mismatchFields.push(AssetInfoFields.KEYWORDS);
    }

    const mismatchCameraInfoType = selectedItem.some(
      (item) =>
        (item.cameraInfo === null && firstItem.cameraInfo !== null) ||
        (item.cameraInfo !== null && firstItem.cameraInfo === null) ||
        (item.cameraInfo !== null &&
          firstItem.cameraInfo !== null &&
          item.cameraInfo.type !== firstItem.cameraInfo.type)
    );

    if (mismatchCameraInfoType) {
      mismatchFields.push(AssetInfoFields.CAMERA_TYPE);
    }

    const mismatchCameraInfoBrand = selectedItem.some(
      (item) =>
        (item.cameraInfo === null && firstItem.cameraInfo !== null) ||
        (item.cameraInfo !== null && firstItem.cameraInfo === null) ||
        (item.cameraInfo !== null &&
          firstItem.cameraInfo !== null &&
          item.cameraInfo.brand !== firstItem.cameraInfo.brand)
    );

    if (mismatchCameraInfoBrand) {
      mismatchFields.push(AssetInfoFields.CAMERA_BRAND);
    }

    if (releasePropertyOptions) {
      const mismatchReleaseProperty = compareReleaseIsSame(firstItem, releasePropertyOptions, selectedItem);

      if (mismatchReleaseProperty) {
        mismatchFields.push(AssetInfoFields.RELEASE_PROPERTY);
      }
    }

    if (releaseModelOptions) {
      const mismatchReleaseModel = compareReleaseIsSame(firstItem, releaseModelOptions, selectedItem);

      if (mismatchReleaseModel) {
        mismatchFields.push(AssetInfoFields.RELEASE_MODEL);
      }
    }

    if (mismatchFields.length > 0) {
      assetInfoForm.resetFields(mismatchFields);
    }
  };

  useEffect(() => {
    if (selectedItem.length === 0) {
      assetInfoForm.resetFields();

      return;
    }

    if (selectedItem.length === 1) {
      setAssetInfoForm(selectedItem[0]);

      return;
    }

    handleSelectMultipleAsset();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);
  // #endregion

  // #region : drawer model
  const [openModelDrawer, setOpenModelDrawer] = useState(false);

  const showModelDrawer = () => {
    setOpenModelDrawer(true);
  };

  const onCloseModelDrawer = (isClearFile: boolean) => {
    setOpenModelDrawer(false);
    releaseModelForm.resetFields();

    if (fileList.length > 0 && isClearFile) {
      deleteFileFromS3(uploadFileName);
    }

    setFileList([]);
  };

  const [modelReleaseGender, setModelReleaseGender] = useState(GenderTypeLabel.MALE);

  const onChangeModelReleaseGender = ({ target: { value } }: RadioChangeEvent) => {
    setModelReleaseGender(value);
  };
  // #endregion

  // #region : drawer property
  const [openPropertyDrawer, setOpenPropertyDrawer] = useState(false);
  const showPropertyDrawer = () => {
    setOpenPropertyDrawer(true);
  };
  const onClosePropertyDrawer = (isClearFile: boolean) => {
    setOpenPropertyDrawer(false);
    releasePropertyForm.resetFields();

    if (fileList.length > 0 && isClearFile) {
      deleteFileFromS3(uploadFileName);
    }

    setFileList([]);
  };
  // #endregion

  // #region : option keyword
  const [keywordOptions, setKeywordOptions] = useState<{ value: string }[]>([]);

  const onGetAssetKeywordAsync = async () => {
    const { data: responseData, status: responseStatus } = await new MasterDataService().get(MasterDataType.KEYWORD);

    if (responseStatus !== HttpStatus.OK) {
      return;
    }

    const keywords = MapMasterDataToOptionModel(responseData);
    setKeywordOptions(keywords);
  };

  useEffect(() => {
    if (keywordOptions.length > 0) {
      return;
    }

    onGetAssetKeywordAsync();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keywordOptions]);
  // #endregion

  // #region : option camery type
  const [cameraTypeOptions, setCameraTypeOptions] = useState<OptionModel[]>([]);

  const onGetCameraTypeAsync = async () => {
    const { data: responseData, status: responseStatus } = await new MasterDataService().get(
      MasterDataType.CAMERA_TYPE
    );

    if (responseStatus !== HttpStatus.OK) {
      return;
    }

    const cameraTypes = MapMasterDataToOptionModel(responseData);
    setCameraTypeOptions(cameraTypes);
  };

  useEffect(() => {
    if (cameraTypeOptions.length > 0) {
      return;
    }

    onGetCameraTypeAsync();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cameraTypeOptions]);
  // #endregion

  // #region : option camera brand
  const [cameraBrandOptions, setCameraBrandOptions] = useState<OptionModel[]>([]);

  const onGetCameraBrandAsync = async () => {
    const { data: responseData, status: responseStatus } = await new MasterDataService().get(
      MasterDataType.CAMERA_BRAND
    );

    if (responseStatus !== HttpStatus.OK) {
      return;
    }

    const cameraBrands = MapMasterDataToOptionModel(responseData);
    setCameraBrandOptions(cameraBrands);
  };

  useEffect(() => {
    if (cameraBrandOptions.length > 0) {
      return;
    }

    onGetCameraBrandAsync();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cameraBrandOptions]);
  // #endregion

  // #region : option release
  const [releasesData, setReleasesData] = useState<ReleaseModel[]>();
  const [releaseModelOptions, setReleaseModelOptions] = useState<OptionModel[]>();
  const [releasePropertyOptions, setReleasePropertyOptions] = useState<OptionModel[]>();

  const onGetReleaseAsync = async () => {
    const { data: responseData, status: responseStatus } = await new ReleasesService().getByArtist();

    if (responseStatus !== HttpStatus.OK) {
      return;
    }

    setReleasesData(responseData);

    const releasesData = responseData.map((r) => ({
      value: r.id ?? StringConstants.EMPTY,
      label: r.name,
      type: r.type,
    }));
    const releaseModelData = releasesData.filter((r) => r.type === ReleaseType.MODEL);
    const releasePropertyData = releasesData.filter((r) => r.type === ReleaseType.PROPERTY);

    setReleaseModelOptions(releaseModelData);
    setReleasePropertyOptions(releasePropertyData);
  };

  useEffect(() => {
    if (releaseModelOptions || releasePropertyOptions) {
      return;
    }

    onGetReleaseAsync();
  }, [releaseModelOptions, releasePropertyOptions]);
  // #endregion

  // #region : option ethnic
  const [ethnicOptions, setEthnicOptions] = useState<OptionModel[]>([]);

  const onGetEthnicAsync = async () => {
    const { data: responseData, status: responseStatus } = await new MasterDataService().get(MasterDataType.ETHNIC);

    if (responseStatus !== HttpStatus.OK) {
      return;
    }

    const ethnics = MapMasterDataToOptionModel(responseData);
    setEthnicOptions(ethnics);
  };

  useEffect(() => {
    if (ethnicOptions.length > 0) {
      return;
    }

    onGetEthnicAsync();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ethnicOptions]);
  // #endregion

  return (
    <>
      <Col
        xs={{ span: 24 }}
        xl={{ span: 12 }}>
        <Card
          bordered={false}
          className='my-upload-card-body'>
          <Row>
            <Col
              className='mt-1'
              span={24}>
              <Form
                name='nest-messages'
                form={assetInfoForm}
                disabled={selectedItem.length === 0 || assetStatus !== AssetStatus.DRAFT}
                layout='vertical'
                onFinish={onSubmitAssetInfoForm}
              >
                {assetStatus === AssetStatus.DRAFT && (
                  <Form.Item>
                    <Row>
                      <Col
                        span={24}
                        style={{
                          display: 'flex',
                          justifyContent: 'end',
                          gap: '1rem'
                        }}>
                        <Button
                          danger
                          size='large'
                          type='primary'
                          htmlType='button'
                          onClick={onConfirmDelete}>
                          {TranslationKey('button.delete')}
                        </Button>
                        <Button
                          size='large'
                          type='primary'
                          htmlType='submit'
                          onClick={setSubmitAssetInfoFormSave}>
                          {TranslationKey('button.save')}
                        </Button>
                      </Col>
                    </Row>
                  </Form.Item>
                )}

                <Form.Item
                  name={AssetInfoFields.ASSET_NAME}
                  label={TranslationKey('artistName')}
                  rules={[{ required: false }]}>
                  <Input
                    placeholder={TranslationKey('artistName')}
                    autoComplete='off'
                  />
                </Form.Item>
                <Form.Item
                  name={AssetInfoFields.DESCRIPTION}
                  label={TranslationKey('myUpload.description')}
                  rules={[{
                    required: true,
                    message: TranslationKey('myUpload.requireDescription'),
                  }]}
                >
                  <Input.TextArea
                    placeholder='Description'
                    showCount
                    maxLength={200}
                  />
                </Form.Item>
                <Form.Item
                  name={AssetInfoFields.USAGE}
                  label={TranslationKey('usage')}
                  rules={[{
                    required: true,
                    message: TranslationKey('myUpload.requireUsage'),
                  }]}
                >
                  <Radio.Group
                    options={usageOptions}
                    optionType='button'
                    size='large'
                    buttonStyle='solid'
                  />
                </Form.Item>
                <Form.Item
                  name={AssetInfoFields.KEYWORDS}
                  label={TranslationKey('keywords')}
                  rules={[{
                    required: true,
                    message: TranslationKey('myUpload.requireKeyword'),
                  }]}
                >
                  <Select
                    mode='tags'
                    style={{ width: '100%' }}
                    placeholder='keyword'
                    options={keywordOptions}
                  />
                </Form.Item>

                <Form.Item
                  name={AssetInfoFields.CAMERA_TYPE}
                  label={TranslationKey('myUpload.cameraType')}
                  rules={[{
                    required: true,
                    message: TranslationKey('myUpload.requireCameraType'),
                  }]}
                >
                  <Select
                    placeholder='Camera Type'
                    options={cameraTypeOptions}
                    size='large'
                  />
                </Form.Item>

                <Form.Item
                  name={AssetInfoFields.CAMERA_BRAND}
                  label={TranslationKey('myUpload.cameraBrand')}
                  rules={[{
                    required: true,
                    message: TranslationKey('myUpload.requireCameraBrand'),
                  }]}
                >
                  <Select
                    placeholder='Camera Brand'
                    options={cameraBrandOptions}
                    size='large'
                  />
                </Form.Item>

                <Form.Item
                  name={AssetInfoFields.RELEASE_MODEL}
                  label={TranslationKey('myUpload.modelRelease')}>
                  <Checkbox.Group style={{ display: 'contents' }}>
                    <Row gutter={16}>
                      <Col span={24}>
                        <Button
                          size='large'
                          onClick={() => showModelDrawer()}>
                          {TranslationKey('myUpload.newRelease')}
                        </Button>
                      </Col>
                      <Col span={24}>
                        <div className='release-panel'>
                          <Row gutter={16}>
                            {releaseModelOptions && releaseModelOptions.length > 0 ? (
                              releaseModelOptions.map((rm) => (
                                <Col
                                  span={24}
                                  key={rm.value}>
                                  <Checkbox
                                    value={rm.value}
                                    style={{ lineHeight: '32px' }}>
                                    {rm.label}
                                  </Checkbox>
                                </Col>
                              ))
                            ) : (
                              <Col span={24}>
                                <Empty
                                  style={{ margin: '15px auto auto auto' }}
                                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                                />
                              </Col>
                            )}
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                </Form.Item>

                <Form.Item
                  name={AssetInfoFields.RELEASE_PROPERTY}
                  label={TranslationKey('myUpload.propertyRelease')}>
                  <Checkbox.Group style={{ display: 'contents' }}>
                    <Row gutter={16}>
                      <Col span={24}>
                        <Button
                          size='large'
                          onClick={() => showPropertyDrawer()}>
                          {TranslationKey('myUpload.newRelease')}
                        </Button>
                      </Col>
                      <Col span={24}>
                        <div className='release-panel'>
                          <Row gutter={16}>
                            {releasePropertyOptions && releasePropertyOptions.length > 0 ? (
                              releasePropertyOptions.map((rm, rmIndex) => (
                                <Col
                                  span={24}
                                  key={rm.value}>
                                  <Checkbox
                                    value={rm.value}
                                    style={{ lineHeight: '32px' }}>
                                    {rm.label}
                                  </Checkbox>
                                </Col>
                              ))
                            ) : (
                              <Col span={24}>
                                <Empty
                                  style={{ margin: '15px auto auto auto' }}
                                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                                />
                              </Col>
                            )}
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                </Form.Item>

                {assetStatus === AssetStatus.DRAFT && (
                  <Form.Item>
                    <Button
                      type='primary'
                      htmlType='submit'
                      block
                      size='large'
                      onClick={setSubmitAssetInfoFormSubmit}>
                      {TranslationKey('button.submit')}
                    </Button>
                  </Form.Item>
                )}
              </Form>
            </Col>
          </Row>
        </Card>
      </Col>

      <Drawer
        title={TranslationKey('release.createNewModelRelease')}
        className='my-upload'
        placement='bottom'
        onClose={() => onCloseModelDrawer(true)}
        open={openModelDrawer}
        bodyStyle={{
          overflowX: "hidden",
        }}
        drawerStyle={{
          overflowX: "hidden",
        }}
        extra={
          <Space>
            <Button
              size='large'
              onClick={() => onCloseModelDrawer(true)}>
              {TranslationKey('button.cancel')}
            </Button>
            <Button
              size='large'
              onClick={() => {
                onSubmitReleaseFormAsync(ReleaseType.MODEL);
              }}
              type='primary'
              htmlType='submit'>
              {TranslationKey('button.ok')}
            </Button>
          </Space>
        }>
        <Form
          name='nest-messages'
          form={releaseModelForm}
          layout='vertical'
        >
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item
                name={ReleaseFields.NAME}
                label={TranslationKey('release.releaseName')}
                rules={[{
                  required: true,
                  message: TranslationKey('releaseDetail.requireReleaseName')
                }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Space
                direction='vertical'
                style={{ display: 'block' }}>
                <a
                  className='download-releases'
                  href={releaseFormModel}
                  download='model_release_form.pdf'>
                  {TranslationKey('release.downloadReleaseForm')}
                </a>
                <Form.Item
                  label={TranslationKey('release.title')}
                  name={ReleaseFields.DOCUMENT_URL}
                  getValueFromEvent={normFile}
                  rules={[{
                    required: true,
                    message: TranslationKey('releaseDetail.requireAttachment')
                  }]}
                >
                  <Upload {...uploadProps}>
                    <Button
                      size='large'
                      icon={<UploadOutlined />}>
                      {TranslationKey('clickToUpload')}
                    </Button>
                  </Upload>
                </Form.Item>
              </Space>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item
                name={ReleaseFields.MODEL_NAME}
                label={TranslationKey('release.modelName')}
                rules={[{
                  required: true,
                  message: TranslationKey('releaseDetail.requireModelName')
                }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name={ReleaseFields.MODEL_GENDER}
                label={TranslationKey('release.genders.title')}
                rules={[{
                  required: true,
                  message: TranslationKey('releaseDetail.requireGender')
                }]}
              >
                <Radio.Group
                  options={
                    [
                      {
                        value: GenderType.NOTSPECIFIED,
                        label: TranslationKey('release.genders.notSpecified')
                      },
                      {
                        value: GenderType.MALE,
                        label: TranslationKey('release.genders.male')
                      },
                      {
                        value: GenderType.FEMALE,
                        label: TranslationKey('release.genders.female')
                      },
                    ]
                  }
                  onChange={onChangeModelReleaseGender}
                  value={modelReleaseGender}
                  optionType='button'
                  size='large'
                  buttonStyle='solid'
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name={ReleaseFields.MODEL_ETHNIC}
                label={TranslationKey('releaseDetail.ethic')}
                rules={[{
                  required: true,
                  message: TranslationKey('releaseDetail.requireEthic')
                }]}
              >
                <Select
                  placeholder='Ethnic'
                  size='large'>
                  {ethnicOptions.map((en) => (
                    <Select.Option
                      key={en.value}
                      value={en.value}>
                      {en.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name={ReleaseFields.MODEL_DATE_OF_BIRTH}
                label={TranslationKey('releaseDetail.dateOfBirth')}
                rules={[{
                  required: true,
                  message: TranslationKey('releaseDetail.requireDateOfBirth')
                }]}
              >
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>

      <Drawer
        title={TranslationKey('release.createNewPropertyRelease')}
        className='my-upload'
        placement='bottom'
        onClose={() => onClosePropertyDrawer(true)}
        open={openPropertyDrawer}
        bodyStyle={{
          overflowX: "hidden",
        }}
        drawerStyle={{
          overflowX: "hidden",
        }}
        extra={
          <Space>
            <Button
              size='large'
              onClick={() => onClosePropertyDrawer(true)}>
              {TranslationKey('button.cancel')}
            </Button>
            <Button
              size='large'
              onClick={() => {
                onSubmitReleaseFormAsync(ReleaseType.PROPERTY);
              }}
              type='primary'
              htmlType='submit'>
              {TranslationKey('button.ok')}
            </Button>
          </Space>
        }>
        <Form
          name='nest-messages'
          form={releasePropertyForm}
          layout='vertical'
        >
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item
                name={ReleaseFields.NAME}
                label={TranslationKey('release.releaseName')}
                rules={[{
                  required: true,
                  message: TranslationKey('releaseDetail.requireReleaseName')
                }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Space
                direction='vertical'
                style={{ display: 'block' }}>
                <a
                  className='download-releases'
                  href={releaseFormProperty}
                  download='property_release_form.pdf'>
                  {TranslationKey('release.downloadReleaseForm')}
                </a>
                <Form.Item
                  name={ReleaseFields.DOCUMENT_URL}
                  label={TranslationKey('release.title')}
                  getValueFromEvent={normFile}
                  rules={[{
                    required: true,
                    message: TranslationKey('releaseDetail.requireAttachment')
                  }]}
                >
                  <Upload {...uploadProps}>
                    <Button
                      size='large'
                      icon={<UploadOutlined />}>
                      {TranslationKey('clickToUpload')}
                    </Button>
                  </Upload>
                </Form.Item>
              </Space>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
}
