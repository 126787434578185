import { Col, Divider, Form, FormInstance, Input, Row } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import SelectProvince from '../../components/address/SelectProvince';
import SelectDistrict from '../../components/address/SelectDistrict';
import SelectSubDistrict from '../../components/address/SelectSubDistrict';
import IIndividualResponse from '@/src/models/responses/individualResponse';
import TranslationKey from 'src/i18n/translation';

interface IFormIndividual {
  useFormIndividual: FormInstance<any>;
  onSubmitIndividual: (data: any) => void;
  individual: IIndividualResponse | undefined;
}

const FormIndividual = ({ useFormIndividual, onSubmitIndividual, individual }: IFormIndividual) => {
  const [postcode, setPostCode] = useState('');
  const [districtId, setDistrictId] = useState(0);
  const [provinceId, setProvinceId] = useState(0);
  const [subDistrictId, setSubDistrictId] = useState(0);

  const [form] = Form.useForm(useFormIndividual);

  useEffect(() => {
    if (individual) {
      setProvinceId(individual.addressInfo?.provinceCode);
      setDistrictId(individual.addressInfo?.districtCode);
      setSubDistrictId(individual.addressInfo?.subDistrictCode);
      setPostCode(individual.addressInfo?.postcode.toString());
    }
  }, [individual]);

  const validateCustomField = useCallback((
    isRequired: boolean,
    key: string) => {
    if (isRequired) {
      useFormIndividual.setFields([
        {
          name: key,
          errors: [`${key} is required`],
        },
      ]);
    } else {
      useFormIndividual.setFields([
        {
          name: key,
          errors: undefined,
        },
      ]);
    }
  }, [useFormIndividual]);

  const onProvinceChange = (provinceId: number) => {
    setProvinceId(provinceId);
    useFormIndividual.setFieldValue('provinceCode', provinceId);
    validateCustomField(!provinceId, 'provinceCode');
  };

  const onDistrictChange = (districtId: number) => {
    setDistrictId(districtId);
    useFormIndividual.setFieldValue('districtCode', districtId);
    validateCustomField(!districtId, 'districtCode');
  };

  const onSubDistrictChange = (subDistrictId: number) => {
    setSubDistrictId(subDistrictId);
    useFormIndividual.setFieldValue('subDistrictCode', subDistrictId);
    validateCustomField(!subDistrictId, 'subDistrictCode');
  };

  return (
    <Form
      form={form}
      onFinish={onSubmitIndividual}
      wrapperCol={{ span: 23 }}
      name="form_item_path"
      layout="vertical"
      className="form-input">
      <Row gutter={[16, 16]} className="artist-upload">
        <Col xs={24} lg={8} className="flex-center-items">
          <Form.Item
            name="firstName"
            className="w-100 text-light"
            label={TranslationKey('individual.firstName')}
            rules={[
              {
                type: 'string',
              },
              {
                required: true,
                message: TranslationKey('individual.requireFirstName')
              }
            ]}>
            <Input
              className="w-100"
              placeholder="example" size='large' />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8} className="flex-center-items">
          <Form.Item
            name="lastName"
            className="w-100 text-light"
            label={TranslationKey('individual.lastName')}
            rules={[
              {
                type: 'string',
              },
              {
                required: true,
                message: TranslationKey('individual.requireLastName')
              }
            ]}>
            <Input
              className="w-100"
              placeholder="example" size='large' />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="artist-upload">
        <Col xs={24} lg={8} className="flex-center-items">
          <Form.Item
            name="email"
            className="w-100 text-light"
            label={TranslationKey('individual.email')}
            rules={[{
              type: 'email',
              message: TranslationKey('individual.invalidEmailFormat')
            },
            {
              required: true,
              message: TranslationKey('individual.requireEmail')
            }]}>
            <Input
              type="email"
              className="w-100"
              placeholder="example"
              readOnly size='large' />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8} className="flex-center-items">
          <Form.Item
            name="phone"
            className="w-100 text-light"
            label={TranslationKey('individual.phone')}
            rules={[
              {
                required: true,
                message: TranslationKey('individual.requirePhoneNumber')
              }]}>
            <Input
              type='phone'
              className="w-100"
              placeholder="example" size='large' />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row gutter={[16, 16]} className="artist-upload">
        <Col xs={24} lg={8} className="flex-center-items">
          <Form.Item
            name="address"
            className="w-100 text-light"
            label={TranslationKey('address')}
            rules={[
              {
                type: 'string',
              },
              {
                required: true,
                message: TranslationKey('address.requireAddress')
              }]}>
            <Input
              className="w-100"
              placeholder={TranslationKey('address')}
              size='large' />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="artist-upload">
        <Col xs={24} lg={8} className="flex-center-items">
          <Form.Item
            name="provinceCode"
            className="w-100 text-light"
            label={TranslationKey('address.province')}
            rules={[
              {
                type: 'number',
              },
              {
                required: true,
                message: TranslationKey('address.requireProvince')
              }]}>
            <SelectProvince
              className="non-radius"
              provinceID={provinceId}
              sendProvinceID={onProvinceChange}
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8} className="flex-center-items">
          <Form.Item
            name="districtCode"
            className="w-100 text-light"
            label={TranslationKey('address.district')}
            rules={[{
              required: true,
              message: TranslationKey('address.requireDistrict')
            }]}>
            <SelectDistrict
              className="non-radius"
              districtID={districtId}
              provinceID={provinceId}
              sendProvinceID={(id) => setProvinceId(id)}
              sendDistrictID={onDistrictChange}
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8} className="flex-center-items">
          <Form.Item
            name="subDistrictCode"
            className="w-100 text-light"
            label={TranslationKey('address.subDistrict')}
            rules={[{
              required: true,
              message: TranslationKey('address.requireSubDistrict')
            }]}>
            <SelectSubDistrict
              className="non-radius"
              postCode={postcode}
              districtID={districtId}
              provinceID={provinceId}
              subDistrictID={subDistrictId}
              sendPostCode={(value) => {
                useFormIndividual.setFieldValue('postcode', value);
              }}
              sendSubDistrictID={onSubDistrictChange}
              sendDistrictID={(id, subId) => {
                setDistrictId(id);
                setSubDistrictId(subId);
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="artist-upload">
        <Col xs={24} lg={8} className="flex-center-items">
          <Form.Item
            name="postcode"
            className="w-100 text-light"
            label={TranslationKey('address.postcode')}
            rules={[
              {
                required: true,
                message: TranslationKey('address.requirePostcode')
              }]}>
            <Input
              readOnly
              className="non-radius"
              size="large" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default FormIndividual;