import { Col, Row, Typography, Card, Button, Descriptions, Space, RadioChangeEvent, Radio, Tooltip, Collapse } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import GalleryItem from '../galleryItem/GalleryItem';
import { RouteNames } from 'src/routes/routeName';
import { AssetPublishModel } from 'src/models/assetModel';
import { ExtendedLicenseModel, StandardLicenseModel } from 'src/models/LicenseModel';
import { useEffect, useState } from 'react';
import { LicenseService } from 'src/services/licenseService';
import { HttpStatus } from 'src/constant/responseStatus';
import { AssetType } from 'src/constant/assetConstants';
import { ExtendedLicense, StandardLicense } from 'src/constant/licenseConstants';
import { UserAssetLicenseService } from 'src/services/userAssetLicenseService';
import { UserAssetLicenseResponse } from 'src/models/responses/userAssetLicenseResponse';
import { StringConstants } from 'src/constant/stringConstants';
import { Constants } from 'src/constant/constants';
import TranslationKey from 'src/i18n/translation';
import classStore from 'src/store/classStore';
import { LanguageConstants } from 'src/constant/language';

const { Title, Text } = Typography;

interface AssetInterface {
  index: number;
  asset: AssetPublishModel;
  onDelete: (id: string, stdId: string, extId: string) => void;
  onUpdateExtendedLicense?: (assetId: string, extLicense: ExtendedLicenseModel) => void;
  isUpdateExtendedLicense: boolean;
  onUpdateStandardLicense?: (assetId: string, extLicense: StandardLicenseModel) => void;
  isUpdateStandardLicense: boolean;
}

const Labelstyle = {
  color: '#FFF', fontSize: '1rem'
}

const Contentstyle = {
  color: '#a2abbf', fontSize: '1rem'
}

export default function CartItem({
  index,
  asset,
  onDelete,
  onUpdateExtendedLicense,
  isUpdateExtendedLicense,
  onUpdateStandardLicense,
  isUpdateStandardLicense
}: AssetInterface) {
  const onClickItem = (assetId: string) => {
    window.location.href = RouteNames.assetDetail.replace(':id', assetId);
  };

  const [, setTotalPrice] = useState(0);
  const [stdValue, setStdValue] = useState<StandardLicenseModel>();
  const [extValue, setExtValue] = useState<ExtendedLicenseModel>();
  const [standardLicense, setStandardLicense] = useState<StandardLicenseModel[]>();
  const [extendedLicense, setExtendedLicense] = useState<ExtendedLicenseModel[]>();
  const [, setStandardLicenseVip] = useState<StandardLicenseModel>({} as StandardLicenseModel);
  const [, setExtendedLicenseVip] = useState<ExtendedLicenseModel>({} as ExtendedLicenseModel);
  const [, setOrderAsset] = useState<AssetPublishModel>({} as AssetPublishModel);
  const [userAssetLicenses, setUserAssetLicenses] = useState<UserAssetLicenseResponse[]>([]);
  const [, setUserAssetLicenseId] = useState('');
  const [, setHasUserLicense] = useState(false);
  const [, setIsShowContact] = useState(false);
  const [activeKeyEtd, setActiveKeyEtd] = useState('');
  const [activeKeyStd, setActiveKeyStd] = useState('');

  const onChangeExtLicense = ({ target: { value } }: RadioChangeEvent) => {
    const extLicense = extendedLicense?.filter(e => e.id === value)[0];

    if (extLicense) {
      setExtValue(extLicense);
      checkLicense(stdValue?.id!, value);
      asset.extendedLicense = extLicense;
      onUpdateExtendedLicense!(asset.id, extLicense);
    }
  };

  const onChangeStdLicense = ({ target: { value } }: RadioChangeEvent) => {
    const stdLicense = standardLicense?.filter(e => e.id === value)[0];

    if (stdLicense) {
      setStdValue(stdLicense);
      checkLicense(value, extValue?.id!);
      asset.standardLicense = stdLicense;
      onUpdateStandardLicense!(asset.id, stdLicense);
    }
  };

  useEffect(() => {
    if (asset) {
      onGetLicenseAsync();
      setExtValue(asset.extendedLicense);
    }

  }, [asset]);

  useEffect(() => {
    if (stdValue && extValue) {
      calculateTotalPrice();

      if (userAssetLicenses) {
        checkLicense(stdValue.id!, extValue.id!);
      }
    }

  }, [stdValue, extValue, userAssetLicenses]);

  useEffect(() => {
    if (standardLicense) {
      onGetUserAssetLicenseAsync();
    }

  }, [standardLicense]);

  const onGetUserAssetLicenseAsync = async () => {
    const { data: responseData, status: responseStatus } = await new UserAssetLicenseService().getByAssetIdAsync('id'!);

    if (responseStatus !== HttpStatus.OK) {
      setDefaultSelectLicense();
      return;
    }

    setUserAssetLicenses(responseData);
    if (responseData) {
      setDefaultSelectLicense(responseData);
    }
  };

  const setDefaultSelectLicense = (datas?: UserAssetLicenseResponse[]) => {
    if (datas && datas.length > 0) {
      const largeLicenses = datas.filter(x => (x.standardLicense.code === StandardLicense.XL || x.standardLicense.code === StandardLicense.UHD));
      const smallLicenses = datas.filter(x => (x.standardLicense.code === StandardLicense.SM || x.standardLicense.code === StandardLicense.HD));
      if (largeLicenses && largeLicenses.length > 0) {
        const maxExt = getMaxExtendedLicense(largeLicenses);
        setStdValue(maxExt!.standardLicense);
        setExtValue(maxExt!.extendedLicenses[0]);
        setUserAssetLicenseId(maxExt!.standardLicenseId);
        return;
      }

      if (smallLicenses && smallLicenses.length > 0) {
        const maxExt = getMaxExtendedLicense(smallLicenses);
        setStdValue(maxExt!.standardLicense);
        setExtValue(maxExt!.extendedLicenses[0]);
        setUserAssetLicenseId(maxExt!.standardLicenseId);
        return;
      }
    }
    else {
      const stdLarge = standardLicense?.filter(std => (std.code === StandardLicense.XL || std.code === StandardLicense.UHD) && std.sequence === 2)[0];
      if (stdLarge) {
        setStdValue(stdLarge);
      }
      else {
        setStdValue(standardLicense?.filter(std => std.assetType === asset?.assetType)[0]);
      }
      setExtValue(extendedLicense?.filter((x) => x.assetType === asset?.assetType)[0]);
    }
  }

  const getMaxExtendedLicense = (userAssetLicenseResponses: UserAssetLicenseResponse[]) => {
    if (!userAssetLicenseResponses || userAssetLicenseResponses.length === 0) {
      return null;
    }

    let maxValue = userAssetLicenseResponses[0];

    for (const license of userAssetLicenseResponses) {
      if (license.extendedLicenses[0].sequence > maxValue.extendedLicenses[0].sequence) {
        maxValue = license;
      }
    }

    return maxValue;
  }

  const calculateTotalPrice = () => {
    if (stdValue && extValue) {
      setOrderAsset((pre) => ({ ...pre, standardLicense: stdValue }));
      setOrderAsset((pre) => ({ ...pre, extendedLicense: extValue }));
      setOrderAsset((pre) => ({
        ...pre,
        price:
          userAssetLicenses.filter((x) => x.standardLicenseId === stdValue.id).length > 0
            ? extValue.price
            : stdValue.price + extValue.price,
        isHasLicense: userAssetLicenses.filter((x) => x.standardLicenseId === stdValue.id).length > 0,
      }));

      if (userAssetLicenses.filter((x) => x.standardLicenseId === stdValue.id).length > 0) {
        setTotalPrice(extValue.price);
      } else {
        setTotalPrice(stdValue.price + extValue.price);
      }
      setIsShowContact(extValue.code === ExtendedLicense.EX3PLUS);
    }
  };

  const onGetLicenseAsync = async () => {
    const { data: responseStdData, status: responseStdStatus } = await new LicenseService().GetStandardLicenseByType(
      asset?.assetType!
    );

    if (responseStdStatus !== HttpStatus.OK) {
      return;
    }

    const { data: responseExtData, status: responseExtStatus } = await new LicenseService().GetExtendedLicenseByType(
      asset?.assetType!
    );

    if (responseExtStatus !== HttpStatus.OK) {
      return;
    }

    setStandardLicense(responseStdData);
    if (asset?.assetType === AssetType.IMAGE) {
      setStandardLicenseVip(responseStdData.filter((x) => x.code === StandardLicense.SM)[0]);
      if (!asset.hasSMLicense) {
        setStandardLicense(responseStdData.filter((x) => x.code !== StandardLicense.SM));
      }

      if (!asset.hasXLLicense) {
        setStandardLicense(responseStdData.filter((x) => x.code !== StandardLicense.XL));
      } else {
      }
    } else {
      setStandardLicenseVip(responseStdData.filter((x) => x.code === StandardLicense.HD)[0]);
      if (!asset?.hasHDLicense) {
        setStandardLicense(responseStdData.filter((x) => x.code !== StandardLicense.HD));
      }

      if (!asset?.hasUHDLicense) {
        setStandardLicense(responseStdData.filter((x) => x.code !== StandardLicense.UHD));
      } else {
      }
    }
    //TODO:filter not vip
    setExtendedLicense(responseExtData.filter((x) => x.code !== ExtendedLicense.VIP));
    setExtendedLicenseVip(responseExtData.filter((x) => x.code === ExtendedLicense.VIP)[0]);
    checkLicense(
      responseStdData.filter((x) => x.assetType === asset?.assetType)[0].id,
      responseExtData.filter((x) => x.assetType === asset?.assetType)[0].id
    );
  };

  const checkLicense = (stdId: string, extId: string) => {
    setHasUserLicense(false);

    if (userAssetLicenses) {
      const userStdLicenses = userAssetLicenses.filter((x) => x.standardLicenseId === stdId);
      const extLicense = extendedLicense?.filter(
        (x) =>
          x.id === extId &&
          x.assetType === asset?.assetType &&
          (x.code === ExtendedLicense.NONE || x.code === ExtendedLicense.VIP)
      )[0];
      const extLicenseMPFR = extendedLicense?.filter(
        (x) => x.id === extId && x.assetType === asset?.assetType && x.code === ExtendedLicense.MPFR
      )[0];
      if (userStdLicenses.length > 0) {
        setUserAssetLicenseId(userStdLicenses[0].id);

        var isNoneExtendedLicenses =
          extLicense ||
          extLicenseMPFR &&
          userStdLicenses
            .filter((userLicense) =>
              userLicense.extendedLicenses
                .filter((extendLicense) =>
                  extendLicense.id === extLicenseMPFR.id)
                .length > 0)
            .length > 0

        if (isNoneExtendedLicenses) {
          setHasUserLicense(true);
          return;
        }
      } else {
        setUserAssetLicenseId('');
      }
    }
  }

  return (
    <>
      <Card key={index} className='my-2 card-order'>
        <Row>
          <Col span={20} xs={{ order: 1, span: 24 }} md={{ order: 0, span: 20 }}>
            <Col xs={{ span: 24 }} xl={{ span: 4 }} className='text-start'>
              <GalleryItem
                asset={asset}
                index={index}
                onClickItem={onClickItem}
              />
            </Col>
            <Col xs={{ span: 24 }} xl={{ span: 20 }} className='product'>
              <Descriptions column={1} bordered size='small' className='descriptions-card'>
                <Descriptions.Item
                  label={TranslationKey('cart.assetCode')}
                  labelStyle={Labelstyle}
                  contentStyle={Contentstyle}
                >
                  {asset.code}
                </Descriptions.Item>
                <Descriptions.Item
                  label={TranslationKey('cart.assetName')}
                  labelStyle={Labelstyle}
                  contentStyle={Contentstyle}
                >
                  {asset.name}
                </Descriptions.Item>
                <Descriptions.Item
                  label={TranslationKey('cart.license')}
                  labelStyle={Labelstyle}
                  contentStyle={Contentstyle}
                >
                  <>

                    {isUpdateStandardLicense ?
                      <>
                        <Row justify={'space-between'}>
                          <p style={Contentstyle}> {asset.standardLicense.price.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                          }) +
                            ' ' +
                            asset.standardLicense.name}</p>
                          <Row>
                            <Space >
                              <Button
                                type='primary'
                                shape='circle'
                                icon={<EditOutlined />}
                                className='btn-outline'
                                size='large'
                                onClick={() => activeKeyStd
                                  ? setActiveKeyStd(Constants.stringEmpty)
                                  : setActiveKeyStd(asset.id)}
                              />
                              <Text
                                className={activeKeyStd ? 'text-primary' : ''}
                              >
                                {
                                  activeKeyStd
                                    ? TranslationKey('cart.close')
                                    : TranslationKey('cart.open')
                                }
                              </Text>
                            </Space>

                          </Row>
                        </Row>
                        <Collapse
                          activeKey={activeKeyStd}
                          collapsible="icon"
                          ghost={true}
                          destroyInactivePanel={true}
                          expandIconPosition={'end'}
                          items={[{
                            showArrow: false,
                            destroyInactivePanel: true,
                            key: asset.id,
                            children:
                              <Radio.Group
                                onChange={onChangeStdLicense}
                                value={asset.standardLicense?.id ?? stdValue?.id}
                                optionType='button'
                                className='mt-3'
                                size='large'
                              >
                                {standardLicense?.map((s, index) => (
                                  <Radio
                                    value={s.id}
                                    key={index}
                                  >
                                    <p className='fs-md'>{s.name}</p>
                                    <p className='fs-sm'>
                                      {StringConstants.BAHT +
                                        ' ' +
                                        s.price.toLocaleString(undefined, {
                                          maximumFractionDigits: 2,
                                        })}
                                    </p>
                                  </Radio>
                                ))}
                              </Radio.Group>
                            ,
                          },]} />
                      </>
                      : <>
                        {asset.standardLicense.price.toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                        }) +
                          ' ' +
                          asset.standardLicense.name}
                      </>}
                  </>
                </Descriptions.Item>
                <Descriptions.Item
                  label={TranslationKey('cart.extendedLicense')}
                  labelStyle={Labelstyle}
                  contentStyle={Contentstyle}
                >
                  <>

                    {isUpdateExtendedLicense ?
                      <>
                        <Row justify={'space-between'}>
                          <p style={Contentstyle}>
                            {asset.extendedLicense.price > 0
                              ? '+ ' +
                              asset.extendedLicense.price.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                              }) +
                              ' ' +
                              asset.extendedLicense.name
                              : asset.extendedLicense.name}
                          </p>
                          <Row>
                            <Space >
                              <Button
                                type='primary'
                                shape='circle'
                                icon={<EditOutlined />}
                                className='btn-outline'
                                size='large'
                                onClick={() => activeKeyEtd
                                  ? setActiveKeyEtd(Constants.stringEmpty)
                                  : setActiveKeyEtd(asset.id + 'etd')}
                              />
                              <Text
                                className={activeKeyEtd ? 'text-primary' : ''}
                              >
                                {
                                  activeKeyEtd
                                    ? TranslationKey('cart.close')
                                    : TranslationKey('cart.open')
                                }
                              </Text>
                            </Space>

                          </Row>
                        </Row>
                        <Collapse
                          activeKey={activeKeyEtd}
                          collapsible="icon"
                          ghost={true}
                          destroyInactivePanel={true}
                          expandIconPosition={'end'}
                          items={[{
                            showArrow: false,
                            destroyInactivePanel: true,
                            key: asset.id + 'etd',
                            children:
                              <Radio.Group
                                onChange={onChangeExtLicense}
                                value={asset.extendedLicense?.id ?? stdValue?.id}
                                className='custom-color-radio'
                                size='large'
                              >
                                <Space
                                  direction='vertical'
                                  size={'middle'}
                                >
                                  {extendedLicense
                                    ?.filter((x) => x.assetType === asset?.assetType)
                                    .map((e, index) => (
                                      <Radio
                                        value={e.id}
                                        className='text-gray'
                                        key={index}
                                      >
                                        +
                                        <span className='text-light mx-1 fw-bold'>
                                          {e.price > 0
                                            ? StringConstants.BAHT +
                                            ' ' +
                                            e.price.toLocaleString(undefined, {
                                              maximumFractionDigits: 2,
                                            })
                                            : ''}
                                        </span>
                                        {e.price > 0 && e.name !== 'Merchandise Product for Resale'
                                          ? <Tooltip
                                            title="This guarantees exclusivity for a specified length of time from the date of the Arts and Contents purchase and includes free unlimited copies, viewers, and worldwide, in perpetuity. An Exclusive Market Freeze license is not refundable and is only offered as an add-on to the X-Large Standard License.
                                                      ">
                                            {e.name}
                                          </Tooltip> : <>
                                            {e.name}
                                          </>}
                                      </Radio>
                                    ))}
                                </Space>
                              </Radio.Group>
                            ,
                          },]} />
                      </>
                      : <>
                        {asset.extendedLicense.price > 0
                          ? '+ ' +
                          asset.extendedLicense.price.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                          }) +
                          ' ' +
                          asset.extendedLicense.name
                          : asset.extendedLicense.name}
                      </>}
                  </>
                </Descriptions.Item>
              </Descriptions>

              <div className='bg-outline-gray py-1 mt-2 inline px-4' style={{ borderRadius: '8px' }}>
                <Title level={4} className='my-0 text-primary'>
                  {TranslationKey('cart.price') + ' '}
                  {
                    classStore.get(LanguageConstants.key) === LanguageConstants.th
                      ? asset.price.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      }) + ' ' + TranslationKey('cart.baht')
                      : TranslationKey('cart.baht') + asset.price.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })
                  }
                </Title>
              </div>
            </Col>
          </Col>
          <Col span={4} xs={{ order: 0, span: 24 }} md={{ order: 1, span: 4 }} className='text-end'>
            <Button
              type='primary'
              shape='circle'
              size='large'
              icon={<DeleteOutlined />}
              className='btn-outline'
              onClick={() =>
                onDelete(
                  asset.id,
                  asset.standardLicense.id,
                  asset.extendedLicense.id)}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
}