import { Select } from 'antd';
import { useEffect, useState } from 'react';
import Province from '../../constant/province.json';
import TranslationKey from 'src/i18n/translation';

const { Option } = Select;

interface ProvinceProps {
  provinceID: number,
  sendProvinceID: (id: number) => void,
  postCode?: string;
  className: string;
}

export default function SelectProvince(props: ProvinceProps) {
  const [provinceId, setProvinceId] = useState(0);
  const [provinceList] = useState(Province);

  useEffect(() => {
    if (props.provinceID) {
      handleSetProvinceByDistrict(props.provinceID);
    }
  }, [props.provinceID]);

  const handleSetProvinceByDistrict = (provinceID: number) => {
    setProvinceId(provinceID);
    props.sendProvinceID(provinceID);
  };

  const handleChangeProvince = (value: number) => {
    setProvinceId(value);
    props.sendProvinceID(value);
  };

  return (
    <Select
      style={{ width: '100%' }}
      size="large"
      className={props.className}
      value={provinceId}
      onSelect={(value: number) => handleChangeProvince(value)}
    >
      <Option value={0} disabled>
        {TranslationKey('select')}
      </Option>
      {
        provinceList.map((data, index) => (
          <Option value={data.id} key={`${data.id}-${index}`}>
            {data.name}
          </Option>
        ))
      }
    </Select>
  );
}
