import { PayoutInfoStatus, PayoutRequestStatus } from 'src/constant/statusConstants';
import {
  Badge,
  Button,
  Card,
  Col,
  DatePicker,
  DatePickerProps,
  Descriptions,
  Form,
  Row,
  Statistic,
  message,
} from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { PayoutRequestCreatingModel, PayoutRequestModel } from 'src/models/PayoutRequestModel';
import { DateFormat } from 'src/constant/DateFormatConstants';
import moment from 'moment';
import { useEffect, useState } from 'react';
import PayoutRequestModal from 'src/components/modal/PayoutRequestModal';
import { useNavigate } from 'react-router-dom';
import { RouteNames } from 'src/routes/routeName';
import { PayoutInfoModel } from 'src/models/PayoutInfoModel';
import { HttpStatus } from 'src/constant/responseStatus';
import { ArtistService } from 'src/services/artistService';
import { useSessionContext } from 'src/context/session-context';
import dayjs from 'dayjs';
import { FinancialConstant } from 'src/constant/financialConstants';
import TranslationKey from 'src/i18n/translation';

export default function Financial() {
  const [isOpenPayout, setIsOpenPayout] = useState(false);
  const [isEnablePayout, setIsEnablePayout] = useState(false);
  const [totalBalance, setTotalBalance] = useState(0);
  const [totalUnpaid, setTotalUnpaid] = useState(0);
  const [isCanPayout, setIsCanPayout] = useState(false);
  const [payoutInfo, setPayoutInfo] = useState<PayoutInfoModel>({} as PayoutInfoModel);
  const [payoutRequests, setPayoutRequests] = useState<PayoutRequestModel[]>([]);
  const { userProfile, getUserProfileAsync } = useSessionContext();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const columns: ColumnsType<PayoutRequestModel> = [
    {
      title: 'Request Date',
      key: 'createdInfo',
      dataIndex: 'createdInfo',
      align: 'center',
      width: 200,
      render: (value, record, index) => <>{moment(record.createdInfo.at).format(DateFormat.DateTime)}</>,
    },
    {
      title: 'Request No',
      key: 'requestNo',
      dataIndex: 'requestNo',
      align: 'center',
      width: 200,
    },
    {
      title: 'Request Amount',
      dataIndex: 'requestAmount',
      key: 'requestAmount',
      align: 'right',
      width: 200,
      render: (value, record, index) => (
        <>
          {record.requestAmount.toLocaleString(undefined, {
            minimumFractionDigits: 2,
          })}
        </>
      ),
    },
    {
      title: 'Approve/Reject Date',
      dataIndex: 'approvedInfo',
      key: 'approvedInfo',
      align: 'center',
      width: 250,
      render: (value, record, index) => (
        <>
          {record.status === PayoutRequestStatus.Cancelled ? (
            moment(record.cancelInfo.at).format(DateFormat.DateTime)
          ) : record.status === PayoutRequestStatus.Processing || record.status === PayoutRequestStatus.Paid ? (
            moment(record.approvedInfo.at).format(DateFormat.DateTime)
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      title: 'Approve Amount',
      dataIndex: 'approveAmount',
      key: 'approveAmount',
      align: 'right',
      width: 200,
      render: (value, record, index) => (
        <>
          {record.approveAmount ? (
            record.approveAmount.toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      title: 'Withholding Tax (3%)',
      dataIndex: 'withholdingTaxAmount',
      key: 'withholdingTaxAmount',
      align: 'right',
      width: 250,
      render: (value, record, index) => (
        <>
          {record.withholdingTaxAmount && record.withholdingTaxAmount > 0 ? (
            record.withholdingTaxAmount.toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      title: 'Transfers Fee',
      dataIndex: 'transfersFee',
      key: 'transfersFee',
      align: 'right',
      width: 100,
      render: (value, record, index) => <>30.00</>,
    },
    {
      title: 'Net Amount',
      dataIndex: 'netAmount',
      key: 'netAmount',
      align: 'right',
      width: 250,
      render: (value, record, index) => (
        <span className='fw-bold'>
          {record.netAmount && record.netAmount > 0 ? (
            (record.netAmount - 30).toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })
          ) : (
            <></>
          )}
        </span>
      ),
    },
    {
      title: 'WHT Attachment',
      dataIndex: 'tax',
      key: 'tax',
      align: 'center',
      width: 150,
      render: (value, record, index) => (
        <>
          {record.taxAttachmentUrl && (
            <a
              href={`${record.taxAttachmentUrl}`}
              target='_blank' rel="noreferrer">
              Download
            </a>
          )}
        </>
      ),
    },
    {
      title: 'status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 150,
      render: (value, record, index) => renderStatus(record.status),
    },
  ];

  useEffect(() => {
    if (userProfile && userProfile.userId) {
      onGetPayoutInfo(userProfile.userId);
      onGetPayoutRequest(new Date().getFullYear());
      onGetBalance(userProfile.userId);
      onCheckMonthlyPayout();
    } else {
      getUserProfileAsync();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile]);

  useEffect(() => {
    form.setFieldValue('year', dayjs());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onGetPayoutInfo = async (id: string) => {
    const { data, status } = await new ArtistService().getPayoutInfoAsync(id);

    if (status !== HttpStatus.OK) {
      return;
    }

    setPayoutInfo(data);
    setIsEnablePayout(data.status === PayoutInfoStatus.Verified);
  };

  const onGetBalance = async (id: string) => {
    const { data, status } = await new ArtistService().getBalanceAsync(id);

    if (status !== HttpStatus.OK) {
      return;
    }

    setTotalBalance(data.totalDebit);
    setTotalUnpaid(data.totalBalance);
  };

  const onGetPayoutRequest = async (year: number) => {
    const { data, status } = await new ArtistService().getPayoutRequestAsync(userProfile.userId, year);

    if (status !== HttpStatus.OK) {
      setPayoutRequests([]);
      return;
    }

    setPayoutRequests(data);
  };

  const onCheckMonthlyPayout = async () => {
    const { data, status } = await new ArtistService().getPayoutRequestAsync(
      userProfile.userId,
      new Date().getFullYear()
    );

    if (status !== HttpStatus.OK) {
      setIsCanPayout(true);
      return;
    }

    if (data && data.length) {
      setIsCanPayout(data.filter((x) => new Date(x.createdInfo.at).getMonth() === new Date().getMonth()).length === 0);
    } else {
      setIsCanPayout(true);
    }
  };

  const onEditPayoutInfo = () => {
    navigate(RouteNames.payoutInfo);
  };

  const onClickPayout = () => {
    if (totalUnpaid < FinancialConstant.payoutMin) {
      messageApi.open({
        type: 'warning',
        content: `Cannot payout. The minimum withdrawal amount is ${FinancialConstant.payoutMinText} baht.`,
      });

      return;
    }

    setIsOpenPayout(true);
  };

  const handleClosePayout = () => setIsOpenPayout(false);

  const onSubmitPayout = (reqAmt: number) => {
    if (reqAmt < FinancialConstant.payoutMin) {
      messageApi.open({
        type: 'error',
        content: `Cannot payout. The minimum withdrawal amount is ${FinancialConstant.payoutMinText} baht.`,
      });

      return;
    }

    if (reqAmt > FinancialConstant.payoutMax) {
      messageApi.open({
        type: 'error',
        content: `Cannot payout. The maximum withdrawal amount is ${FinancialConstant.payoutMaxText} baht.`,
      });

      return;
    }

    if (reqAmt > totalUnpaid) {
      messageApi.open({
        type: 'error',
        content: 'Cannot payout. The request amount is greater than Unpaid Earning.',
      });

      return;
    }

    onCreatePayoutRequest(reqAmt);
  };

  const onCreatePayoutRequest = async (amount: number) => {
    const requestModel: PayoutRequestCreatingModel = {
      amount: amount,
    };

    const { data, status } = await new ArtistService().createPayoutRequest(userProfile.userId, requestModel);

    if (status !== HttpStatus.OK) {
      messageApi.open({
        type: 'error',
        content: 'Create payout request failed.' + (data ?? ''),
      });

      return;
    }

    messageApi.open({
      type: 'success',
      content: 'Create payout request success.',
    });

    window.location.href = RouteNames.financial;
  };

  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    onGetPayoutRequest(dayjs(date).year());
  };

  function renderStatus(status: PayoutRequestStatus) {
    switch (status) {
      case PayoutRequestStatus.Pending: {
        return (
          <Badge
            color='#bfbfbf'
            text='Pending'
          />
        );
      }
      case PayoutRequestStatus.Processing: {
        return (
          <Badge
            status='processing'
            text='Processing'
          />
        );
      }
      case PayoutRequestStatus.Paid: {
        return (
          <Badge
            status='success'
            text='Paid'
          />
        );
      }
      case PayoutRequestStatus.Cancelled: {
        return (
          <Badge
            status='error'
            text='Cancelled'
          />
        );
      }
      default: {
        return (
          <Badge
            status='error'
            text='Cancel'
          />
        );
      }
    }
  }

  return (
    <>
      {contextHolder}
      <Row gutter={[16, 16]}>
        <Col
          xs={24}
          sm={12}
          md={12}>
          <Card bordered={false}>
            <Row gutter={16}>
              <Col span={12}>
                <Statistic
                  title={TranslationKey('financial.totalBalance')}
                  value={totalBalance}
                  precision={2}
                  valueStyle={{ color: '#237804', fontWeight: 'bold' }}
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title={TranslationKey('financial.unpaidEarning')}
                  value={totalUnpaid}
                  precision={2}
                  valueStyle={{ color: '#0958d9', fontWeight: 'bold' }}
                />
                {isCanPayout && isEnablePayout ? (
                  <Button
                    style={{ marginTop: 16 }}
                    type='primary'
                    shape='round'
                    onClick={onClickPayout}
                    size='large'>
                    {TranslationKey('payout')}

                  </Button>
                ) : (
                  <ul>
                    {!isCanPayout && (
                      <li className='text-danger'>{TranslationKey('financial.youAreNoLonger')}</li>
                    )}
                    {!isEnablePayout && (
                      <li className='text-danger'>{TranslationKey('financial.PleaseCheck')}</li>
                    )}
                  </ul>
                )}
              </Col>
            </Row>
          </Card>
        </Col>
        <Col
          xs={24}
          sm={12}
          md={12}>
          <div className='text-danger fw-bold'>{TranslationKey('financial.payoutInformation')}</div>
          <ul className='text-danger'>
            <li>{TranslationKey('financial.theMinimumWithdrawal1')}{FinancialConstant.payoutMinText} {TranslationKey('financial.theMinimumWithdrawal2')} {FinancialConstant.payoutMaxText} {TranslationKey('financial.theMinimumWithdrawal3')}</li>
            <li>{TranslationKey('financial.theSystemRequires')}</li>
            <li>{TranslationKey('financial.thereIsA30Bath')}</li>
            <li>{TranslationKey('financial.withholdingTax')}</li>
            <li>{TranslationKey('financial.paymentCutoff')}</li>
            <li>{TranslationKey('financial.processingTimeTake')}</li>
          </ul>
        </Col>
        <Col span={24}>
          <Card bordered={false}>
            <Row>
              <Col>
                <Descriptions
                  title={TranslationKey('financial.payoutInfo')}
                  labelStyle={{ color: '#8c8c8c' }}
                  extra={
                    payoutInfo.id ? (
                      <Button
                        size='large'
                        type='primary'
                        shape='round'
                        onClick={onEditPayoutInfo}>
                        {TranslationKey('button.edit')}
                      </Button>
                    ) : (
                      <Button
                        size='large'
                        type='primary'
                        shape='round'
                        onClick={onEditPayoutInfo}>
                        {TranslationKey('button.new')}
                      </Button>
                    )
                  }>
                  {payoutInfo.id && (
                    <>
                      <Descriptions.Item label='Account No'>{payoutInfo.bankAccountNumber}</Descriptions.Item>
                      <Descriptions.Item label='Bank Account Name'>{payoutInfo.bankAccountName}</Descriptions.Item>
                      <Descriptions.Item label='Payout Info Status'>
                        <Badge
                          count={payoutInfo.status}
                          color='#389e0d'
                        />
                      </Descriptions.Item>
                    </>
                  )}
                </Descriptions>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <div className='text-danger fw-bold'>Note</div>
                <ul className='text-danger'>
                  <li>{TranslationKey('financial.whenYouSubmit')}</li>
                  <li>{TranslationKey('financial.whenThePayoutInfo')}</li>
                </ul>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={24}>
          <Card
            title={TranslationKey('financial.payoutRequest')}
            bordered={false}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Card className='card-form'>
                  <Form
                    form={form}
                    layout='vertical'
                    autoComplete='off'>
                    <Form.Item
                      name='year'
                      label={TranslationKey('financial.year')}
                      rules={[{ required: false, message: 'Please input your name!' }]}>
                      <DatePicker
                        onChange={onChange}
                        picker='year'
                      />
                    </Form.Item>
                  </Form>
                  <ul className='text-danger fw-bold'>
                    <li>{TranslationKey('financial.ourTeamWillAttach')}</li>
                    <li>{TranslationKey('financial.transfersFeeMayChange')}</li>
                  </ul>
                </Card>
              </Col>
              <Col span={24}>
                <Table
                  locale={{ emptyText: TranslationKey("table.noData") }}
                  columns={columns}
                  dataSource={payoutRequests}
                  rowKey='id'
                  className='table-title-center table-responsive'
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={24}>
          <PayoutRequestModal
            isModalOpen={isOpenPayout}
            onClose={handleClosePayout}
            unpaidAmount={totalUnpaid}
            onSubmit={onSubmitPayout}
          />
        </Col>
      </Row>
    </>
  );
}