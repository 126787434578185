import { Row, Space, Spin, } from "antd";
import AssetInfo from "./asset-info";
import AssetTabItem from "./asset-tab-item";
import EditingAssetModel from "../../../models/request/editingAssetRequestModel";
import { useCallback, useEffect, useState } from "react";
import { AssetStatus } from "src/constant/assetConstants";
import { useLocation } from "react-router-dom";

export default function MyUpload() {
  const [selectedItem, setItemSelected] = useState<EditingAssetModel[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [assetStatus, setAssetStatus] = useState<string>();
  const search = useLocation().search;

  useEffect(() => {
    const paramStatus = new URLSearchParams(search).get("status");

    if (paramStatus) {
      setAssetStatus(paramStatus);
    } else {
      setAssetStatus(AssetStatus.DRAFT);
    }
  }, []);

  const handleSelectedItem = useCallback((newItem: EditingAssetModel) => {
    const itemIsExist = selectedItem.find(item => item.id === newItem.id);

    if (itemIsExist) {
      const remainSelectedItem = selectedItem.filter((item) => item.id !== newItem.id);
      setItemSelected(remainSelectedItem);

      return;
    }

    setItemSelected([...selectedItem, newItem]);
  }, [selectedItem]);

  const handleSelectedItemAll = useCallback((items: EditingAssetModel[]) => {
    setItemSelected(items);
  }, [selectedItem])

  const handleOnReloadData = useCallback(() => {
    setLoading(true);
  }, []);

  const handleOnLoadDataDone = useCallback(() => {
    setItemSelected([]);
    setTimeout(function () { setLoading(false) }, 1000);
  }, []);

  const handleOnTabChanged = useCallback((assetStaus: string) => {
    setLoading(true);
    setItemSelected([]);
    setAssetStatus(assetStaus);
  }, [assetStatus])

  return (
    <>
      <Spin spinning={loading}>
        <Space
          direction="vertical"
          style={{ display: 'flex' }}
          className="my-upload">
          <Row gutter={[16, 16]}>
            {
              assetStatus &&
              <>
                <AssetTabItem
                  onSelectedItem={handleSelectedItem}
                  onSelectedItemAll={handleSelectedItemAll}
                  tabValue={assetStatus}
                  onTabChanged={handleOnTabChanged}
                  onLoadDataDone={handleOnLoadDataDone}
                  reloadData={loading} />
                {
                  assetStatus !== AssetStatus.REJECTED &&
                  <AssetInfo
                    selectedItem={selectedItem}
                    assetStatus={assetStatus}
                    onReloadData={handleOnReloadData} />
                }
              </>
            }
          </Row>
        </Space>
      </Spin>
    </>
  );
}