import { Select } from 'antd';
import { useEffect, useState } from 'react';
import SubDistrict from '../../constant/SubDistrict.json';
import TranslationKey from 'src/i18n/translation';

const { Option } = Select;

interface SubDistrictProps {
  postCode: string;
  districtID: number;
  provinceID: number;
  subDistrictID: number;
  sendDistrictID: (id: number, subId: number) => void;
  sendPostCode: (value: string) => void;
  sendSubDistrictID: (id: number) => void;
  className: string;
}

export default function SelectSubDistrict({ postCode, districtID, provinceID, subDistrictID, sendDistrictID, sendPostCode, sendSubDistrictID, className }: SubDistrictProps) {
  const [subDistrict, setSubDistrict] = useState(0);
  const [zipCode, setZipCode] = useState(postCode);

  useEffect(() => { setZipCode('') }, [districtID])

  useEffect(() => {
    if (postCode) {
      SubDistrict.every((data) => {
        if (data.districtID === districtID) {
          if (subDistrictID > 0) {
            setSubDistrict(subDistrictID);
          }
          else {
            setSubDistrict(data.id);
          }
          setZipCode(postCode);

          return false;
        }

        return true;
      });
    }
  }, [postCode, districtID, SubDistrict, subDistrictID]);

  useEffect(() => {
    console.log('districtID', districtID)

    if (districtID) {
      var subDistrict = 0;
      var postcode = '';

      if (subDistrictID > 0) {
        if (SubDistrict.some(sd => sd.id === subDistrictID && sd.districtID === districtID)) {
          subDistrict = subDistrictID;
          postcode = SubDistrict.filter(sd => sd.id === subDistrictID && sd.districtID === districtID)[0].postCode;
        }
        else {
          subDistrict = SubDistrict.filter(sd => sd.districtID === districtID)[0].id;
          postcode = SubDistrict.filter(sd => sd.districtID === districtID)[0].postCode;
        }
      }
      else {
        subDistrict = SubDistrict.filter(sd => sd.districtID === districtID)[0].id;
        postcode = SubDistrict.filter(sd => sd.districtID === districtID)[0].postCode;
      }
      setSubDistrict(subDistrict);
      setZipCode(postcode);

      sendSubDistrictID(subDistrict);
      sendPostCode(postcode);
    }
  }, [districtID]);

  useEffect(() => {
    if (zipCode) {
      sendPostCode(zipCode);
    }
  }, [zipCode]);

  const handleChange = (value: number) => {
    setSubDistrict(value);
    if (SubDistrict.some(sd => sd.id === value)) {
      sendPostCode(SubDistrict.filter(sd => sd.id === value)[0].postCode);
    }
    else {
      sendPostCode('');
    }

    sendSubDistrictID(value);
  };

  return (
    <Select
      style={{ width: '100%' }}
      size="large"
      className={className}
      value={subDistrict}
      onSelect={(value: number) => handleChange(value)}
    >
      <Option value={0}>
        {TranslationKey('select')}
      </Option>
      {
        SubDistrict.map((data, index) => data.districtID === districtID && (
          <Option value={data.id} key={`${data.id}-${index}`}>
            {data.name}
          </Option>
        ))
      }
    </Select>
  );
}
