import { Col, Row, Typography, Form, Radio, Tabs, Button, Divider, Input, InputNumber, Card, message, Tag } from 'antd';
import { useEffect, useState } from 'react';
import type { TabsProps } from 'antd';
import { DeleteOutlined, UserOutlined } from '@ant-design/icons';
import { AssetPublishModel } from 'src/models/assetModel';
import { useSessionContext } from '../../context/session-context';
import CartItem from '../../components/card/CartItem';
import { useLocation, useNavigate } from 'react-router-dom';
import { AssetService } from '../../services/assetService';
import { HttpStatus } from '../../constant/responseStatus';
import { LicenseService } from '../../services/licenseService';
import {
  CreateCardModel,
  CreditcardPaymentInfo,
  OmiseCreateCardModel,
  OrderBillingInfoModel,
  OrderCreatingModel,
  OrderItemModel,
  OrderModel,
  OrderValidateModel,
} from '../../models/OrderModel';
import { BuyerType } from '../../constant/BuyerType';
import { PaymentType } from '../../constant/PaymentType';
import { OrderService } from '../../services/orderService';
import SelectProvince from 'src/components/address/SelectProvince';
import SelectDistrict from 'src/components/address/SelectDistrict';
import SelectSubDistrict from 'src/components/address/SelectSubDistrict';
import { PageDocument, StringConstants } from 'src/constant/stringConstants';
import { RouteNames } from 'src/routes/routeName';
import { AccountService } from 'src/services/accountService';
import IIndividualResponse from 'src/models/responses/individualResponse';
import ICompanyResponse from 'src/models/responses/companyResponse';
import omiseService from 'src/services/omiseService';
import { PaymentService } from 'src/services/paymentService';
import { CreditCardModel } from 'src/models/PaymentModel';
import { Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { RegexPattern } from 'src/constant/constants';
import { Loading } from 'src/components/loaging/Loading';
import { FaCommentDollar, FaCreditCard, FaQrcode } from 'react-icons/fa';
import IIndividualRequest from 'src/models/request/individualRequest';
import ICompanyRequest from 'src/models/request/companyRequest';
import { PromotionModel } from 'src/models/promotionModel';
import { MaskedInput, createDefaultMaskGenerator } from 'react-hook-mask';
import { omiseLimitAmount } from 'src/constant/omise';
import { ExtendedLicenseModel, StandardLicenseModel } from '@/src/models/LicenseModel';
import TranslationKey from 'src/i18n/translation';
import { LanguageConstants } from 'src/constant/language';
import classStore from 'src/store/classStore';

const { Title } = Typography;

const maskGenerator = createDefaultMaskGenerator('9999 9999 9999 9999');

export default function Order() {
  const { getOrderAssets, updateOrderAssets, userProfile, RemoveOrderAssets } = useSessionContext();
  const [assets, setAssets] = useState<AssetPublishModel[]>([]);
  const [order, setOrder] = useState<OrderModel>({} as OrderModel);
  const [orderValidate, setOrderValidate] = useState<OrderValidateModel>({
    province: { isError: false },
    district: { isError: false },
    subDistrict: { isError: false },
  } as OrderValidateModel);
  const [total, setTotal] = useState(0);
  const [totalVat, setTotalVat] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [buyerType, setBuyerType] = useState<BuyerType>(BuyerType.Individual);
  const [paymentType, setPaymentType] = useState<PaymentType>(PaymentType.CreditCard);
  const [postcode, setPostcode] = useState<string>(StringConstants.EMPTY);
  const [subDistrictId, setSubDistrictId] = useState(0);
  const [districtId, setDistrictId] = useState(0);
  const [provinceId, setProvinceId] = useState(0);
  const [hasIndividualInfo, setHasIndividualInfo] = useState(false);
  const [hasCompanyInfo, setHasCompanyInfo] = useState(false);
  const [creditCard, setCreditCard] = useState<CreditCardModel>({} as CreditCardModel);
  const [isRememberCard, setIsRememberCard] = useState(false);
  const [isOnCart, setIsOnCart] = useState(false);
  const [isHideLoading, setIsHideLoading] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();
  const [promotionCode, setPromotionCode] = useState('');
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [afterDiscount, setAfterDiscount] = useState(0);
  const [isOverLimit, setIsOverLimit] = useState(false);
  const [minCardExpireYear] = useState(new Date().getFullYear() - 2000);
  const [maxCardExpireYear] = useState((new Date().getFullYear() - 2000) + 5);

  const { state } = useLocation();
  const [orderForm] = Form.useForm<OrderModel>();
  const navigate = useNavigate();

  const [value] = useState('');

  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    document.title = `Order - ${PageDocument.Title}`;
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  useEffect(() => {
    if (!state) {
      onGetOrders();
      setIsOnCart(true);
    } else {
      onGetAsset();
      setIsOnCart(false);
    }

    onSetOrder();
    onGetAccountInfo();
    onGetCreditcard();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userProfile && userProfile.email) {
      orderForm.setFieldValue('Email', userProfile.email);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile]);

  useEffect(() => {
    if (assets.length > 0) {
      onCalculateOrder();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assets, totalDiscount]);

  useEffect(() => {
    onGetAccountInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyerType]);

  const onCalculateOrder = () => {
    const total = assets.reduce((sum, current) => (sum = sum + current.price), 0);
    const afterDiscount = total - totalDiscount;
    const vat = (afterDiscount * 7) / 100;
    const grandTotal = afterDiscount + vat;

    setTotal(total);
    setAfterDiscount(afterDiscount > 0 ? afterDiscount : 0);
    setTotalVat(vat > 0 ? vat : 0);
    setGrandTotal(grandTotal > 0 ? grandTotal : 0);
    setIsOverLimit(grandTotal > omiseLimitAmount);
  };

  const onSetOrder = () => {
    let order: OrderModel;

    order = {
      userId: StringConstants.EMPTY,
      BuyerType: buyerType,
      PaymentType: paymentType,
      DiscountCode: StringConstants.EMPTY,
      Address: StringConstants.EMPTY,
      Postcode: StringConstants.EMPTY,
      SubDistrictId: 0,
      DistrictId: 0,
      ProvinceId: 0,
      CityText: StringConstants.EMPTY,
      Firstname: StringConstants.EMPTY,
      Lastname: StringConstants.EMPTY,
      Email: StringConstants.EMPTY,
      Phone: StringConstants.EMPTY,
      CompanyName: StringConstants.EMPTY,
      TaxID: StringConstants.EMPTY,
      Branch: StringConstants.EMPTY,
      PaymentMethod: PaymentType.CreditCard,
      NameOnCard: StringConstants.EMPTY,
      CardExpireMonth: undefined,
      CardExpireYear: undefined,
      Cardcvv: StringConstants.EMPTY,
      CardNumber: StringConstants.EMPTY,
      OrderItems: assets.map((asset) => {
        return {
          AssetId: asset.id,
          StandardLicenseId: asset.standardLicense.id,
          ExtendedLicenseId: asset.extendedLicense.id,
          DiscountCode: StringConstants.EMPTY,
        } as OrderItemModel;
      }),
      TotalPrice: assets.reduce((a, v) => (a = a + v.price), 0),
      IsRemember: false,
    };
    setOrder(order);
  };

  const onGetAccountInfo = async () => {
    const response = await new AccountService().getAccountInfo<any>();

    if (response.status !== HttpStatus.OK) {
      return;
    }

    if (response.data.length > 0 && response.data[0].individualInfo) {
      if (buyerType === BuyerType.Individual) {
        onSetFieldIndividualInfo(response.data[0].individualInfo);
      }
      setHasIndividualInfo(true);
    } else {
      setHasIndividualInfo(false);
    }

    if (response.data.length > 0 && response.data[0].companyInfo) {
      if (buyerType === BuyerType.Company) {
        onSetFieldCompanyInfo(response.data[0].companyInfo);
      }
      setHasCompanyInfo(true);
    } else {
      setHasCompanyInfo(false);
    }
  };

  const onSetFieldIndividualInfo = (data: IIndividualResponse) => {
    orderForm.setFieldValue('Firstname', data.firstname);
    orderForm.setFieldValue('Lastname', data.lastName);
    orderForm.setFieldValue('Email', data.email);
    orderForm.setFieldValue('Phone', data.phone);
    orderForm.setFieldValue('Address', data.addressInfo.address);
    orderForm.setFieldValue('ProvinceId', data.addressInfo.provinceCode);
    orderForm.setFieldValue('DistrictId', data.addressInfo.districtCode);
    orderForm.setFieldValue('SubDistrictId', data.addressInfo.subDistrictCode);
    orderForm.setFieldValue('Postcode', data.addressInfo.postcode);

    setSubDistrictId(data.addressInfo.subDistrictCode);
    setDistrictId(data.addressInfo.districtCode);
    setProvinceId(data.addressInfo.provinceCode);
    setPostcode(data.addressInfo.postcode.toString());
  };

  const onSetFieldCompanyInfo = (data: ICompanyResponse) => {
    orderForm.setFieldValue('CompanyName', data.companyName);
    orderForm.setFieldValue('TaxID', data.taxId);
    orderForm.setFieldValue('Branch', data.branch);
    orderForm.setFieldValue('Address', data.addressInfo.address);
    orderForm.setFieldValue('ProvinceId', data.addressInfo.provinceCode);
    orderForm.setFieldValue('DistrictId', data.addressInfo.districtCode);
    orderForm.setFieldValue('SubDistrictId', data.addressInfo.subDistrictCode);
    orderForm.setFieldValue('Postcode', data.addressInfo.postcode);

    setSubDistrictId(data.addressInfo.subDistrictCode);
    setDistrictId(data.addressInfo.districtCode);
    setProvinceId(data.addressInfo.provinceCode);
    setPostcode(data.addressInfo.postcode.toString());
  };

  const onGetOrders = () => {
    let orders = getOrderAssets();

    if (!orders) {
      return;
    }
    setAssets(orders);
  };

  const onGetAsset = async () => {
    const { data: responseData, status: responseStatus } = await new AssetService().getAssetPublishAsync(
      state.assetId!
    );

    if (responseStatus !== HttpStatus.OK) {
      return;
    }

    let asset = responseData;

    if (asset) {
      const { data: responseStdData, status: responseStdStatus } = await new LicenseService().GetStandardLicenseByType(
        asset.assetType!
      );

      if (responseStdStatus !== HttpStatus.OK) {
        return;
      }

      const { data: responseExtData, status: responseExtStatus } = await new LicenseService().GetExtendedLicenseByType(
        asset.assetType!
      );

      if (responseExtStatus !== HttpStatus.OK) {
        return;
      }

      asset.standardLicense = responseStdData.filter((x) => x.id === state.standardLicenseId)[0];
      asset.extendedLicense = responseExtData.filter((x) => x.id === state.extendedLicenseId)[0];
      asset.price = asset.standardLicense.price + asset.extendedLicense.price;
    }

    let orders: AssetPublishModel[] = [];
    orders.push(...[asset]);
    setAssets(orders);

  };

  const onDelete = (id: string, stdId: string, extId: string) => {
    let orders = getOrderAssets();

    if (!orders) {
      orders = [];
    }

    const oldIndex = orders.findIndex(
      (x) => x.id === id && x.standardLicense.id === stdId && x.extendedLicense.id === extId
    );

    if (oldIndex > -1) {
      orders.splice(oldIndex, 1);
    }

    updateOrderAssets(orders);
    onGetOrders();
  };

  const onUpdateExtendedLicense = (assetId: string, extLicense: ExtendedLicenseModel) => {
    let orders = getOrderAssets();

    if (orders.length < 1) {
      let assetsModel = assets;

      assetsModel.forEach(a => {
        if (a.id === assetId) {
          a.extendedLicense = extLicense;
        }

        a.price = a.standardLicense.price + extLicense.price;
      })

      setAssets(assetsModel);

      onCalculateOrder();

      return;

    } else {
      orders.forEach(o => {
        if (o.id === assetId) {
          o.extendedLicense = extLicense;
          o.price = o.standardLicense.price + extLicense.price;
        }
      });

      updateOrderAssets(orders);

      onGetOrders();
    }
  };

  const onUpdateStandardLicense = (assetId: string, stdLicense: StandardLicenseModel) => {
    let orders = getOrderAssets();

    if (orders.length < 1) {

      let assetsModel = assets;

      assetsModel.forEach(a => {
        if (a.id === assetId) {
          a.standardLicense = stdLicense;
        }

        a.price = a.extendedLicense.price + stdLicense.price;
      })

      setAssets(assetsModel)

      onCalculateOrder();

      return;
    } else {
      orders.forEach(o => {
        if (o.id === assetId) {
          o.standardLicense = stdLicense;
          o.price = o.extendedLicense.price + stdLicense.price;
        }
      });

      updateOrderAssets(orders);

      onGetOrders();
    }
  };

  const onCustomerInfoChange = (key: string) => {
    if (key === BuyerType.Company.toString()) {
      setBuyerType(BuyerType.Company);
    } else {
      setBuyerType(BuyerType.Individual);
    }
  };

  const onPaymentMethodChange = (key: string) => {
    if (key === PaymentType.CreditCard.toString()) {
      setPaymentType(PaymentType.CreditCard);
    } else if (key === PaymentType.QRCode.toString()) {
      setPaymentType(PaymentType.QRCode);
    } else if (key === PaymentType.Manual.toString()) {
      setPaymentType(PaymentType.Manual);
    } else {
      setPaymentType(PaymentType.PaymentTerm);
    }
  };

  const onPayNow = (orderForm: OrderModel) => {
    orderForm.CardNumber =
      orderForm.CardNumber != null ? orderForm.CardNumber.replace(/\s+/g, '') : order.CardNumber.replace(/\s+/g, '');

    setIsHideLoading(false);

    let isError = false;
    let orderValid = {
      province: { isError: false },
      district: { isError: false },
      subDistrict: { isError: false },
    } as OrderValidateModel;

    if (districtId === 0) {
      orderValid.district.isError = true;
      orderValid.district.message = 'Please input your district.';
      isError = true;
    }
    else {
      orderValid.district.isError = false;
    }

    if (subDistrictId === 0) {
      orderValid.subDistrict.isError = true;
      orderValid.subDistrict.message = 'Please input your subDistrict.';
      isError = true;
    }
    else {
      orderValid.subDistrict.isError = false;
    }

    setOrderValidate(orderValid);

    if (isError) {
      setIsHideLoading(true);
      return;
    }

    onSaveAccountInfo(orderForm);

    if (paymentType === PaymentType.CreditCard) {
      if (isRememberCard && creditCard.id) {
        onCreateOrderCreditcard(orderForm, creditCard.id);
      } else {
        var omiseModel: OmiseCreateCardModel = {
          name: orderForm.NameOnCard,
          number: orderForm.CardNumber.replaceAll(StringConstants.HYPHEN, StringConstants.EMPTY).replaceAll(
            StringConstants.UNDERSCORE,
            StringConstants.EMPTY
          ),
          expirationMonth: orderForm.CardExpireMonth!,
          expirationYear: orderForm.CardExpireYear!,
          securityCode: orderForm.Cardcvv,
          city: null,
          country: null,
          postalCode: null,
          state: null,
          street1: null,
        };

        onCreateCard(omiseModel, orderForm);
      }
    } else if (paymentType === PaymentType.QRCode) {
      onCreateOrderQRCode(orderForm);
    } else if (paymentType === PaymentType.Manual) {
      onCreateManualOrder(orderForm);
    } else if (paymentType === PaymentType.PaymentTerm) {
      onCreateOrderPaymentTerm(orderForm);
    }
  };

  const onSaveAccountInfo = async (orderForm: OrderModel) => {
    if (buyerType === BuyerType.Individual && !hasIndividualInfo) {
      const individualData: IIndividualRequest = {
        firstName: orderForm.Firstname,
        lastName: orderForm.Lastname,
        email: orderForm.Email,
        phone: orderForm.Phone,
        address: orderForm.Address,
        postcode: orderForm.Postcode,
        provinceCode: provinceId,
        districtCode: districtId,
        subDistrictCode: subDistrictId,
      };

      const response = await new AccountService().updateIndividualAsync(individualData);

      if (response.status !== HttpStatus.OK) {
        setIsHideLoading(true);

        return;
      }
    } else if (buyerType === BuyerType.Company && !hasCompanyInfo) {
      const companyData: ICompanyRequest = {
        companyWebsite: '-',
        branch: orderForm.Branch,
        address: orderForm.Address,
        companyName: orderForm.CompanyName,
        contactEmail: userProfile.email,
        contactFirstName: orderForm.Firstname,
        contactLastName: orderForm.Lastname,
        contactPhone: '-',
        department: '-',
        districtCode: districtId,
        jobTitle: '-',
        postcode: Number(orderForm.Postcode),
        provinceCode: provinceId,
        purchasingFor: '-',
        subDistrictCode: subDistrictId,
        taxId: orderForm.TaxID,
      };

      const response = await new AccountService().updateCompanyAsync(companyData);

      if (response.status !== HttpStatus.OK) {
        setIsHideLoading(true);

        return;
      }
    }
  };

  const onCreateCard = async (card: OmiseCreateCardModel, orderForm: OrderModel) => {
    const response = await omiseService.getOmiseTokenAsync(card);

    if (response.status !== HttpStatus.OK) {
      setIsHideLoading(true);

      return;
    }

    const data = {
      creditCardToken: response.data.id,
    } as CreateCardModel;

    const responseCard = await new PaymentService().createCreditCard(data);

    if (responseCard.status !== HttpStatus.OK) {
      setIsHideLoading(true);

      return;
    }

    onCreateOrderCreditcard(orderForm, responseCard.data.creditCardId);
  };

  const onGetCreditcard = async () => {
    const response = await new PaymentService().getCreditCards();

    if (response.status !== HttpStatus.OK) {
      setIsRememberCard(false);

      return;
    }

    if (response.data.creditCards.length > 0) {
      const cData: CreditCardModel = response.data.creditCards[0];
      order.CardNumber = 'XXXX XXXX XXXX ' + cData.lastDigits;
      order.CardExpireMonth = cData.expirationMonth;
      order.CardExpireYear = cData.expirationYear;
      order.NameOnCard = cData.cardHolder;

      setIsRememberCard(true);
      setCreditCard(cData);
      setOrder(order);
    } else {
      setIsRememberCard(false);
    }
  };

  const onDeleteCreditCard = async (creditCardId: string) => {
    setIsHideLoading(false);
    const response = await new PaymentService().deleteCreditCards(creditCardId);

    if (response.status !== HttpStatus.OK) {
      return;
    }

    setIsRememberCard(false);
    onGetCreditcard();
    setIsHideLoading(true);
  };

  const onCreateOrderCreditcard = async (orderForm: OrderModel, creditCardId: string) => {
    const orderCreatingModel = {
      userId: userProfile.userId,
      PaymentInfo: {
        creditCardId: creditCardId,
      } as CreditcardPaymentInfo,
      BuyerType: buyerType,
      PaymentMethod: paymentType,
      DiscountCode: promotionCode,
      OrderBillingInfo: {
        BuyerType: buyerType,
        Address: orderForm.Address,
        Postcode: orderForm.Postcode.toString(),
        SubDistrictId: subDistrictId,
        DistrictId: districtId,
        ProvinceId: provinceId,
        FullAddress: orderForm.Address,
        Firstname: orderForm.Firstname,
        Lastname: orderForm.Lastname,
        Email: orderForm.Email,
        Phone: orderForm.Phone,
        CompanyName: orderForm.CompanyName,
        TaxID: orderForm.TaxID,
        Branch: orderForm.Branch,
      } as OrderBillingInfoModel,
      OrderItems: assets.map((i) => {
        return {
          AssetId: i.id,
          StandardLicenseId: i.standardLicense.id,
          ExtendedLicenseId: i.extendedLicense.id,
          DiscountCode: promotionCode,
        } as OrderItemModel;
      }),
    } as OrderCreatingModel;

    const response = await new OrderService().createOrderCreditCard(orderCreatingModel);

    if (response.status !== HttpStatus.OK) {
      setIsHideLoading(true);

      return;
    }

    if (isOnCart) {
      RemoveOrderAssets();
    }

    if (!isRememberCard) {
      onDeleteCreditCard(creditCardId);
    }

    navigate(RouteNames.successOrder);
  };

  const onCreateOrderQRCode = async (orderForm: OrderModel) => {
    const orderCreatingModel = {
      userId: userProfile.userId,
      PaymentInfo: {
        creditCardId: '',
      } as CreditcardPaymentInfo,
      BuyerType: buyerType,
      PaymentMethod: paymentType,
      DiscountCode: promotionCode,
      OrderBillingInfo: {
        BuyerType: buyerType,
        Address: orderForm.Address,
        Postcode: orderForm.Postcode.toString(),
        SubDistrictId: subDistrictId,
        DistrictId: districtId,
        ProvinceId: provinceId,
        FullAddress: orderForm.Address,
        Firstname: orderForm.Firstname,
        Lastname: orderForm.Lastname,
        Email: orderForm.Email,
        Phone: orderForm.Phone,
        CompanyName: orderForm.CompanyName,
        TaxID: orderForm.TaxID,
        Branch: orderForm.Branch,
      } as OrderBillingInfoModel,
      OrderItems: assets.map((i) => {
        return {
          AssetId: i.id,
          StandardLicenseId: i.standardLicense.id,
          ExtendedLicenseId: i.extendedLicense.id,
          DiscountCode: promotionCode,
        } as OrderItemModel;
      }),
    } as OrderCreatingModel;

    const response = await new OrderService().createOrderPrompayAsync(orderCreatingModel);

    if (response.status !== HttpStatus.OK) {
      setIsHideLoading(true);

      return;
    }

    if (isOnCart) {
      RemoveOrderAssets();
    }

    navigate(`${RouteNames.payment}?id=${response.data.orderId}`);
  };

  const onCreateManualOrder = async (orderForm: OrderModel) => {
    const orderCreatingModel = {
      userId: userProfile.userId,
      PaymentInfo: {
        creditCardId: '',
      } as CreditcardPaymentInfo,
      BuyerType: buyerType,
      PaymentMethod: paymentType,
      DiscountCode: promotionCode,
      OrderBillingInfo: {
        BuyerType: buyerType,
        Address: orderForm.Address,
        Postcode: orderForm.Postcode.toString(),
        SubDistrictId: subDistrictId,
        DistrictId: districtId,
        ProvinceId: provinceId,
        FullAddress: orderForm.Address,
        Firstname: orderForm.Firstname,
        Lastname: orderForm.Lastname,
        Email: orderForm.Email,
        Phone: orderForm.Phone,
        CompanyName: orderForm.CompanyName,
        TaxID: orderForm.TaxID,
        Branch: orderForm.Branch,
      } as OrderBillingInfoModel,
      OrderItems: assets.map((i) => {
        return {
          AssetId: i.id,
          StandardLicenseId: i.standardLicense.id,
          ExtendedLicenseId: i.extendedLicense.id,
          DiscountCode: promotionCode,
        } as OrderItemModel;
      }),
    } as OrderCreatingModel;

    const response = await new OrderService().createOrderManualAsync(orderCreatingModel);

    if (response.status !== HttpStatus.OK) {
      setIsHideLoading(true);

      return;
    }

    if (isOnCart) {
      RemoveOrderAssets();
    }

    navigate(RouteNames.successOrder);
  };

  const onCreateOrderPaymentTerm = async (orderForm: OrderModel) => {
    const orderCreatingModel = {
      userId: userProfile.userId,
      PaymentInfo: {
        creditCardId: '',
      } as CreditcardPaymentInfo,
      BuyerType: buyerType,
      PaymentMethod: paymentType,
      DiscountCode: promotionCode,
      OrderBillingInfo: {
        BuyerType: buyerType,
        Address: orderForm.Address,
        Postcode: orderForm.Postcode.toString(),
        SubDistrictId: subDistrictId,
        DistrictId: districtId,
        ProvinceId: provinceId,
        FullAddress: orderForm.Address,
        Firstname: orderForm.Firstname,
        Lastname: orderForm.Lastname,
        Email: orderForm.Email,
        Phone: orderForm.Phone,
        CompanyName: orderForm.CompanyName,
        TaxID: orderForm.TaxID,
        Branch: orderForm.Branch,
      } as OrderBillingInfoModel,
      OrderItems: assets.map((i) => {
        return {
          AssetId: i.id,
          StandardLicenseId: i.standardLicense.id,
          ExtendedLicenseId: i.extendedLicense.id,
          DiscountCode: promotionCode,
        } as OrderItemModel;
      }),
    } as OrderCreatingModel;

    const response = await new OrderService().createOrderPaymentTermAsync(orderCreatingModel);

    if (response.status !== HttpStatus.OK) {
      setIsHideLoading(true);

      return;
    }

    if (isOnCart) {
      RemoveOrderAssets();
    }

    navigate(RouteNames.successPaymentTerm);
  };

  const onChangePostcode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (value.length === 5) {
      setPostcode(value);

      return;
    }

    setPostcode(StringConstants.EMPTY);
  };

  const onChangeRemember = (e: CheckboxChangeEvent) => {
    setIsRememberCard(e.target.checked);
  };

  const onUseCode = async () => {
    const discountCode = orderForm.getFieldValue('discountCode');
    if (discountCode) {
      const response = await new AccountService().getPromotionByDiscountCode(discountCode);

      if (response.status !== HttpStatus.OK) {
        messageApi.open({
          type: 'error',
          content: TranslationKey('order.discountCodeFailed')
        });
        setPromotionCode('');
        orderForm.setFieldValue('discountCode', undefined);
        setTotalDiscount(0);
        onCalculateOrder();
        return;
      }

      const promotion = response.data as PromotionModel;

      if (promotion && promotion.minimumBillAmount <= total) {
        if (promotion.promotionType === 'PercentagePromotion') {
          const disAmt = total * (promotion.count / 100);
          setPromotionCode(promotion.discountCode);
          if (promotion.maximumDiscountAmount > 0 && promotion.maximumDiscountAmount < disAmt) {
            setTotalDiscount(promotion.maximumDiscountAmount);
          } else {
            setTotalDiscount(disAmt);
          }
        } else {
          setPromotionCode(promotion.discountCode);
          setTotalDiscount(promotion.discountAmount);
        }

        onCalculateOrder();
      } else {
        messageApi.open({
          type: 'error',
          content: TranslationKey('order.discountCodeFailed')
        });
        setPromotionCode('');
        orderForm.setFieldValue('discountCode', undefined);
      }
    }
  };

  const onRemoveTag = () => {
    setPromotionCode('');
    setTotalDiscount(0);
    orderForm.setFieldValue('discountCode', undefined);
    onCalculateOrder();
  };

  const billingInfoTabs: TabsProps['items'] = [
    {
      key: BuyerType.Individual.toString(),
      label: (
        <Radio
          value={BuyerType.Individual}
          checked={buyerType === BuyerType.Individual}>
          <UserOutlined className='me-2' />
          {TranslationKey('individual.title')}
        </Radio>
      ),
      children: renderOrderBillingInfo(BuyerType.Individual),
    },
    {
      key: BuyerType.Company.toString(),
      label: (
        <Radio
          value={BuyerType.Company}
          checked={buyerType === BuyerType.Company}>
          <UserOutlined className='me-2' />
          {TranslationKey('company.title')}
        </Radio>
      ),
      children: renderOrderBillingInfo(BuyerType.Company),
    },
  ];

  function renderOrderBillingInfo(tab: BuyerType) {
    switch (tab) {
      case BuyerType.Individual:
        return (
          <Row gutter={8}>
            <Col
              xs={{ span: 24 }}
              md={{ span: 12 }}>
              <Form.Item
                name='Firstname'
                label={TranslationKey('individual.firstName')}
                rules={[
                  {
                    required: buyerType === BuyerType.Individual,
                    message: TranslationKey('individual.requireFirstName'),
                  },
                ]}>
                <Input
                  className='input-radius-dark non-radius'
                  value={order.Firstname}
                  size='large'
                  placeholder={TranslationKey('individual.firstName')}
                />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              md={{ span: 12 }}>
              <Form.Item
                name='Lastname'
                label={TranslationKey('individual.lastName')}
                rules={[
                  {
                    required: buyerType === BuyerType.Individual,
                    message: TranslationKey('individual.requireLastName'),
                  },
                ]}>
                <Input
                  className='input-radius-dark non-radius'
                  value={order.Lastname}
                  size='large'
                  placeholder={TranslationKey('individual.lastName')}
                />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              md={{ span: 12 }}>
              <Form.Item
                name='Email'
                label={TranslationKey('individual.email')}
                rules={[
                  {
                    required: buyerType === BuyerType.Individual,
                    message: TranslationKey('individual.requireEmail'),
                  },
                ]}>
                <Input
                  className='input-radius-dark non-radius'
                  value={order.Email}
                  size='large'
                  placeholder={TranslationKey('individual.email')}
                  readOnly
                />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              md={{ span: 12 }}>
              <Form.Item
                name='Phone'
                label={TranslationKey('individual.phone')}
                rules={[
                  {
                    required: buyerType === BuyerType.Individual,
                    message: TranslationKey('individual.requirePhoneNumber'),
                  },
                  {
                    pattern: RegexPattern.phone,
                    message: TranslationKey('individual.invalidPhoneNumberFormat'),
                  },
                ]}>
                <Input
                  className='input-radius-dark non-radius'
                  value={order.Phone}
                  size='large'
                  placeholder={TranslationKey('individual.phone')}
                />
              </Form.Item>
            </Col>
          </Row>
        );
      case BuyerType.Company:
        return (
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                name='CompanyName'
                label={TranslationKey('company.title')}
                rules={[
                  {
                    required: buyerType === BuyerType.Company,
                    message: TranslationKey('company.requireCompanyName'),
                  },
                ]}>
                <Input
                  className='input-radius-dark non-radius'
                  size='large'
                  value={order.CompanyName}
                  placeholder={TranslationKey('company.companyName')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='TaxID'
                label={TranslationKey('company.taxId')}
                rules={[{
                  required: buyerType === BuyerType.Company,
                  message: TranslationKey('company.requireTaxId'),
                }]}>
                <Input
                  className='input-radius-dark non-radius'
                  size='large'
                  value={order.TaxID}
                  placeholder={TranslationKey('company.taxId')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='Branch'
                label={TranslationKey('company.branch')}
                rules={[{
                  required: buyerType === BuyerType.Company,
                  message: TranslationKey('company.requireBranch'),
                }]}>
                <Input
                  className='input-radius-dark non-radius'
                  size='large'
                  value={order.Branch}
                  placeholder={TranslationKey('company.branch')}
                />
              </Form.Item>
            </Col>
          </Row>
        );
    }
  }

  const paymentTypeTabs: TabsProps['items'] = [
    {
      key: PaymentType.CreditCard.toString(),
      label: (
        <Radio
          value={PaymentType.CreditCard}
          checked={paymentType === PaymentType.CreditCard}>
          {isMobile ? <FaCreditCard /> : TranslationKey('order.creditCard')}
        </Radio>
      ),
      children: renderPaymentMethod(PaymentType.CreditCard),
    },
    {
      key: PaymentType.QRCode.toString(),
      label: (
        <Radio
          value={PaymentType.QRCode}
          checked={paymentType === PaymentType.QRCode}>
          {isMobile ? <FaQrcode /> : TranslationKey('order.qrCode')}
        </Radio>
      ),
      children: renderPaymentMethod(PaymentType.QRCode),
    },
  ];

  const paymentTypeVipTabs: TabsProps['items'] = [
    {
      key: PaymentType.CreditCard.toString(),
      label: (
        <Radio
          value={PaymentType.CreditCard}
          checked={paymentType === PaymentType.CreditCard}>
          Credit Card
        </Radio>
      ),
      children: renderPaymentMethod(PaymentType.CreditCard),
    },
    {
      key: PaymentType.QRCode.toString(),
      label: (
        <Radio
          value={PaymentType.QRCode}
          checked={paymentType === PaymentType.QRCode}>
          QR Code
        </Radio>
      ),
      children: renderPaymentMethod(PaymentType.QRCode),
    },
    {
      key: PaymentType.PaymentTerm.toString(),
      label: (
        <Radio
          value={PaymentType.PaymentTerm}
          checked={paymentType === PaymentType.PaymentTerm}>
          {isMobile ? <FaCommentDollar /> : 'Payment Term'}
        </Radio>
      ),
      children: renderPaymentMethod(PaymentType.PaymentTerm),
    },
    {
      key: PaymentType.Manual.toString(),
      label: (
        <Radio
          value={PaymentType.Manual}
          checked={paymentType === PaymentType.Manual}>
          {isMobile ? <FaCommentDollar /> : 'Other'}
        </Radio>
      ),
      children: renderPaymentMethod(PaymentType.Manual),
    },
  ];

  function renderPaymentMethod(tab: PaymentType) {
    order.PaymentMethod = tab;
    switch (tab) {
      case PaymentType.CreditCard:
        return (
          <>
            {isRememberCard && creditCard.id ? (
              <Card style={{ backgroundColor: '#0D0C0C', padding: 20 }}>
                <Row gutter={8}>
                  <Col span={20}>
                    <Form.Item
                      label={TranslationKey('order.cardNumber')}
                      style={{ color: '#FFF' }}>
                      {order.CardNumber}
                    </Form.Item>
                  </Col>
                  <Col
                    span={4}
                    className='text-right'>
                    <Button
                      size='large'
                      type='primary'
                      shape='circle'
                      icon={<DeleteOutlined />}
                      className='btn-outline btn-cart-close'
                      onClick={() => onDeleteCreditCard(creditCard.id)}
                    />
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={TranslationKey('order.nameOnCard')}
                      style={{ color: '#FFF' }}>
                      {order.NameOnCard}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label='Card Expire'
                      style={{ color: '#FFF' }}>
                      {order.CardExpireMonth} / {order.CardExpireYear}
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            ) : (
              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item
                    name='CardNumber'
                    label={TranslationKey('order.cardNumber')}
                    rules={[
                      {
                        required: paymentType === PaymentType.CreditCard,
                        message: TranslationKey('order.creditCardType.requireCardNumber')
                      },
                    ]}>
                    <MaskedInput
                      placeholder='XXXX XXXX XXXX XXXX'
                      className='input-radius-dark non-radius w-100'
                      maskGenerator={maskGenerator}
                      value={value}
                      style={{
                        height: '40px',
                        padding: 12,
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 8 }}>
                  <Form.Item
                    name='CardExpireMonth'
                    label={TranslationKey('order.cardExpireMonth')}
                    rules={[
                      {
                        required: paymentType === PaymentType.CreditCard,
                        message: TranslationKey('order.creditCardType.requireCardExpireMonth')
                      },
                    ]}>
                    <InputNumber
                      className='input-radius-dark non-radius inputNumber-text-light w-100'
                      min={1}
                      max={12}
                      placeholder='MM'
                      size='large'
                      controls={false}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 8 }}>
                  <Form.Item
                    name='CardExpireYear'
                    label={TranslationKey('order.cardExpireYear')}
                    rules={[
                      {
                        required: paymentType === PaymentType.CreditCard,
                        message: TranslationKey('order.creditCardType.requireCardExpireYear')
                      },
                    ]}>
                    <InputNumber
                      className='input-radius-dark non-radius inputNumber-text-light w-100'
                      min={minCardExpireYear}
                      max={maxCardExpireYear}
                      placeholder='YY'
                      size='large'
                      controls={false}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 8 }}>
                  <Form.Item
                    name='Cardcvv'
                    label={TranslationKey('order.cvv')}
                    rules={[{
                      required: paymentType === PaymentType.CreditCard,
                      message: TranslationKey('order.creditCardType.requireExpireCardCvv')
                    }]}>
                    <Input
                      className='input-radius-dark non-radius'
                      minLength={3}
                      maxLength={3}
                      placeholder='CVV'
                      size='large'
                      style={{ color: '#FFF' }}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name='NameOnCard'
                    label={TranslationKey('order.nameOnCard')}
                    rules={[{
                      required: paymentType === PaymentType.CreditCard,
                      message: TranslationKey('order.creditCardType.requireExpireCardName')
                    }]}>
                    <Input
                      className='input-radius-dark non-radius'
                      placeholder={TranslationKey('order.nameOnCard')}
                      size='large'
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name='rememberCard'>
                    <Checkbox
                      checked={isRememberCard}
                      onChange={onChangeRemember}>
                      {TranslationKey('order.rememberMyCardInfo')}
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            )}
          </>
        );
      case PaymentType.QRCode:
        return (
          <>
            <Card>
              <Row className='mt-5 mb-5'>
                <Col
                  span={24}
                  className='text-center'>
                  <Title level={5}>
                    {TranslationKey('order.theQrCodeInformation')}
                  </Title>
                </Col>
              </Row>
            </Card>
          </>
        );
      case PaymentType.PaymentTerm:
        return (
          <>
            <Card>
              <Row className='mt-5 mb-5'>
                <Col
                  span={24}
                  className='text-center'>
                  <Title level={5}>You are entitled to a special payment term</Title>
                </Col>
              </Row>
            </Card>
          </>
        );
      case PaymentType.Manual:
        return (
          <>
            <Card>
              <Row className='mt-5 mb-5'>
                <Col
                  span={24}
                  className='text-center'>
                  <Title
                    level={5}
                    className='mb-4'>
                    Please confirm your payment.
                  </Title>
                  <a
                    href='/contactus'
                    className='btn-orange-round'
                    style={{ textDecoration: 'none' }}>
                    Contact Alive
                  </a>
                </Col>
              </Row>
            </Card>
          </>
        );
    }
  }

  const getAmountLabel = (_amount: number) => {
    const amountText = _amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

    if (classStore.get(LanguageConstants.key) === LanguageConstants.th) {
      return amountText + ' ' + TranslationKey('cart.baht');
    }

    return TranslationKey('cart.baht') + amountText;
  };

  return (
    <>
      {contextHolder}
      <div className='container order-summary banner-margin-t-70'>
        <Title
          level={2}
          style={{ marginTop: '5rem' }}
          className='text-primary'>
          {TranslationKey('order.title')}
        </Title>
        <Form
          form={orderForm}
          onFinish={onPayNow}
          onFinishFailed={(error) => {
            console.log('error', error)
          }}
          layout='vertical'
          autoComplete='off'
          className='form-dark'>
          <Row>
            <Col
              xs={24}
              xl={16}>
              <Col span={24}>
                <div className='bg-outline-gray mt-2'>
                  <>
                    {assets.map((asset, index) => (
                      <div key={index}>
                        <CartItem
                          index={index}
                          asset={asset}
                          onDelete={onDelete}
                          onUpdateExtendedLicense={onUpdateExtendedLicense}
                          isUpdateExtendedLicense={true}
                          onUpdateStandardLicense={onUpdateStandardLicense}
                          isUpdateStandardLicense={true}
                        />
                        {index !== assets.length - 1 && (
                          <Divider
                            style={{
                              border: '1px solid',
                              borderImage:
                                'linear-gradient(rgba(49, 51, 89, 0.318), rgba(49, 51, 89, 1), rgba(49, 51, 89, 0)) 1',
                            }}
                          />
                        )}
                      </div>
                    ))}
                  </>
                </div>
              </Col>
              <Col span={24}>
                <div className='bg-outline-gray mt-5 radio-type-customer custom-color-radio'>
                  <Title
                    level={4}
                    className='text-light'>
                    {TranslationKey('order.customerInfo')}
                  </Title>
                  <Tabs
                    defaultActiveKey='0'
                    items={billingInfoTabs}
                    onChange={onCustomerInfoChange}
                  />
                  <div>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          name='Address'
                          label={TranslationKey('address')}
                          rules={[{
                            required: true,
                            message: TranslationKey('address.requireAddress')
                          }]}>
                          <Input
                            className='input-radius-dark non-radius'
                            value={order.Address}
                            size='large'
                            placeholder={TranslationKey('address')}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 6 }}>
                        <Form.Item
                          name='ProvinceId'
                          label={TranslationKey('address.province')}
                          hasFeedback={orderValidate.province.isError}
                          validateStatus={orderValidate.province.isError ? `error` : `success`}
                          help={orderValidate.province.isError ? orderValidate.province.message : ``}
                          rules={[{
                            required: true,
                            message: TranslationKey('address.requireProvince')
                          }]}>
                          <SelectProvince
                            className='input-radius-dark non-radius'
                            provinceID={provinceId}
                            sendProvinceID={(id) => setProvinceId(id)}
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 6 }}>
                        <Form.Item
                          name='DistrictId'
                          label={TranslationKey('address.district')}
                          hasFeedback={orderValidate.district.isError}
                          validateStatus={orderValidate.district.isError ? `error` : `success`}
                          help={orderValidate.district.isError ? orderValidate.district.message : ``}
                          rules={[{
                            required: true,
                            message: TranslationKey('address.requireDistrict')
                          }]}>
                          <SelectDistrict
                            className='input-radius-dark non-radius'
                            districtID={districtId}
                            provinceID={provinceId}
                            sendProvinceID={(id) => setProvinceId(id)}
                            sendDistrictID={(id) => {
                              setDistrictId(id)
                              orderForm.setFieldValue('DistrictId', id);
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 6 }}>
                        <Form.Item
                          name='SubDistrictId'
                          label={TranslationKey('address.subDistrict')}
                          hasFeedback={orderValidate.subDistrict.isError}
                          validateStatus={orderValidate.subDistrict.isError ? `error` : `success`}
                          help={orderValidate.subDistrict.isError ? orderValidate.subDistrict.message : ``}
                          rules={[{
                            required: true,
                            message: TranslationKey('address.requireSubDistrict')
                          }]}>
                          <SelectSubDistrict
                            className='input-radius-dark non-radius'
                            postCode={postcode}
                            districtID={districtId}
                            provinceID={provinceId}
                            subDistrictID={subDistrictId}
                            sendPostCode={(value) => {
                              orderForm.setFieldValue('Postcode', value);
                            }}
                            sendSubDistrictID={(value) => {
                              setSubDistrictId(value);
                              orderForm.setFieldValue('SubDistrictId', value);
                            }}
                            sendDistrictID={(id, subId) => {
                              setDistrictId(id);
                              setSubDistrictId(subId);
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 6 }}>
                        <Form.Item
                          name='Postcode'
                          label={TranslationKey('address.postcode')}
                          rules={[{ required: true, message: 'Please input your postcode!' }]}>
                          <Input
                            readOnly
                            className='input-radius-dark non-radius'
                            onChange={onChangePostcode}
                            value={order.Postcode}
                            size='large'
                            placeholder={TranslationKey('address.postcode')}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className='bg-outline-gray mt-5 radio-type-customer custom-color-radio'>
                  <Title
                    level={4}
                    className='text-light'>
                    {TranslationKey('order.paymentMethod')}
                  </Title>
                  {!isOverLimit ? (
                    <Tabs
                      defaultActiveKey='0'
                      items={
                        userProfile && userProfile.isVip && userProfile.isCanPaymentTerm
                          ? paymentTypeVipTabs
                          : paymentTypeTabs
                      }
                      onChange={onPaymentMethodChange}
                    />
                  ) : (
                    <Card>
                      <Row className='mt-5 mb-5'>
                        <Col
                          span={24}
                          className='text-center'>
                          <Title
                            level={5}
                            className='mb-4'>
                            Your order balance is overdue. Please contact our team.
                          </Title>
                          <a
                            href='/contactus'
                            className='btn-orange-round'
                            style={{ textDecoration: 'none' }}>
                            Contact Alive
                          </a>
                        </Col>
                      </Row>
                    </Card>
                  )}
                </div>
              </Col>
            </Col>
            <Col
              xs={24}
              xl={8}
              className='card-payment'>
              <div className='bg-outline-gray mt-2'>
                <Title
                  level={4}
                  className='text-light'>
                  {TranslationKey('order.paymentSummary')}
                </Title>
                <Row justify='space-between'>
                  <Col span={12}>
                    <Title
                      level={5}
                      className='text-light'>
                      {TranslationKey('order.total')}

                    </Title>
                  </Col>
                  <Col
                    span={12}
                    className='text-end'>
                    <Title
                      level={5}
                      className='text-light'>
                      {getAmountLabel(total)}
                    </Title>
                  </Col>
                </Row>
                <Row justify='space-between'>
                  <Col span={12}>
                    <Title
                      level={5}
                      className='text-light'>
                      {TranslationKey('order.discountTotal')}{' '}
                      {promotionCode ? (
                        <Tag
                          color='#FF5501'
                          style={{ color: '#FFF' }}
                          closable
                          onClose={onRemoveTag}>
                          {promotionCode}
                        </Tag>
                      ) : (
                        <></>
                      )}
                    </Title>
                  </Col>
                  <Col
                    span={12}
                    className='text-end'>
                    <Title
                      level={5}
                      className='text-light'>
                      {getAmountLabel(totalDiscount)}
                    </Title>
                  </Col>
                </Row>
                <Row justify='space-between'>
                  <Col span={12}>
                    <Title
                      level={5}
                      className='text-light'>
                      {TranslationKey('order.afterDiscountTotal')}
                    </Title>
                  </Col>
                  <Col
                    span={12}
                    className='text-end'>
                    <Title
                      level={5}
                      className='text-light'>
                      {getAmountLabel(afterDiscount)}
                    </Title>
                  </Col>
                </Row>
                <hr className='hr-gray' />
                <Row justify='space-between'>
                  <Col span={12}>
                    <Title
                      level={5}
                      className='text-light'>
                      {TranslationKey('order.vat')}
                    </Title>
                  </Col>
                  <Col
                    span={12}
                    className='text-end'>
                    <Title
                      level={5}
                      className='text-light'>
                      {getAmountLabel(totalVat)}
                    </Title>
                  </Col>
                </Row>
                <Row justify='space-between'>
                  <Col span={12}>
                    <Title
                      level={4}
                      className='text-light'>
                      {TranslationKey('order.grandTotal')}
                    </Title>
                  </Col>
                  <Col
                    span={12}
                    className='text-end'>
                    <Title
                      level={4}
                      className='text-light'>
                      {getAmountLabel(grandTotal)}
                    </Title>
                  </Col>
                </Row>
                <Row
                  justify='start'
                  align='middle'
                  gutter={16}
                  className='mt-4'>
                  <Col
                    xs={{ span: 24 }}
                    xxl={{ span: 18 }}>
                    <Form.Item
                      name='discountCode'
                      label={TranslationKey('order.discountCode')}>
                      <Input
                        className='input-radius-dark'
                        size='large'
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    xxl={{ span: 6 }}>
                    <Button
                      type='primary'
                      shape='round'
                      className='btn-light'
                      size='large'
                      onClick={onUseCode}
                      block
                      disabled={!userProfile.userId}>
                      {TranslationKey('order.useCode')}
                    </Button>
                  </Col>
                </Row>
                {userProfile && userProfile.userId && !isOverLimit ? (
                  <Button
                    type='primary'
                    htmlType='submit'
                    shape='round'
                    className='btn-primary w-100 mt-2'
                    size='large'
                    disabled={assets.length === 0}
                    block>
                    {TranslationKey('order.payNow')}
                  </Button>
                ) : (
                  <Button
                    type='primary'
                    shape='round'
                    className='btn-primary w-100 mt-2'
                    size='large'
                    disabled={assets.length === 0}
                    onClick={() => navigate(RouteNames.contactus)}
                    block>
                    Contact Alive
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Form>
      </div>
      <Loading isLoading={isHideLoading} />
    </>
  );
}
