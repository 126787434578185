import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FavoriteService } from 'src/services/favoriteService';
import { HttpStatus } from 'src/constant/responseStatus';
import { FavoriteResponseModel } from 'src/models/responses/favoriteModel';
import { IFavoriteCreateRequest, IFavoriteItemCreateRequest } from 'src/models/request/favoriteRequestModel';
import { Button, Col, Form, Input, Row, notification } from 'antd';
import { FolderOutlined } from '@ant-design/icons';
import TranslationKey from 'src/i18n/translation';

interface ModalInterface {
  isFavoriteModalOpen: boolean;
  onCloseFavoriteModal: any;
  assetId: string;
}

export default function MyFavoriteModal(
  {
    isFavoriteModalOpen: isModalOpen = false,
    onCloseFavoriteModal: onClose,
    assetId
  }: ModalInterface) {
  const [favorites, setFavorites] = useState<FavoriteResponseModel[]>([]);
  const [api, contextHolders] = notification.useNotification();
  const [form] = Form.useForm();

  useEffect(() => {
    onGetMyFavoriteFolderAllAsync();
  }, [isModalOpen]);

  const onGetMyFavoriteFolderAllAsync = async () => {
    if (!isModalOpen) {
      return;
    }

    const {
      data: responseData,
      status: responseStatus
    } = await new FavoriteService().getFavoriteFolderAllAsync();

    if (responseStatus !== HttpStatus.OK) {
      return;
    }

    setFavorites(responseData);

    if (responseData.length === 0) {
      const favCreate = {
        name: 'Default',
      } as IFavoriteCreateRequest;

      onCreateDefaultFavoriteAsync(favCreate);
    }
  };

  const onSubmitAddNewFavoriteFolder = (values: any) => {
    if (favorites.filter((fav) => fav.name === values.name).length > 0) {
      return;
    }

    const favCreate = {
      name: values.name,
    } as IFavoriteCreateRequest;

    onAddFavoriteAsync(favCreate);
  };

  const onCreateDefaultFavoriteAsync = async (data: IFavoriteCreateRequest) => {
    const {
      status: responseStatus
    } = await new FavoriteService().createFavoriteAsync(data);

    if (responseStatus !== HttpStatus.CREATED) {
      api.error({
        message: TranslationKey('myFavorite.modal.saveFolderFail'),
      });

      return;
    }

    api.success({
      message: TranslationKey('myFavorite.modal.saveFolderSuccess'),
    });

    onGetMyFavoriteFolderAllAsync();

    form.resetFields();
  };

  const onAddFavoriteAsync = async (data: IFavoriteCreateRequest) => {
    const {
      data: responseData,
      status: responseStatus
    } = await new FavoriteService().createFavoriteAsync(data);

    if (responseStatus !== HttpStatus.CREATED) {
      api.error({
        message: TranslationKey('myFavorite.modal.saveFolderFail'),
      });

      return;
    }

    api.success({
      message: TranslationKey('myFavorite.modal.saveFolderSuccess'),
    });

    onGetMyFavoriteFolderAllAsync();
    form.resetFields();
  };

  const onClickFavoriteIcon = async (
    favoriteFolder: FavoriteResponseModel,
    isFavoriteItemActive: boolean) => {

    if (isFavoriteItemActive) {
      const favoriteItemId =
        favoriteFolder.favoriteItems.filter(favItem => favItem.assetId === assetId)[0].id;

      if (!favoriteItemId) {
        api.error({
          message: TranslationKey('myFavorite.modal.favoriteItemNotFound'),
        });
      }

      onDeleteFavoriteItemAsync(favoriteFolder.id, favoriteItemId);
    }
    else {
      onAddFavoriteItemAsync(favoriteFolder.id);
    }
  }

  const onDeleteFavoriteItemAsync = async (favId: string, itemId: string) => {
    const { status: responseStatus } = await new FavoriteService().deleteFavoriteItemAsync(favId, itemId);

    if (responseStatus !== HttpStatus.NO_CONTENT) {
      api.error({
        message: TranslationKey('myFavorite.modal.deleteItemFail'),
      });

      return;
    }

    api.success({
      message: TranslationKey('myFavorite.modal.deleteItemSuccess'),
    });

    onClose();
  };

  const onAddFavoriteItemAsync = async (favId: string) => {
    const favItemCreate = {
      assetId: assetId,
    } as IFavoriteItemCreateRequest;

    const {
      data: responseData,
      status: responseStatus
    } = await new FavoriteService().addFavoriteItemAsync(favId, favItemCreate);

    if (responseStatus !== HttpStatus.CREATED) {
      api.error({
        message: TranslationKey('myFavorite.modal.addItemFail'),
      });

      return;
    }

    api.success({
      message: TranslationKey('myFavorite.modal.addItemSuccess'),
    });

    onClose();
  };

  return (
    <>
      {contextHolders}
      <Modal
        show={isModalOpen}
        onHide={onClose}
        dialogClassName='fav-modal'>
        <Modal.Header>
          {TranslationKey('myFavorite.modal.title')}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col span={24}>
              <Form
                form={form}
                name='form_item_path'
                layout='vertical'
                onFinish={onSubmitAddNewFavoriteFolder}
                className='form-input'
              >
                <Row gutter={16}>
                  <Col span={18}>
                    <Form.Item
                      name='name'
                      className='text-light'
                      rules={[
                        {
                          required: true,
                          message: TranslationKey('myFavorite.modal.requireFolderName')
                        }]}
                    >
                      <Input
                        className='input-radius-dark w-100'
                        placeholder={TranslationKey('myFavorite.modal.placeHolderFolderName')} />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Button
                      type='primary'
                      shape='round'
                      className='btn-primary'
                      htmlType='submit'
                      block
                      size='large'
                    >
                      {TranslationKey('myFavorite.modal.add')}
                    </Button>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  {favorites
                    .sort((a, b) => (a.name < b.name ? -1 : 1))
                    .map((fav, index) => {
                      const isFavoriteActive =
                        fav.favoriteItems.some((favitem) => favitem.assetId === assetId);

                      const favoriteItemClassName =
                        isFavoriteActive
                          ? 'btn-favorite-folder active'
                          : 'btn-favorite-folder';

                      return (
                        <Col span={8} key={'favFolder_' + index}>
                          <div
                            key={'favItem_' + index}
                            className={(favoriteItemClassName)}
                            onClick={() => onClickFavoriteIcon(fav, isFavoriteActive)}>
                            <FolderOutlined style={{ fontSize: '4em' }} />
                            {fav.name}
                          </div>
                        </Col>
                      );
                    })}
                </Row>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
