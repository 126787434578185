import { ArtistTaxInfoAttachmentInit, ArtistTaxInfoFileType, ArtistTaxInfoProperty, NationalityType } from 'src/constant/artistTaxInfoConstants';
import { ArtistTaxInfoAttachmentModel } from 'src/models/TaxCenterModel';
import { Button, Col, Form, Input, Row, Upload, UploadProps, message } from 'antd';
import Title from 'antd/es/typography/Title';
import { useEffect, useState } from 'react';
import { StringConstants } from 'src/constant/stringConstants';
import { UploadOutlined } from '@ant-design/icons';
import { UploadFileConstants, UploadListType, UploadValidationMessages } from 'src/constant/uploadFileConstants';
import { S3Constants } from 'src/constant/s3Constants';
import { useSessionContext } from 'src/context/session-context';
import AWS from 'aws-sdk';
import TranslationKey from 'src/i18n/translation';

interface CompanyTaxCenterProps {
  nationalityType: string,
  compVatRegisterCertificateAttachment: ArtistTaxInfoAttachmentModel,
  compCerRegIssueAttachment: ArtistTaxInfoAttachmentModel,
  compCommercialRegAttachment: ArtistTaxInfoAttachmentModel,
  compListOfShareholdersAttachment: ArtistTaxInfoAttachmentModel,
  compIdCardAuthorizedPersonAttachment: ArtistTaxInfoAttachmentModel,
  onUploadFileChanged: (
    fileType: ArtistTaxInfoFileType,
    file: ArtistTaxInfoAttachmentModel) => void;
}

export default function CompanyTaxCenter(props: CompanyTaxCenterProps) {
  const [, setIsThai] = useState(false);
  const { userProfile } = useSessionContext();
  const [compVatRegisterCertificateFile, setCompVatRegisterCertificateFile] = useState<ArtistTaxInfoAttachmentModel>(ArtistTaxInfoAttachmentInit.defaultFileValue);
  const [compCerRegIssueFile, setCompCerRegIssueFile] = useState<ArtistTaxInfoAttachmentModel>(ArtistTaxInfoAttachmentInit.defaultFileValue);
  const [compCommercialRegFile, setCompCommercialRegFile] = useState<ArtistTaxInfoAttachmentModel>(ArtistTaxInfoAttachmentInit.defaultFileValue);
  const [compListOfShareholdersFile, setCompListOfShareholdersFile] = useState<ArtistTaxInfoAttachmentModel>(ArtistTaxInfoAttachmentInit.defaultFileValue);
  const [compIdCardAuthorizedPersonFile, setCompIdCardAuthorizedPersonFile] = useState<ArtistTaxInfoAttachmentModel>(ArtistTaxInfoAttachmentInit.defaultFileValue);

  useEffect(() => {
    if (props.nationalityType) {
      setIsThai(props.nationalityType === NationalityType.Thai);
    }
  }, [props.nationalityType]);

  useEffect(() => {
    if (props.compVatRegisterCertificateAttachment) {
      setCompVatRegisterCertificateFile(props.compVatRegisterCertificateAttachment);
    }
  }, [props.compVatRegisterCertificateAttachment]);

  useEffect(() => {
    if (props.compCerRegIssueAttachment) {
      setCompCerRegIssueFile(props.compCerRegIssueAttachment);
    }
  }, [props.compCerRegIssueAttachment]);

  useEffect(() => {
    if (props.compCommercialRegAttachment) {
      setCompCommercialRegFile(props.compCommercialRegAttachment);
    }
  }, [props.compCommercialRegAttachment]);

  useEffect(() => {
    if (props.compListOfShareholdersAttachment) {
      setCompListOfShareholdersFile(props.compListOfShareholdersAttachment);
    }
  }, [props.compListOfShareholdersAttachment]);

  useEffect(() => {
    if (props.compIdCardAuthorizedPersonAttachment) {
      setCompIdCardAuthorizedPersonFile(props.compIdCardAuthorizedPersonAttachment);
    }
  }, [props.compIdCardAuthorizedPersonAttachment]);


  AWS.config.update({
    accessKeyId: S3Constants.ACCESS_KEY_ID,
    secretAccessKey: S3Constants.SECRET_ACCESS_KEY,
  });

  const S3_BUCKET = S3Constants.BUCKET;
  const REGION = S3Constants.REGION;
  const s3Bucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const deleteFileFromS3 = async (fileName: string) => {
    const params = {
      Bucket: S3Constants.BUCKET,
      Key: fileName,
    };

    await s3Bucket.deleteObject(params, (err) => {
      if (err) {
        console.log(err, err.stack);
        message.error(`${fileName} file remove failed.`);
      }
    });
  };

  const getFileName = (originalFileName: string, fileType: ArtistTaxInfoFileType) => {
    const now = new Date();

    return (
      UploadFileConstants.FILE_NAME_PREFIX +
      userProfile.userId +
      StringConstants.UNDERSCORE +
      fileType +
      StringConstants.UNDERSCORE +
      now.getUTCFullYear() +
      (now.getUTCMonth() + 1) +
      now.getUTCDate() +
      now.getUTCHours() +
      now.getUTCMinutes() +
      now.getUTCSeconds() +
      now.getUTCMilliseconds() +
      StringConstants.UNDERSCORE +
      originalFileName
    );
  };

  const addFileToS3 = async (
    fileType: ArtistTaxInfoFileType,
    originalFile: any,
    onError: any,
    onSuccess: any,
    onProgress: any) => {
    switch (fileType) {
      case ArtistTaxInfoFileType.compVatRegisterCertificate: {
        setCompVatRegisterCertificateFile(ArtistTaxInfoAttachmentInit.defaultFileValue);
        break;
      }
      case ArtistTaxInfoFileType.compCerRegIssue: {
        setCompCerRegIssueFile(ArtistTaxInfoAttachmentInit.defaultFileValue);
        break;
      }
      case ArtistTaxInfoFileType.compCommercialReg: {
        setCompCommercialRegFile(ArtistTaxInfoAttachmentInit.defaultFileValue);
        break;
      }
      case ArtistTaxInfoFileType.compListOfShareholders: {
        setCompListOfShareholdersFile(ArtistTaxInfoAttachmentInit.defaultFileValue);
        break;
      }
      case ArtistTaxInfoFileType.compIdCardAuthorizedPerson: {
        setCompIdCardAuthorizedPersonFile(ArtistTaxInfoAttachmentInit.defaultFileValue);
        break;
      }
    }

    const fileName = getFileName(originalFile.name, fileType);
    const filePath = `${S3Constants.FILE_NAME_FORMAT}${fileName}`;
    const params = {
      ACL: S3Constants.ACL_TERM,
      Body: originalFile,
      Bucket: S3_BUCKET,
      Key: fileName,
      ContentType: originalFile.type,
    };

    s3Bucket
      .putObject(params)
      .on(S3Constants.EVENT_ON_UPLOAD, (evt) => {
        const progressPercent = Math.round((evt.loaded / evt.total) * 100);
        onProgress!({ percent: progressPercent });
      })
      .promise()
      .then(
        async () => {
          var fileUploaded = {
            url: filePath,
            fileNameOriginal: originalFile.name,
            fileName: fileName
          };

          switch (fileType) {
            case ArtistTaxInfoFileType.compVatRegisterCertificate: {
              setCompVatRegisterCertificateFile(fileUploaded);
              break;
            }
            case ArtistTaxInfoFileType.compCerRegIssue: {
              setCompCerRegIssueFile(fileUploaded);
              break;
            }
            case ArtistTaxInfoFileType.compCommercialReg: {
              setCompCommercialRegFile(fileUploaded);
              break;
            }
            case ArtistTaxInfoFileType.compListOfShareholders: {
              setCompListOfShareholdersFile(fileUploaded);
              break;
            }
            case ArtistTaxInfoFileType.compIdCardAuthorizedPerson: {
              setCompIdCardAuthorizedPersonFile(fileUploaded);
              break;
            }
          }

          onSuccess!(fileName);
          props.onUploadFileChanged(fileType, fileUploaded);
        },
        (err) => {
          onError!(err);
          throw err;
        }
      );
  };

  const validateUploadFile = async (file: File, onError: any) => {
    const unknowFileType = !file.type || file.type === StringConstants.EMPTY;
    const fileTypeNotSupport = !UploadFileConstants.MIME_RELEASES_ACCEPT.includes(file.type);

    if (unknowFileType || fileTypeNotSupport) {
      const errorMessage = file.name + StringConstants.SPACE + UploadValidationMessages.INVALID_FILE_TYPE;
      onError(errorMessage);

      return errorMessage;
    }

    return StringConstants.EMPTY.toString();
  };

  const uploadPropsCompVatRegisterCertificate: UploadProps = {
    multiple: false,
    listType: UploadListType.TEXT,
    accept: UploadFileConstants.MIME_RELEASES_ACCEPT.toString(),
    maxCount: 1,
    showUploadList: true,
    progress: {
      strokeColor: {
        '0%': '#DBDBFF',
        '100%': '#4D4DFF',
      },
      showInfo: false,
      status: 'active',
    },
    async onRemove() {
      deleteFileFromS3(compVatRegisterCertificateFile.fileName);
    },
    async customRequest({ file, onError, onSuccess, onProgress }) {
      const originalFile = file as File;
      let errorMessage = await validateUploadFile(originalFile, onError);
      if (errorMessage !== StringConstants.EMPTY) {
        message.error(errorMessage, 10);
        return;
      }

      await addFileToS3(ArtistTaxInfoFileType.compVatRegisterCertificate, originalFile, onError, onSuccess, onProgress);
    },
  };

  const uploadPropsCompCerRegIssue: UploadProps = {
    multiple: false,
    listType: UploadListType.TEXT,
    accept: UploadFileConstants.MIME_RELEASES_ACCEPT.toString(),
    maxCount: 1,
    showUploadList: true,
    progress: {
      strokeColor: {
        '0%': '#DBDBFF',
        '100%': '#4D4DFF',
      },
      showInfo: false,
      status: 'active',
    },
    async onRemove() {
      deleteFileFromS3(compCerRegIssueFile.fileName);
    },
    async customRequest({ file, onError, onSuccess, onProgress }) {
      const originalFile = file as File;
      let errorMessage = await validateUploadFile(originalFile, onError);
      if (errorMessage !== StringConstants.EMPTY) {
        message.error(errorMessage, 10);
        return;
      }

      await addFileToS3(ArtistTaxInfoFileType.compCerRegIssue, originalFile, onError, onSuccess, onProgress);
    },
  };

  const uploadPropsCompCommercialReg: UploadProps = {
    multiple: false,
    listType: UploadListType.TEXT,
    accept: UploadFileConstants.MIME_RELEASES_ACCEPT.toString(),
    maxCount: 1,
    showUploadList: true,
    progress: {
      strokeColor: {
        '0%': '#DBDBFF',
        '100%': '#4D4DFF',
      },
      showInfo: false,
      status: 'active',
    },
    async onRemove() {
      deleteFileFromS3(compCommercialRegFile.fileName);
    },
    async customRequest({ file, onError, onSuccess, onProgress }) {
      const originalFile = file as File;
      let errorMessage = await validateUploadFile(originalFile, onError);
      if (errorMessage !== StringConstants.EMPTY) {
        message.error(errorMessage, 10);
        return;
      }

      await addFileToS3(ArtistTaxInfoFileType.compCommercialReg, originalFile, onError, onSuccess, onProgress);
    },
  };

  const uploadPropsCompListOfShareholders: UploadProps = {
    multiple: false,
    listType: UploadListType.TEXT,
    accept: UploadFileConstants.MIME_RELEASES_ACCEPT.toString(),
    maxCount: 1,
    showUploadList: true,
    progress: {
      strokeColor: {
        '0%': '#DBDBFF',
        '100%': '#4D4DFF',
      },
      showInfo: false,
      status: 'active',
    },
    async onRemove() {
      deleteFileFromS3(compListOfShareholdersFile.fileName);
    },
    async customRequest({ file, onError, onSuccess, onProgress }) {
      const originalFile = file as File;
      let errorMessage = await validateUploadFile(originalFile, onError);
      if (errorMessage !== StringConstants.EMPTY) {
        message.error(errorMessage, 10);
        return;
      }

      await addFileToS3(ArtistTaxInfoFileType.compListOfShareholders, originalFile, onError, onSuccess, onProgress);
    },
  };

  const uploadPropsCompIdCardAuthorizedPerson: UploadProps = {
    multiple: false,
    listType: UploadListType.TEXT,
    accept: UploadFileConstants.MIME_RELEASES_ACCEPT.toString(),
    maxCount: 1,
    showUploadList: true,
    progress: {
      strokeColor: {
        '0%': '#DBDBFF',
        '100%': '#4D4DFF',
      },
      showInfo: false,
      status: 'active',
    },
    async onRemove() {
      deleteFileFromS3(compIdCardAuthorizedPersonFile.fileName);
    },
    async customRequest({ file, onError, onSuccess, onProgress }) {
      const originalFile = file as File;
      let errorMessage = await validateUploadFile(originalFile, onError);
      if (errorMessage !== StringConstants.EMPTY) {
        message.error(errorMessage, 10);
        return;
      }

      await addFileToS3(ArtistTaxInfoFileType.compIdCardAuthorizedPerson, originalFile, onError, onSuccess, onProgress);
    },
  };

  return (
    <>
      <Row>
        <Col span={24} className='mb-4'>
          <Title level={2}>{TranslationKey('taxCenter.pleaseComplete')}</Title>
          <p>{TranslationKey('taxCenter.basedOnTheInformation')}</p>
          <p>{TranslationKey('taxCenter.youMayWantToReview')}</p>
          <p><span className='text-danger'>*</span> {TranslationKey('taxCenter.markedFields')}</p>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Title level={3}>
            {TranslationKey('taxCenter.part1')}: {TranslationKey('taxCenter.identificationOfBeneficial')}
          </Title>
          <p>{TranslationKey('taxCenter.nameAs')}<span className='text-danger'>*</span></p>
        </Col>
        <Col md={12} sm={24}>
          <Form.Item
            label={`${TranslationKey('taxCenter.nameOfJuristicPerson')} (${TranslationKey('inputLanguage.th')})`}
            name="juristicPersonNameTh"
            rules={[
              {
                required: true,
                message: `${TranslationKey('taxCenter.nameOfJuristicPerson')} (${TranslationKey('inputLanguage.th')})`,
              }]}>
            <Input
              size='large'
              className='w-100'
            />
          </Form.Item>
        </Col>
        <Col md={12} sm={24}>
          <Form.Item
            label={`${TranslationKey('taxCenter.nameOfJuristicPerson')} (${TranslationKey('inputLanguage.en')})`}
            name="juristicPersonNameEn"
            rules={[
              {
                required: true,
                message: `${TranslationKey('taxCenter.nameOfJuristicPerson')} (${TranslationKey('inputLanguage.en')})`,
              }]}>
            <Input
              size='large'
              className='w-100'
            />
          </Form.Item>
        </Col>
        <Col md={12} sm={24}>
          <Form.Item
            label={TranslationKey('taxCenter.idCardNumber')}
            name="taxIdNumber"
            rules={[
              {
                required: true,
                message: TranslationKey('taxCenter.requireIdCardNumber'),
              }]}>
            <Input
              size='large'
              className='w-100'
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Title level={3}>{TranslationKey('taxCenter.juristicPersonAddress')} ({TranslationKey('inputLanguage.th')})</Title>
        </Col>
        <Col md={12} sm={24}>
          <Form.Item
            label={TranslationKey('taxCenter.addressLine1')}
            name="companyAddress1Th">
            <Input
              size='large'
              className='w-100'
            />
          </Form.Item>
        </Col>
        <Col md={12} sm={24}>
          <Form.Item
            label={TranslationKey('taxCenter.addressLine2')}
            name="companyAddress2Th">
            <Input
              size='large'
              className='w-100'
            />
          </Form.Item>
        </Col>
        <Col md={12} sm={24}>
          <Form.Item
            label={TranslationKey('taxCenter.city')}
            name="cityTh">
            <Input
              size='large'
              className='w-100'
            />
          </Form.Item>
        </Col>
        <Col md={12} sm={24}>
          <Form.Item
            label={TranslationKey('taxCenter.postal')}
            name="postcodeTh">
            <Input
              size='large'
              className='w-100'
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Title level={3}>
            {TranslationKey('taxCenter.juristicPersonAddress')} ({TranslationKey('inputLanguage.en')})
          </Title>
        </Col>
        <Col md={12} sm={24}>
          <Form.Item
            label={TranslationKey('taxCenter.address1')}
            name="companyAddress1En">
            <Input
              size='large'
              className='w-100'
            />
          </Form.Item>
        </Col>
        <Col md={12} sm={24}>
          <Form.Item
            label={TranslationKey('taxCenter.address2')}
            name="companyAddress2En">
            <Input
              size='large'
              className='w-100'
            />
          </Form.Item>
        </Col>
        <Col md={12} sm={24}>
          <Form.Item
            label={TranslationKey('taxCenter.city')}
            name="cityEn">
            <Input
              size='large'
              className='w-100'
            />
          </Form.Item>
        </Col>
        <Col md={12} sm={24}>
          <Form.Item
            label={TranslationKey('taxCenter.postal')}
            name="postcodeEn">
            <Input
              size='large'
              className='w-100'
            />
          </Form.Item>
        </Col>
        <Col md={12} sm={24}>
          <Form.Item
            label={TranslationKey('taxCenter.companyContactEmail')}
            name="companyContactEmail">
            <Input
              size='large'
              className='w-100'
            />
          </Form.Item>
        </Col>
        <Col md={12} sm={24}>
          <Form.Item
            label={TranslationKey('taxCenter.companyContactPhone')}
            name="companyContactPhone">
            <Input
              size='large'
              className='w-100'
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Title level={3}>
            {TranslationKey('taxCenter.upload5TaxFiles')}
          </Title>
          <Form.Item
            name={ArtistTaxInfoProperty.compVatRegisterCertificateAttachmentUrl}
            className='mt-4 text-light '
            label={TranslationKey('taxCenter.taxFiles.1')}>
            <div className='upload-form-box'>
              {
                compVatRegisterCertificateFile && compVatRegisterCertificateFile.url !== StringConstants.EMPTY
                  ?
                  <div className='d-flex gap-2'>
                    <Button type='default' size='small'
                      onClick={() => {
                        setCompVatRegisterCertificateFile(ArtistTaxInfoAttachmentInit.defaultFileValue);
                        props.onUploadFileChanged(
                          ArtistTaxInfoFileType.compVatRegisterCertificate,
                          ArtistTaxInfoAttachmentInit.defaultFileValue)
                      }}
                      danger>
                      {TranslationKey('button.newUpload')}
                    </Button>
                    <a href={compVatRegisterCertificateFile.url}
                      target='_blank'
                      rel="noreferrer">
                      <span style={{ color: 'black' }}>
                        {compVatRegisterCertificateFile.fileNameOriginal}
                      </span>
                    </a>
                  </div>
                  :
                  <Upload {...uploadPropsCompVatRegisterCertificate}>
                    <Button icon={<UploadOutlined />}>
                      {TranslationKey('button.clickToUpload')}
                    </Button>
                  </Upload>
              }
            </div>
          </Form.Item>
          <Form.Item name={ArtistTaxInfoProperty.compVatRegisterCertificateAttachmentFileName} hidden></Form.Item>
          <Form.Item name={ArtistTaxInfoProperty.compVatRegisterCertificateAttachmentFileNameOriginal} hidden></Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name={ArtistTaxInfoProperty.compCerRegIssueAttachmentUrl}
            className='mt-4 text-light '
            label={TranslationKey('taxCenter.taxFiles.2')}>
            <div className='upload-form-box'>
              {
                compCerRegIssueFile && compCerRegIssueFile.url !== StringConstants.EMPTY
                  ?
                  <div className='d-flex gap-2'>
                    <Button type='default' size='small'
                      onClick={() => {
                        setCompCerRegIssueFile(ArtistTaxInfoAttachmentInit.defaultFileValue);
                        props.onUploadFileChanged(
                          ArtistTaxInfoFileType.compCerRegIssue,
                          ArtistTaxInfoAttachmentInit.defaultFileValue)
                      }}
                      danger>
                      {TranslationKey('button.newUpload')}
                    </Button>
                    <a href={compCerRegIssueFile.url} target='_blank' rel="noreferrer">
                      <span style={{ color: 'black' }}>{compCerRegIssueFile.fileNameOriginal}</span>
                    </a>
                  </div>
                  :
                  <Upload {...uploadPropsCompCerRegIssue}>
                    <Button icon={<UploadOutlined />}>{TranslationKey('button.clickToUpload')}</Button>
                  </Upload>
              }
            </div>
          </Form.Item>
          <Form.Item name={ArtistTaxInfoProperty.compCerRegIssueAttachmentFileName} hidden></Form.Item>
          <Form.Item name={ArtistTaxInfoProperty.compCerRegIssueAttachmentFileNameOriginal} hidden></Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name={ArtistTaxInfoProperty.compCommercialRegAttachmentUrl}
            className='mt-4 text-light '
            label={TranslationKey('taxCenter.taxFiles.3')}>
            <div className='upload-form-box'>
              {
                compCommercialRegFile && compCommercialRegFile.url !== StringConstants.EMPTY
                  ?
                  <div className='d-flex gap-2'>
                    <Button type='default' size='small'
                      onClick={() => {
                        setCompCommercialRegFile(ArtistTaxInfoAttachmentInit.defaultFileValue);
                        props.onUploadFileChanged(
                          ArtistTaxInfoFileType.compCommercialReg,
                          ArtistTaxInfoAttachmentInit.defaultFileValue)
                      }}
                      danger>
                      {TranslationKey('button.newUpload')}
                    </Button>
                    <a href={compCommercialRegFile.url} target='_blank' rel="noreferrer">
                      <span style={{ color: 'black' }}>{compCommercialRegFile.fileNameOriginal}</span>
                    </a>
                  </div>
                  :
                  <Upload {...uploadPropsCompCommercialReg}>
                    <Button icon={<UploadOutlined />}>{TranslationKey('button.clickToUpload')}</Button>
                  </Upload>
              }
            </div>
          </Form.Item>
          <Form.Item name={ArtistTaxInfoProperty.compCommercialRegAttachmentFileName} hidden></Form.Item>
          <Form.Item name={ArtistTaxInfoProperty.compCommercialRegAttachmentFileNameOriginal} hidden></Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name={ArtistTaxInfoProperty.compListOfShareholdersAttachmentUrl}
            className='mt-4 text-light '
            label={TranslationKey('taxCenter.taxFiles.4')}>
            <div className='upload-form-box'>
              {
                compListOfShareholdersFile && compListOfShareholdersFile.url !== StringConstants.EMPTY
                  ?
                  <div className='d-flex gap-2'>
                    <Button type='default' size='small'
                      onClick={() => {
                        setCompListOfShareholdersFile(ArtistTaxInfoAttachmentInit.defaultFileValue);
                        props.onUploadFileChanged(
                          ArtistTaxInfoFileType.compListOfShareholders,
                          ArtistTaxInfoAttachmentInit.defaultFileValue)
                      }}
                      danger>
                      {TranslationKey('button.newUpload')}
                    </Button>
                    <a href={compListOfShareholdersFile.url} target='_blank' rel="noreferrer">
                      <span style={{ color: 'black' }}>{compListOfShareholdersFile.fileNameOriginal}</span>
                    </a>
                  </div>
                  :
                  <Upload {...uploadPropsCompListOfShareholders}>
                    <Button icon={<UploadOutlined />}>{TranslationKey('button.clickToUpload')}</Button>
                  </Upload>
              }
            </div>
          </Form.Item>
          <Form.Item name={ArtistTaxInfoProperty.compListOfShareholdersAttachmentFileName} hidden></Form.Item>
          <Form.Item name={ArtistTaxInfoProperty.compListOfShareholdersAttachmentFileNameOriginal} hidden></Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name={ArtistTaxInfoProperty.compIdCardAuthorizedPersonAttachmentUrl}
            className='mt-4 text-light '
            label={TranslationKey('taxCenter.taxFiles.5')}>
            <div className='upload-form-box'>
              {
                compIdCardAuthorizedPersonFile && compIdCardAuthorizedPersonFile.url !== StringConstants.EMPTY
                  ?
                  <div className='d-flex gap-2'>
                    <Button type='default' size='small'
                      onClick={() => {
                        setCompIdCardAuthorizedPersonFile(ArtistTaxInfoAttachmentInit.defaultFileValue);
                        props.onUploadFileChanged(
                          ArtistTaxInfoFileType.compIdCardAuthorizedPerson,
                          ArtistTaxInfoAttachmentInit.defaultFileValue)
                      }}
                      danger>
                      {TranslationKey('button.newUpload')}
                    </Button>
                    <a href={compIdCardAuthorizedPersonFile.url} target='_blank' rel="noreferrer">
                      <span style={{ color: 'black' }}>{compIdCardAuthorizedPersonFile.fileNameOriginal}</span>
                    </a>
                  </div>
                  :
                  <Upload {...uploadPropsCompIdCardAuthorizedPerson}>
                    <Button icon={<UploadOutlined />}>{TranslationKey('button.clickToUpload')}</Button>
                  </Upload>
              }
            </div>
          </Form.Item>
          <Form.Item name={ArtistTaxInfoProperty.compIdCardAuthorizedPersonAttachmentFileName} hidden></Form.Item>
          <Form.Item name={ArtistTaxInfoProperty.compIdCardAuthorizedPersonAttachmentFileNameOriginal} hidden></Form.Item>
        </Col>
      </Row>
    </>
  );
}
