import { AssetType } from '../../constant/assetConstants';
import HoverVideoPlayer from 'react-hover-video-player';
import { HeartOutlined, PlayCircleOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { Skeleton, Space, message } from 'antd';
import { AssetPublishModel } from 'src/models/assetModel';
import { Link, useNavigate } from 'react-router-dom';
import { RouteNames } from 'src/routes/routeName';
import { useState } from 'react';
import { HttpStatus } from 'src/constant/responseStatus';
import { useSessionContext } from 'src/context/session-context';
import { ExtendedLicense } from 'src/constant/licenseConstants';
import { ExtendedLicenseModel, StandardLicenseModel } from 'src/models/LicenseModel';
import MyFavoriteModal from '../modal/myFavoriteModal';
import { ArtistService } from 'src/services/artistService';
import TranslationKey from 'src/i18n/translation';

interface GalleryInterface {
    index: number;
    asset: AssetPublishModel;
    width?: string;
    height?: string;
    isUseContainerImage?: boolean;
}

export default function GallerySearchItem({ asset, index, isUseContainerImage = false }: GalleryInterface) {
    const navigate = useNavigate();

    const { getOrderAssets, updateOrderAssets, getCountOrder, userProfile } = useSessionContext();
    const [isOpenFavorite, setIsOpenFavorite] = useState(false);

    const onClickFavorite = () => {
        if (userProfile && userProfile.userId) {
            setIsOpenFavorite(true);

            return;
        }

        message.open({
            type: 'error',
            content: 'Please login to use this feature.',
        });
    };

    const handleCloseFav = () => {
        setIsOpenFavorite(false);
    };

    const onClickToArtist = async () => {
        const { data, status } = await new ArtistService().GetIdByCode(asset.id);

        if (status !== HttpStatus.OK) {
            return;
        }

        navigate(`/artist/${data}`)
    };

    const addToCard = (asset: AssetPublishModel) => {
        let orders = getOrderAssets();

        if (!orders) {
            orders = [];
        }
        //Check dubplicate
        const dubIndex = orders.findIndex((order) => order.id === asset.id);

        const isExtendedLicense = ExtendedLicense.NONE;

        //Check Nolicense
        const nolicenseIndex = orders.findIndex(
            (order) =>
                order.id === asset.id &&
                order.standardLicense.id === asset.standardLicense.id &&
                order.extendedLicense.code === ExtendedLicense.NONE
        );

        //Check has Extendedlicense
        const hasExtlicense =
            orders.filter(
                (order) =>
                    order.id === asset.id &&
                    order.standardLicense.id === asset.standardLicense.id &&
                    order.extendedLicense.code !== ExtendedLicense.NONE
            ).length > 0;

        //Check has add ExtendedLicense but not noneExtenededlicense.
        if (isExtendedLicense && nolicenseIndex > -1) {
            orders.splice(nolicenseIndex, 1);
        }

        //Check is select none but has extendedlicense or duplicate
        if ((!isExtendedLicense && hasExtlicense) || dubIndex > -1) {
            orders.splice(dubIndex, 1);
        }

        let extendedLicenseModel: ExtendedLicenseModel;
        let standardLicenseModel: StandardLicenseModel;

        if (asset.assetType === AssetType.IMAGE) {
            extendedLicenseModel = {
                id: 'e855df98-108d-4da3-a86a-bae81d326eca',
                assetType: AssetType.IMAGE,
                code: 'NONE',
                name: 'None Extended License',
                price: 0,
                liftTimeType: 'DAY',
                liftTime: 0,
                sequence: 1,
            };

            standardLicenseModel = {
                assetType: AssetType.IMAGE,
                code: asset.hasXLLicense ? 'XL' : 'SM',
                id: asset.hasXLLicense ? '133bb17e-d809-49e9-8008-596b95aa9dfa' : '1b8ac529-0b0a-4547-99c0-98faf1143070',
                name: asset.hasXLLicense ? 'XLarge' : 'Small',
                price: asset.hasXLLicense ? 5000 : 500,
                sequence: asset.hasXLLicense ? 2 : 1,
            };
        } else {
            extendedLicenseModel = {
                id: '6976291a-f0bf-4732-96f5-58ada620da51',
                assetType: AssetType.VIDEO,
                code: 'NONE',
                name: 'None Extended License',
                price: 0,
                liftTimeType: 'DAY',
                liftTime: 0,
                sequence: 1,
            };

            standardLicenseModel = {
                assetType: AssetType.VIDEO,
                code: 'HD',
                id: 'a05aa75d-9757-4788-8389-8e43b8a51a40',
                name: 'HD',
                price: 7000,
                sequence: 1,
            };
        }

        let assetModified: AssetPublishModel =
            {
                id: asset.id,
                assetType: asset.assetType,
                code: asset.code,
                name: asset.name,
                description: asset.description,
                thumbnailImageUrl: asset.thumbnailImageUrl,
                thumbnailVideoUrl: asset.thumbnailVideoUrl,
                thumbnailVideoCoverUrl: asset.thumbnailVideoCoverUrl,
                hasSMLicense: asset.hasSMLicense,
                hasXLLicense: asset.hasXLLicense,
                hasHDLicense: asset.hasHDLicense,
                hasUHDLicense: asset.hasUHDLicense,
                publishDate: asset.publishDate,
                hasCollection: asset.hasCollection,
                keywords: asset.keywords,
                userId: asset.userId,
                standardLicense: standardLicenseModel,
                extendedLicense: extendedLicenseModel,
                price: standardLicenseModel.price + extendedLicenseModel.price,
                isDisabled: asset.isDisabled,
                isHasLicense: true,
                imageOrientation: asset.imageOrientation,
                usage: asset.usage,
            };

        orders.push(assetModified);
        updateOrderAssets(orders);

        message.open({
            type: 'success',
            content: TranslationKey('cart.addItemToCartSuccess'),
        });

        getCountOrder();
    }

    return (
        <>
            <div className='img-info'>
                {
                    asset.assetType === AssetType.VIDEO
                    ? 
                    (
                        <>
                            <div className={isUseContainerImage ? 'containerImage' : ''}>
                                <Link
                                    to={RouteNames.assetDetail.replace(':id', asset.id)}
                                    reloadDocument={true}
                                    className={`${asset.imageOrientation}`}>
                                    <HoverVideoPlayer
                                        className='video'
                                        key={index}
                                        videoSrc={asset.thumbnailVideoUrl!}
                                        restartOnPaused
                                        overlayTransitionDuration={500}
                                        style={{
                                            position: 'relative',
                                            width: '100%',
                                            height: '100%',
                                            borderRadius: 5,
                                        }}
                                        videoStyle={{
                                            position: 'relative',
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                            cursor: 'pointer',
                                            borderRadius: 5,
                                        }}
                                        pausedOverlay={
                                            asset.thumbnailVideoCoverUrl
                                                ?
                                                (
                                                    <>
                                                        <img
                                                            key={index}
                                                            src={asset.thumbnailVideoCoverUrl}
                                                            className='gallery-img'
                                                            style={{
                                                                position: 'relative',
                                                                width: '100%',
                                                                height: '100%',
                                                                objectFit: 'cover',
                                                                borderRadius: 5,
                                                            }}
                                                            alt={asset.name}
                                                        />
                                                        <PlayCircleOutlined
                                                            style={{
                                                                position: 'absolute',
                                                                top: '50%',
                                                                left: '50%',
                                                                color: '#FFF',
                                                                fontSize: '3em',
                                                                opacity: 0.7,
                                                                transform: 'translate(-50%, -50%)',
                                                            }}
                                                        />

                                                    </>
                                                )
                                                : <Skeleton.Image />
                                        }
                                        loadingOverlay={<div className='loading-overlay'>Loading...</div>}
                                    />
                                </Link>
                                <HeartOutlined size={15} className='favorite' onClick={() => onClickFavorite()} />
                                <ShoppingCartOutlined size={15} className='fa-download' onClick={() => addToCard(asset)} />
                                <div className='artist' onClick={onClickToArtist}>
                                    {
                                        asset &&
                                        asset.artistNameEn
                                    }
                                </div>
                            </div>
                        </>
                    )
                    : 
                    (
                        <>
                            <div className={isUseContainerImage ? 'containerImage' : ''}>
                                <Link to={RouteNames.assetDetail.replace(':id', asset.id)} reloadDocument={true} className='box-images'>
                                    <img key={index} src={asset.thumbnailImageUrl} className={`gallery-img`} alt={asset.name} />
                                </Link>
                                <Space>
                                    <HeartOutlined size={30} className='favorite' onClick={() => onClickFavorite()} />
                                    <ShoppingCartOutlined size={50} className='fa-download' onClick={() => addToCard(asset)} />
                                </Space>
                                <div className='artist' onClick={onClickToArtist}>
                                    {
                                        asset &&
                                        (asset.artistNameEn == null ||  asset.artistNameEn === undefined
                                        ||  asset.artistNameEn) === '' ? asset.artistFirstname + '' + asset.artistLastname :
                                        asset.artistNameEn
                                    }
                                </div>
                            </div>
                        </>
                    )}
            </div>
            
            <MyFavoriteModal
                isFavoriteModalOpen={isOpenFavorite}
                assetId={asset.id!}
                onCloseFavoriteModal={handleCloseFav}
            />
        </>
    );
}