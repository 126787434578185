import { useSessionContext } from 'src/context/session-context';
import { Button, Card, Col, Form, Input, Modal, Row, message } from 'antd';
import Title from 'antd/es/typography/Title';
import { AccountService } from 'src/services/accountService';
import { HttpStatus } from 'src/constant/responseStatus';
import IChangeEmailRequest from 'src/models/request/changeEmailRequestModel';
import { ExclamationCircleFilled } from '@ant-design/icons';
import TranslationKey from 'src/i18n/translation';

export default function ChangeEmail() {
  const { userProfile, signOut } = useSessionContext();
  const [form] = Form.useForm();
  const { confirm } = Modal;
  const [messageApi, contextHolder] = message.useMessage();

  const onChangeEmail = async (data: IChangeEmailRequest) => {


    if (data.newEmail.length <= 0) {
      messageApi.open({
        type: 'error',
        content: TranslationKey('changeEmail.incorectEmail'),
      });

      return;
    }

    const accountService = new AccountService();
    const response = await accountService.changeEmailAsync(data);

    if (response.status !== HttpStatus.OK) {
      messageApi.open({
        type: 'error',
        content: TranslationKey('changeEmail.changeEmailFailed'),
      });

      return;
    }

    messageApi.open({
      type: 'success',
      content: TranslationKey('changeEmail.changeEmailSuccess'),
    }).then(() => {
      signOut();
    });
  };

  const onFinish = async (data: IChangeEmailRequest) => {
    confirm({
      title: TranslationKey('changeEmail.confirmChangeEmail'),
      icon: <ExclamationCircleFilled />,
      onOk() {
        onChangeEmail(data);
      },
      okText: TranslationKey('button.ok'),
      cancelText: TranslationKey('button.cancel')
    });
  };

  return (
    <>
      {contextHolder}
      <Form form={form} layout='vertical' onFinish={onFinish} className='form-input'>
        <Card
          title={TranslationKey('changeEmail.title')}
          bordered={false}
          extra={
            <Button type='primary' htmlType='submit' size='large'>
              {TranslationKey('button.submit')}
            </Button>
          }
        >
          <Row>
            <Col span={24} lg={8}>
              <Form.Item
                name='email'
                className='mb-4 text-light'
                label={TranslationKey('changeEmail.currentEmail')}
              >
                <Title level={5}>{userProfile.email}</Title>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24} lg={8}>
              <Form.Item
                name='newEmail'
                className='mb-4 text-light'
                label={TranslationKey('changeEmail.newEmail')}
                rules={[
                  {
                    required: true,
                    message: TranslationKey('changeEmail.requireEmail')
                  },
                  {
                    type: 'email',
                    message: TranslationKey('changeEmail.invalidEmail')
                  }]}
              >
                <Input className='w-100' placeholder='Email' size='large' />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </>
  );
}
