import { Row, Col } from 'antd';
import { useEffect, useState } from 'react';
import '../../styles/fix/input-dark.scss';
import { useParams } from "react-router-dom";
import { HttpStatus } from 'src/constant/responseStatus';
import { FavoriteService } from 'src/services/favoriteService';
import { PageDocument } from 'src/constant/stringConstants';
import { FavoriteResponseModel } from 'src/models/responses/favoriteModel';
import GalleryFavoriteCollectItems from 'src/components/galleryCollection/GalleryFavoriteCollectionItems';
import TranslationKey from 'src/i18n/translation';

export default function FavoriteCollection() {
  const [favorite, setFavorite] = useState<FavoriteResponseModel | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { id } = useParams();

  useEffect(() => {
    document.title = `Favorite Collection - ${PageDocument.Title}`;

    if (id) {
      onGetFavoriteByIdAsync();
    }
  }, [id]);

  const onGetFavoriteByIdAsync = async () => {
    const
      {
        data: responseData,
        status: responseStatus
      } = await new FavoriteService().getFavoriteByIdAsync(id!);

    if (responseStatus !== HttpStatus.OK) {
      return;
    }

    setFavorite(responseData);
    setIsLoading(false)
  }

  return (
    <div className='search pt-5 mt-5'>
      <div className='container'>
        {
          favorite
            ? <>
              <h3>
                {TranslationKey('myFavorite.collection') + ': ' + favorite.name}
              </h3>
              <Row align="middle" className='mt-3'>
                <Col span={24}>
                  {
                    favorite.favoriteItems &&
                    (
                      <>
                        <div className="gallery">
                          <div className='gallery'>
                            {
                              favorite.favoriteItems
                                .map((favItem, index) => (
                                  <GalleryFavoriteCollectItems
                                    key={'fav-item-' + index}
                                    favItem={favItem}
                                    index={index} />
                                ))
                            }
                          </div>
                          <div className="box-gradient" />
                        </div>
                      </>
                    )
                  }
                </Col>
              </Row>
            </>
            : isLoading && <>
              <Row align={'middle'} justify={'center'} className='mt-3'>
                <Col span={24}>
                  <h3>{TranslationKey('myFavorite.notFound')}</h3>
                </Col>
              </Row>
            </>
        }
      </div>
    </div>
  );
}