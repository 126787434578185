import { AssetType } from '../../constant/assetConstants';
import HoverVideoPlayer from 'react-hover-video-player';
import { Button, Skeleton } from 'antd';
import { CloseOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import type { Identifier, XYCoord } from 'dnd-core';
import type { FC } from 'react';
import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { AssetPublishModel } from 'src/models/assetModel';

interface AssetInterface {
  index: number;
  asset: AssetPublishModel;
  id: string;
  width?: string;
  height?: string;
  onClickItem: (assetId: string) => void;
  onDelete?: (itemId: string) => void;
  btnDelmarginRight?: number;
  btnDelmarginTop?: number;
  iconsize?: string;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
}

const style = {
  border: '1px dashed gray',
  padding: '0.5rem 0.5rem',
  backgroundColor: 'white',
  cursor: 'move',
  borderRadius: 10,
};

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export default function GalleryDragItem({
  index,
  asset,
  id,
  width = '100%',
  height = '100%',
  onDelete,
  onClickItem,
  btnDelmarginRight = 10,
  btnDelmarginTop = 10,
  iconsize = '64px',
  moveCard,
}: AssetInterface) {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <div
      ref={ref} style={{ ...style, opacity }}
      data-handler-id={handlerId}>
      {asset.assetType === AssetType.IMAGE
        ?
        (
          <div className='img-gallery-item'>
            <img
              key={index}
              src={asset.thumbnailImageUrl}
              onClick={() => onClickItem(asset.id)}
              alt={asset.name}
              width={width}
              height={height}
              style={{ objectFit: 'cover', cursor: 'move' }}
            />
            {
              onDelete &&
              (
                <Button
                  type='primary'
                  className='btn-gallery-delete'
                  size='small'
                  icon={<CloseOutlined className='icon-fix' />}
                  onClick={() => onDelete?.(id)}
                  style={{
                    marginTop: btnDelmarginTop,
                    marginRight: btnDelmarginRight,
                    borderRadius: 5,
                  }}
                  danger
                ></Button>
              )}
          </div>
        )
        :
        (
          <div className='img-gallery-item'>
            <HoverVideoPlayer
              key={index}
              onClick={() => onClickItem(asset.id)}
              videoSrc={asset.thumbnailVideoUrl}
              restartOnPaused
              overlayTransitionDuration={1000}
              style={{
                position: 'relative',
                width: width,
                height: height,
                borderRadius: 10,
              }}
              videoStyle={{
                position: 'relative',
                width: width,
                height: height,
                objectFit: 'cover',
                cursor: 'move',
                borderRadius: 10,
              }}
              pausedOverlay={
                asset.thumbnailVideoCoverUrl ? (
                  <>
                    <img
                      key={index}
                      src={asset.thumbnailVideoCoverUrl}
                      className='gallery-img'
                      style={{
                        position: 'relative',
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        borderRadius: 10,
                      }}
                      alt={asset.name}
                    />
                    <PlayCircleOutlined
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        color: '#FFF',
                        fontSize: '3em',
                        opacity: 0.7,
                        transform: 'translate(-50%, -50%)',
                      }}
                    />
                  </>
                ) : (
                  <Skeleton.Node>
                    <PlayCircleOutlined style={{ fontSize: iconsize, color: '#bfbfbf' }} />
                  </Skeleton.Node>
                )
              }
              loadingOverlay={
                <div key={index} className='loading-overlay'>
                  Loading...
                </div>
              }
            />
            {
              onDelete &&
              (
                <Button
                  type='primary'
                  className='btn-gallery-delete'
                  size='large'
                  icon={<CloseOutlined className='icon-fix' />}
                  onClick={() => onDelete?.(id)}
                  style={{
                    marginTop: btnDelmarginTop,
                    marginRight: btnDelmarginRight,
                    borderRadius: 5,
                  }}
                  danger
                ></Button>
              )}
          </div>
        )}
    </div>
  );
}
