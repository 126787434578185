import { ShareAltOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Result, Row, Tabs, TabsProps, message } from 'antd';
import { Space, Typography } from 'antd';
import { useEffect, useState } from 'react';
import '../../styles/fix/input-dark.scss';
import GalleryArtist from 'src/components/gallery/GalleryArtist';
import { AccountService } from 'src/services/accountService';
import { AssetPublishModel } from 'src/models/assetModel';
import { HttpStatusCode } from 'axios';
import { ArtistProfileModel, FollowCreateModel } from 'src/models/accountModel';
import { useParams } from 'react-router-dom';
import { AssetType } from 'src/constant/assetConstants';
import ShareModal from 'src/components/modal/shareModal';
import { HttpStatus } from 'src/constant/responseStatus';
import { PageDocument } from 'src/constant/stringConstants';
import TranslationKey from 'src/i18n/translation';

const { Title, Text } = Typography;

export default function ArtistProfile() {
    const [assets, setAssets] = useState<AssetPublishModel[]>([]);
    const [artist, setArtist] = useState<ArtistProfileModel>({} as ArtistProfileModel);
    const { id } = useParams();
    const [isNotFound, setIsNotFound] = useState(false);
    const [isOpenShare, setIsOpenShare] = useState(false);
    const [isFollow, setIsFollow] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        document.title = `Artist - ${PageDocument.Title}`;
        getArtistAsync();
    }, []);

    useEffect(() => {
        if (artist && artist.artist && artist.artist.nameInfoEn) {
            document.title = `${artist.artist.nameInfoEn.firstname} ${artist.artist.nameInfoEn.lastname} - ${PageDocument.Title}`;
        }
    }, [artist]);

    const getArtistAsync = async () => {
        const { data, status } = await new AccountService().getArtistById(id!);

        if (status !== HttpStatusCode.Ok) {
            setIsNotFound(true);

            return;
        }

        setArtist(data);
        onCheckFollowArtistAsync(data.artist.id);
        getAssetByArtistIdAsync();
    };

    const getAssetByArtistIdAsync = async () => {
        const { data, status } = await new AccountService().getAssetByArtistId(id!);

        if (status !== HttpStatusCode.Ok) {
            return;
        }

        setAssets(data);
    };

    const onFollowArtistAsync = async (id: string) => {
        const followCreate = {
            artistId: id,
        } as FollowCreateModel;
        const {status: responseStatus } = await new AccountService().followByArtistId(followCreate);

        if (responseStatus !== HttpStatus.CREATED) {
            messageApi.open({
                type: 'error',
                content: TranslationKey('follows.followFailed'),
            });
            return;
        }

        messageApi.open({
            type: 'success',
            content: TranslationKey('follows.followSuccess'),
        });
        onCheckFollowArtistAsync(id);
    };

    const onUnfollowArtistAsync = async (id: string) => {
        const { status: responseStatus } = await new AccountService().unfollowByArtistId(id);

        if (responseStatus !== HttpStatus.NO_CONTENT) {
            messageApi.open({
                type: 'error',
                content: TranslationKey('follows.unFollowFailed'),
            });
            return;
        }

        messageApi.open({
            type: 'success',
            content: TranslationKey('follows.unFollowSuccess'),
        });

        onCheckFollowArtistAsync(id);
    };

    const onCheckFollowArtistAsync = async (artistId: string) => {
        const { data: responseData, status: responseStatus } = await new AccountService().getFollower(artistId);

        if (responseStatus !== HttpStatus.OK) {
            setIsFollow(false);
            return;
        }

        setIsFollow(responseData.artistId === artistId);
    };

    const onClickShare = () => {
        setIsOpenShare(true);
    };

    const handleCloseShare = () => setIsOpenShare(false);

    const itemsTab: TabsProps['items'] = [
        {
            key: 'all',
            label: 'All (' + assets.length + ')',
            children: <GalleryArtist assets={assets} />,
        },
        {
            key: 'image',
            label: 'Photos (' + assets.filter((asset) => asset.assetType === AssetType.IMAGE).length + ')',
            children: <GalleryArtist assets={assets.filter((asset) => asset.assetType === AssetType.IMAGE)} />,
        },
        {
            key: 'video',
            label: 'Videos (' + assets.filter((asset) => asset.assetType === AssetType.VIDEO).length + ')',
            children: <GalleryArtist assets={assets.filter((asset) => asset.assetType === AssetType.VIDEO)} />,
        },
    ];

    if (isNotFound) {
        return (
            <Row className='pt-5' justify='center'>
                <Col span={24}>
                    <Result
                        status='404'
                        title='404'
                        subTitle='Sorry, the page you visited does not exist.'
                        className='result-error'
                        extra={[
                            <a href='/' className='btn-orange-round' style={{ textDecoration: 'none' }}>
                                {TranslationKey('goToHome')}
                            </a>,
                        ]}
                    />
                </Col>
            </Row>
        );
    }

    return (
        <div className='search pt-5 profile banner-margin-t-50'>
            {contextHolder}
            <div className='container'>
                {artist && (
                    <>
                        <Row justify='center' gutter={16}>
                            <Col style={{ alignContent: 'center' }}>
                                {artist.avatarUrl ? (
                                    <Avatar
                                        size={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 160, xxl: 160 }}
                                        src={<img src={artist.avatarUrl} alt={artist.artist.nameInfoEn.firstname + ' ' + artist.artist.nameInfoEn.lastname} />}
                                    />
                                ) : (
                                    <Avatar
                                        size={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 160, xxl: 160 }}
                                        icon={<UserOutlined />}
                                        alt='artist'
                                        style={{ backgroundColor: 'rgb(255, 85, 1)' }}
                                    />
                                )}
                            </Col>
                        </Row>
                        <Row justify='center' gutter={16} className='mt-4'>
                            <Col span={24} style={{ textAlign: 'center' }}>
                                {artist.artist && artist.artist.nameInfoEn && (
                                    <>
                                        <Title className='text-light'>
                                            {artist.artist.nameInfoEn.firstname +
                                                ' ' +
                                                artist.artist.nameInfoEn.lastname}
                                        </Title>
                                        <Text className='text-light'>{artist.artist.selfPromotion}</Text>
                                    </>
                                )}
                            </Col>
                        </Row>
                        <Row justify='center' gutter={16} className='mt-4'>
                            <Col span={24} style={{ textAlign: 'center' }}>
                                <Space>
                                    {isFollow ? (
                                        <Button
                                            type='primary'
                                            shape='round'
                                            className='btn-outline-border active'
                                            size='large'
                                            onClick={() => onUnfollowArtistAsync(artist.artist.id)}
                                        >
                                            {TranslationKey('button.unFollow')}
                                        </Button>
                                    ) : (
                                        <Button
                                            type='primary'
                                            shape='round'
                                            className='btn-light'
                                            size='large'
                                            onClick={() => onFollowArtistAsync(artist.artist.id)}
                                        >
                                            {TranslationKey('button.follow')}
                                        </Button>
                                    )}
                                    <Button
                                        type='primary'
                                        shape='circle'
                                        className='btn-outline'
                                        size='large'
                                        icon={<ShareAltOutlined className='icon-fix' />}
                                        onClick={onClickShare}
                                    />
                                </Space>
                            </Col>
                        </Row>
                        <Row gutter={16} className='mt-4'>
                            <Col span={24}>
                                <Tabs defaultActiveKey='1' items={itemsTab} className='custom-tab mt-3' />
                            </Col>
                        </Row>
                        <ShareModal
                            isModalOpen={isOpenShare}
                            sharedUrl={window.location.href}
                            onClose={handleCloseShare}
                        />
                    </>
                )}
            </div>
        </div>
    );
}
