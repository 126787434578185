import { PageDocument, StringConstants } from "src/constant/stringConstants";
import { Col, Row, Typography } from "antd";
import Title from "antd/es/typography/Title";
import { useEffect } from "react";
import TranslationKey from "src/i18n/translation";
const { Text } = Typography;

export default function PrivacyPolicy() {
    useEffect(() => {
        document.title = `Privacy Policy - ${PageDocument.Title}`;
    }, []);

  return (
    <div className="container py-5 banner-margin-t-50">
      <Row className="d-flex justify-content-center">
        <Col span={16}>
          <Title className="text-primary" level={3}>
            {TranslationKey('privacyPolicy.privacyPolicyFotSiteUse')}
          </Title>
          <p>{TranslationKey('privacyPolicy.thisPrivacyPolicyHereinafter')} </p>
          <Title className="text-primary" level={3}>
            {TranslationKey('privacyPolicy.aliveUnitedDataProtectionNoticeForWebsiteUsers.title')}
          </Title>
          <p>{TranslationKey('privacyPolicy.aliveUnitedDataProtectionNoticeForWebsiteUsers.thisAliveUnitedData')} </p>
          <p>{TranslationKey('privacyPolicy.aliveUnitedDataProtectionNoticeForWebsiteUsers.inTheOperations')} </p>
          <p>{TranslationKey('privacyPolicy.aliveUnitedDataProtectionNoticeForWebsiteUsers.theDataProtectionNotice')}</p>
          <p>{TranslationKey('privacyPolicy.aliveUnitedDataProtectionNoticeForWebsiteUsers.pleaseReadThisData')}</p>
          <p>{TranslationKey('privacyPolicy.aliveUnitedDataProtectionNoticeForWebsiteUsers.thisDataProtectionNotice')} </p>
          <Title className="text-primary" level={3}>
          {TranslationKey('privacyPolicy.whatIsPersonalData')}
          </Title>
          <p>{TranslationKey('privacyPolicy.personalDataMeansAnyInformation')}</p>
          <Title className="text-primary" level={3}>
          {TranslationKey('privacyPolicy.yourPersonalDataThatWeMayCollectProcessAndOrDisclose.title')}
          </Title>
          <p>{TranslationKey('privacyPolicy.yourPersonalDataThatWeMayCollectProcessAndOrDisclose.weMayCollect')}</p>
          <p>{TranslationKey('privacyPolicy.yourPersonalDataThatWeMayCollectProcessAndOrDisclose.weAlsoReceive')}</p>
          <p>{TranslationKey('privacyPolicy.yourPersonalDataThatWeMayCollectProcessAndOrDisclose.weMayCollectProcessCategories')}</p>
          <Title className="text-primary" level={3}>
          {TranslationKey('privacyPolicy.personalInformation.title')}
          </Title>
          <p>{TranslationKey('privacyPolicy.personalInformation.thePersonalDataInThis')}</p>
          <p>{TranslationKey('privacyPolicy.personalInformation.asOurArtists')}</p>
          <Title className="text-primary" level={3}>
          {TranslationKey('privacyPolicy.contactInformation.title')}
          </Title>
          <p>{TranslationKey('privacyPolicy.contactInformation.thePersonalData')}</p>
          <Title className="text-primary" level={3}>
          {TranslationKey('privacyPolicy.financialAndTransactionalInformation.title')}
          </Title>
          <p> {TranslationKey('privacyPolicy.financialAndTransactionalInformation.thePersonalData')}</p>
          <p> {TranslationKey('privacyPolicy.financialAndTransactionalInformation.asOurArtists')} </p>
          <Title className="text-primary" level={3}>
          {TranslationKey('privacyPolicy.uniqueIdentifierInformationAndOnlineAndTechnicalInformation.title')}
          </Title>
          <p>{TranslationKey('privacyPolicy.uniqueIdentifierInformationAndOnlineAndTechnicalInformation.thePersonalData')}</p>
          <Title className="text-primary" level={3}>
        {TranslationKey('privacyPolicy.demographicInformationAndPreferences.title')}
      </Title>
      <p>{TranslationKey('privacyPolicy.demographicInformationAndPreferences.thePersonalData')}</p>
      <p>{TranslationKey('privacyPolicy.demographicInformationAndPreferences.asTheData')}</p>
      
      <Title className="text-primary" level={3}>
        {TranslationKey('privacyPolicy.personalDataInArtsAndContents.title')}
      </Title>
      <p>{TranslationKey('privacyPolicy.personalDataInArtsAndContents.thePersonalData')}</p>
      <p>{TranslationKey('privacyPolicy.personalDataInArtsAndContents.leaseAlsoRefer')}</p>
      
      <Title className="text-primary" level={3}>
        {TranslationKey('privacyPolicy.biometricInformation.title')}
      </Title>
      <p>{TranslationKey('privacyPolicy.biometricInformation.thePersonalData')}</p>
      <p>{TranslationKey('privacyPolicy.biometricInformation.asTheData')}</p>
      
      <Title className="text-primary" level={3}>
        {TranslationKey('privacyPolicy.personalDataOfMinors.title')}
      </Title>
      <p>{TranslationKey('privacyPolicy.personalDataOfMinors.forThePersonalData')}</p>
      
      <Text className="text-primary" strong>{TranslationKey('privacyPolicy.howDoWeCollectYourPersonalData.title')}</Text>
      <p>{TranslationKey('privacyPolicy.howDoWeCollectYourPersonalData.weCollectYourPersonalData')}</p>
      <dl>
        <dt>{TranslationKey('privacyPolicy.howDoWeCollectYourPersonalData.1.title')}</dt>
        <dd className="dd-indent">
        <ul>
            <li>{TranslationKey('privacyPolicy.howDoWeCollectYourPersonalData.1.yourAccount')}</li>
            <li>{TranslationKey('privacyPolicy.howDoWeCollectYourPersonalData.1.submission')}</li>
            <li>{TranslationKey('privacyPolicy.howDoWeCollectYourPersonalData.1.downloading')}</li>
            <li>{TranslationKey('privacyPolicy.howDoWeCollectYourPersonalData.1.uploading')}</li>
            <li>{TranslationKey('privacyPolicy.howDoWeCollectYourPersonalData.1.visiting')}</li>
            <li>{TranslationKey('privacyPolicy.howDoWeCollectYourPersonalData.1.viewing')}</li>
            <li>{TranslationKey('privacyPolicy.howDoWeCollectYourPersonalData.1.using')}</li>
            <li>{TranslationKey('privacyPolicy.howDoWeCollectYourPersonalData.1.subscription')}</li>
            <li>{TranslationKey('privacyPolicy.howDoWeCollectYourPersonalData.1.filling')}</li>
            <li>{TranslationKey('privacyPolicy.howDoWeCollectYourPersonalData.1.other')}</li>
            <li>{TranslationKey('privacyPolicy.howDoWeCollectYourPersonalData.1.yourPurchase')}</li>
            <li>{TranslationKey('privacyPolicy.howDoWeCollectYourPersonalData.1.yourLogIns')}</li>
            <li>{TranslationKey('privacyPolicy.howDoWeCollectYourPersonalData.1.youEmails')}</li>
            <li>{TranslationKey('privacyPolicy.howDoWeCollectYourPersonalData.1.yourBrowser')}</li>
          </ul>
        </dd>
        <dt>{TranslationKey('privacyPolicy.howDoWeCollectYourPersonalData.2')}</dt>
      </dl>
      <Text className="text-primary" strong>
        {TranslationKey('privacyPolicy.OurObjectivesOfPersonalDataCollectionProcessingAndDisclosure.title')}
      </Text>
      <dl>
        <dt>
          {TranslationKey('privacyPolicy.OurObjectivesOfPersonalDataCollectionProcessingAndDisclosure.weMayCollect')}
        </dt>
        <dd className="dd-indent">
          <dt>1. {TranslationKey('privacyPolicy.OurObjectivesOfPersonalDataCollectionProcessingAndDisclosure.1')}</dt>
          <dd className="dd-indent">
            <p>{TranslationKey('privacyPolicy.OurObjectivesOfPersonalDataCollectionProcessingAndDisclosure.1desc1')}</p>
            <p>{TranslationKey('privacyPolicy.OurObjectivesOfPersonalDataCollectionProcessingAndDisclosure.1desc2')}</p>
          </dd>
          <dt>2. {TranslationKey('privacyPolicy.OurObjectivesOfPersonalDataCollectionProcessingAndDisclosure.2')}</dt>
          <dd className="dd-indent">
            <p>{TranslationKey('privacyPolicy.OurObjectivesOfPersonalDataCollectionProcessingAndDisclosure.2desc')}</p>
          </dd>
          <dt>3. {TranslationKey('privacyPolicy.OurObjectivesOfPersonalDataCollectionProcessingAndDisclosure.3')}</dt>
          <dd className="dd-indent">
            <p>{TranslationKey('privacyPolicy.OurObjectivesOfPersonalDataCollectionProcessingAndDisclosure.3desc')}</p>
          </dd>
          <dt>4. {TranslationKey('privacyPolicy.OurObjectivesOfPersonalDataCollectionProcessingAndDisclosure.4')}</dt>
          <dd className="dd-indent">
            <p>{TranslationKey('privacyPolicy.OurObjectivesOfPersonalDataCollectionProcessingAndDisclosure.4desc1')}</p>
            <p>{TranslationKey('privacyPolicy.OurObjectivesOfPersonalDataCollectionProcessingAndDisclosure.4desc2')}</p>
          </dd>
        </dd>
      </dl>

      <Text className="text-primary" strong>
        {TranslationKey('privacyPolicy.ourPersonalDataProcessingSharingAndTransfer.title')}
      </Text>
      <p>{TranslationKey('privacyPolicy.ourPersonalDataProcessingSharingAndTransfer.yourPersonalData')}</p>
      <p>{TranslationKey('privacyPolicy.ourPersonalDataProcessingSharingAndTransfer.weMayShare')}</p>
      <p>{TranslationKey('privacyPolicy.ourPersonalDataProcessingSharingAndTransfer.weMayAs')}</p>

      <Title className="text-primary" level={3}>
        {TranslationKey('privacyPolicy.marketingAndAdvertisement.title')}
      </Title>
      <p>{TranslationKey('privacyPolicy.marketingAndAdvertisement.withYourConsent')}</p>

      <Title className="text-primary" level={3}>
        {TranslationKey('privacyPolicy.dataStorageAndDataRetention.title')}
      </Title>
      <p>{TranslationKey('privacyPolicy.dataStorageAndDataRetention.weMayStore')}</p>
      <ol>
        <li>{TranslationKey('privacyPolicy.dataStorageAndDataRetention.1')}</li>
        <li>{TranslationKey('privacyPolicy.dataStorageAndDataRetention.2')}</li>
        <li>{TranslationKey('privacyPolicy.dataStorageAndDataRetention.3')}</li>
      </ol>

      <Title className="text-primary" level={3}>
        {TranslationKey('privacyPolicy.DataRetentionPeriodAndLegalBasisOfCollectionAndProcessing.title')}
      </Title>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th style={{ width: '10%' }}>No.</th>
              <th style={{ width: '30%' }}>Type of Personal Data</th>
              <th style={{ width: '30%' }}>Legal Basis</th>
              <th style={{ width: '30%' }}>Retention Period</th>
            </tr>
          </thead>
          <tbody>
            {[1, 2, 3, 4, 5, 6, 7].map((num) => (
              <tr key={num}>
                <td>{num}.</td>
                <td>{TranslationKey(`privacyPolicy.DataRetentionPeriodAndLegalBasisOfCollectionAndProcessing.columnTypeOfPersonalData.${num}`)}</td>
                <td>{TranslationKey(`privacyPolicy.DataRetentionPeriodAndLegalBasisOfCollectionAndProcessing.columnLegalBasis.${num}`)}</td>
                <td>{TranslationKey(`privacyPolicy.DataRetentionPeriodAndLegalBasisOfCollectionAndProcessing.columnRetention Period.${num}`)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Title className="text-primary" level={3}>
        {TranslationKey('privacyPolicy.yourRightsAndChoices.title')}
      </Title>
      <p>{TranslationKey('privacyPolicy.yourRightsAndChoices.toEnsure')}</p>
      <ol>
        {[1, 2, 3, 4, 5, 6, 7, 8].map((num) => (
          <li key={num}>{TranslationKey(`privacyPolicy.yourRightsAndChoices.${num}`)}</li>
        ))}
      </ol>
      <p>{TranslationKey('privacyPolicy.yourRightsAndChoices.youCanMakeARequest')}</p>
      <p>{TranslationKey('privacyPolicy.yourRightsAndChoices.forYourRight')}</p>
      <p>{TranslationKey('privacyPolicy.yourRightsAndChoices.NoteUnlessTheRequestMadeToUs')}</p>

      <Title className="text-primary" level={3}>
        {TranslationKey('privacyPolicy.whatAreCookies.title')}
      </Title>
      <p>{TranslationKey('privacyPolicy.whatAreCookies.cookies')}</p>
      <p>{TranslationKey('privacyPolicy.whatAreCookies.ifYouWish')}</p>
      <Title className="text-primary" level={3}>
        {TranslationKey('privacyPolicy.howDoWeUseCookies.title')}
      </Title>
      <p>{TranslationKey('privacyPolicy.howDoWeUseCookies.weUseCookiesForManyPurposes.title')}</p>
      <ul>
        <li>{TranslationKey('privacyPolicy.howDoWeUseCookies.weUseCookiesForManyPurposes.toIncrease')}</li>
        <li>{TranslationKey('privacyPolicy.howDoWeUseCookies.weUseCookiesForManyPurposes.toCollect')}</li>
        <li>{TranslationKey('privacyPolicy.howDoWeUseCookies.weUseCookiesForManyPurposes.toResearchAnIndividual')}</li>
        <li>{TranslationKey('privacyPolicy.howDoWeUseCookies.weUseCookiesForManyPurposes.toResearchYourBehavioral')}</li>
        <li>{TranslationKey('privacyPolicy.howDoWeUseCookies.weUseCookiesForManyPurposes.toServe')}</li>
        <li>{TranslationKey('privacyPolicy.howDoWeUseCookies.weUseCookiesForManyPurposes.toHelp')}</li>
        <li>{TranslationKey('privacyPolicy.howDoWeUseCookies.weUseCookiesForManyPurposes.toRemember')}</li>
      </ul>
      <p>{TranslationKey('privacyPolicy.howDoWeUseCookies.theTypesOfCookies.title')}</p>
      <ul>
        <li>{TranslationKey('privacyPolicy.howDoWeUseCookies.theTypesOfCookies.functionality')}</li>
        <li>{TranslationKey('privacyPolicy.howDoWeUseCookies.theTypesOfCookies.advertising')}</li>
        <li>{TranslationKey('privacyPolicy.howDoWeUseCookies.theTypesOfCookies.strictly')}</li>
        <li>{TranslationKey('privacyPolicy.howDoWeUseCookies.theTypesOfCookies.performance')}</li>
        <li>{TranslationKey('privacyPolicy.howDoWeUseCookies.theTypesOfCookies.thirdParty')}</li>
      </ul>

      <Title className="text-primary" level={3}>
        {TranslationKey('privacyPolicy.cookiesSetting.title')}
      </Title>
      <p>{TranslationKey('privacyPolicy.cookiesSetting.youCanDisable')}</p>

      <Text className="text-primary" strong>{TranslationKey('privacyPolicy.forSafariUsers.title')}</Text>
      <ol>
        <li>{TranslationKey('privacyPolicy.forSafariUsers.1')}</li>
        <li>
          {TranslationKey('privacyPolicy.forSafariUsers.2.title')}
          <ul>
            <li>{TranslationKey('privacyPolicy.forSafariUsers.2.enablingPrevent')}</li>
            <li>{TranslationKey('privacyPolicy.forSafariUsers.2.enablingBlock')}</li>
            <li>{TranslationKey('privacyPolicy.forSafariUsers.2.chooseTheManage')}</li>
          </ul>
        </li>
      </ol>

      <Text className="text-primary" strong>{TranslationKey('privacyPolicy.forGoogleChromeUsers.title')}</Text>
      <ul>
        <li>{TranslationKey('privacyPolicy.forGoogleChromeUsers.open')}</li>
        <li>{TranslationKey('privacyPolicy.forGoogleChromeUsers.clickAdvanced')}</li>
        <li>{TranslationKey('privacyPolicy.forGoogleChromeUsers.under')}</li>
        <li>{TranslationKey('privacyPolicy.forGoogleChromeUsers.clickCookies')}</li>
        <li>{TranslationKey('privacyPolicy.forGoogleChromeUsers.ifYouWish')}</li>
      </ul>

      <Text className="text-primary" strong>{TranslationKey('privacyPolicy.forInternetExplorerUsers.title')}</Text>
      <ul>
        <li>{TranslationKey('privacyPolicy.forInternetExplorerUsers.open')}</li>
        <li>{TranslationKey('privacyPolicy.forInternetExplorerUsers.choose')}</li>
        <li>{TranslationKey('privacyPolicy.forInternetExplorerUsers.click')}</li>
        <li>{TranslationKey('privacyPolicy.forInternetExplorerUsers.under')}</li>
      </ul>

      <Title className="text-primary" level={3}>
        {TranslationKey('privacyPolicy.dataProtectionPoliciesOfThirdPartyWebsites.title')}
      </Title>
      <p>{TranslationKey('privacyPolicy.dataProtectionPoliciesOfThirdPartyWebsites.thisDataProtection')}</p>

      <Title className="text-primary" level={3}>
        {TranslationKey('privacyPolicy.effectOfNoticeAndChangesToNotice.title')}
      </Title>
      <p>{TranslationKey('privacyPolicy.effectOfNoticeAndChangesToNotice.thisDataProtection')}</p>
      <p>{TranslationKey('privacyPolicy.effectOfNoticeAndChangesToNotice.weMayRevise')}</p>

      <Title className="text-primary" level={3}>
        {TranslationKey('privacyPolicy.dataController.title')}
      </Title>
      <p>{TranslationKey('privacyPolicy.dataController.nameShots')}</p>
      <p>{TranslationKey('privacyPolicy.dataController.soi')}</p>
      <p>{TranslationKey('privacyPolicy.dataController.khlong')}</p>
      <p>{TranslationKey('privacyPolicy.dataController.emailAddress')}</p>
      <a href={TranslationKey('privacyPolicy.dataController.website')}>{TranslationKey('privacyPolicy.dataController.website')}</a>

      <Title className="text-primary" level={3}>
        {TranslationKey('privacyPolicy.dataProtectionOfficer.title')}
      </Title>
      <p>{TranslationKey('privacyPolicy.dataProtectionOfficer.name')}</p>
       <dt>
            <Title className="text-primary" level={3}>
            {TranslationKey('effectiveDate.title')}
            </Title>
          </dt>
          <dd className="dd-indent">
          {TranslationKey('effectiveDate.date')}
          </dd>
          <dt>
            <Title className="text-primary" level={3}>
            {TranslationKey('lastUpdatedDate.title')}
            </Title>
          </dt>
          <dd className="dd-indent">
          {TranslationKey('lastUpdatedDate.date')}
          </dd>
          <Title className="text-primary" level={3}>
          {TranslationKey('hContactUs')}
          </Title>
          <Text className="text-primary" strong>{TranslationKey('privacyPolicy.dataController.title')}</Text>
          <p>{TranslationKey('privacyPolicy.dataController.nameShots')}</p>
          <p>{TranslationKey('privacyPolicy.dataController.soi')}</p>
          <p>{TranslationKey('privacyPolicy.dataController.khlong')} </p>
          <p>{TranslationKey('privacyPolicy.dataController.emailAddress')} support@aliveunited.com</p>
          <p>{TranslationKey('privacyPolicy.dataController.website')}: <a href={`${process.env.LINK_WEB_CODEHARD ?? StringConstants.EMPTY}`}>aliveunited.com</a></p>
          <Text className="text-primary" strong>{TranslationKey('privacyPolicy.dataController.title')}</Text>
          <p>{TranslationKey('privacyPolicy.dataProtectionOfficer.name')}</p>
          <p>{TranslationKey('privacyPolicy.dataProtectionOfficer.email')}: legal@aliveunited.com </p>
        </Col>
      </Row>
    </div>
  );
}
